// content/:id
import { faAnglesRight, faLock } from '@fortawesome/free-solid-svg-icons';
import { faDollarCircle } from '@fortawesome/pro-duotone-svg-icons';
import { faBan } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NormalizedSearchResponse } from '@newstex/search/types';
import {
	type Content,
	Publication,
	isPublication,
	isPublisher,
} from '@newstex/types/content';
import { ContentStatus } from '@newstex/types/content-status';
import type { Feed } from '@newstex/types/feed';
import { Results } from '@newstex/types/results';
import {
	MutableRefObject,
	useEffect,
	useRef,
	useState,
} from 'react';
import {
	Alert,
	Button,
	Card,
	Col,
	Container,
	Row,
	Tab,
	Tabs,
} from 'react-bootstrap';
import {
	Link,
	useLocation,
	useNavigate,
	useParams,
	useSearchParams,
} from 'react-router-dom';
import { ContentCard } from '~/components/content-card';
import CreatePublicationButton from '~/components/create-publication-button';
import { CreateStoryButton, NEWSTEX_PARENT_ID } from '~/components/create-story-button';
import { DeletedAlert } from '~/components/deleted-alert';
import EditButton from '~/components/edit-button';
import { EditorialNotesTable } from '~/components/editorial-notes-table';
import { FeedCard } from '~/components/feed-card';
import { HeaderWithTooltip } from '~/components/header-with-tooltip';
import { HubSpotLink } from '~/components/hubspot-link';
import LoadingSpinner from '~/components/LoadingSpinner';
import { ObjectHistoryButton } from '~/components/object-history-button';
import { ParentBreadcrumb } from '~/components/parent-breadcrumb';
import { ProductStatusList } from '~/components/product-status';
import { PropertyDisplayValue } from '~/components/property-display-value';
import { RedeliverButton } from '~/components/redeliver-button';
import { RefreshButton } from '~/components/refresh-button';
import { RequirePermissions, hasPermission } from '~/components/require-permissions';
import { RevenueStats } from '~/components/revenue-stats';
import { StatusBadge } from '~/components/status-badge';
import { StoryStats } from '~/components/story/story-stats';
import { SyncToHubSpotButton } from '~/components/sync-to-hubspot-button';
import { PropertiesTable } from '~/components/tables/properties-table';
import { UpdateModal } from '~/components/update-modal';
import ViewDashboardButton from '~/components/view-dashboard-button';
import ViewStoriesButton from '~/components/view-stories-button';
import { useAPI } from '~/providers/api-provider';
import { useSearch } from '~/providers/search';
import { useUserInfo } from '~/providers/user-info';

const STATUS_ORDER: Publication['content_status'][] = [
	ContentStatus.ACTIVE,
	ContentStatus.TESTING,
	ContentStatus.INTEGRATION,
	ContentStatus.PROVIDER_PREP,
	ContentStatus.EDITORIAL_REVIEW,
	ContentStatus.ADMINISTRATION,
	ContentStatus.HIATUS,
	ContentStatus.ARCHIVAL,
	ContentStatus.MOVED,
	ContentStatus.NON_QUALIFYING,
	ContentStatus.DELETED,
];

function getStatusOrder(status?: ContentStatus) {
	if (!status) {
		return 999;
	}
	return STATUS_ORDER.indexOf(status);
}

export function ChildContent({ pub, refresh }: { pub?: Content, refresh?: MutableRefObject<Function | undefined> }) {
	const navigate = useNavigate();
	const searchContext = useSearch();
	const [childContent, setChildContent] = useState<NormalizedSearchResponse<Content>['hits']>([]);

	useEffect(() => {
		const fetchData = async (shouldRefresh = false) => {
			setChildContent([]);
			if (pub && searchContext.searchClient) {
				const searchResp = await searchContext.searchClient.multiSearch([
					{
						indexName: 'Publication',
						query: '',
						filter_by: `parent:${pub.$id}`,
						per_page: 10,
						use_cache: !shouldRefresh,
					},
					{
						indexName: 'Publisher',
						query: '',
						filter_by: `parent:${pub.$id}`,
						per_page: 10,
						use_cache: !shouldRefresh,
					},
				]);
				const mergedHits = searchResp?.flatMap((response) => response.hits || []) || [];
				setChildContent(mergedHits);
			}
		};

		fetchData();
		if (refresh) {
			refresh.current = () => {
				fetchData(true);
			};
		}
	}, [pub?.$id]);

	return (
		<Row style={{ display: (childContent?.length || isPublisher(pub)) ? 'block' : 'none' }}>
			<h6>
				Child Records
				<Button color="secondary" variant="link" onClick={() => {
					if (pub?.$id) {
						const filterString = new URLSearchParams({
							parent: pub.$id,
						});
						if (childContent?.[0]?.$type === 'Publisher') {
							navigate(`/publishers?${filterString.toString()}`);
						} else {
							navigate(`/publications?${filterString.toString()}`);
						}
					}
				}}>
					View All <FontAwesomeIcon icon={faAnglesRight} />
				</Button>
			</h6>
			<hr />
			<div key={`${pub?.$id}-children`} className="row">
				{childContent?.sort((a, b) => {
					if (isPublication(a) && isPublication(b)) {
						return getStatusOrder(a.content_status) - getStatusOrder(b.content_status);
					}
					return 0;
				}).map((hit) => (
					<div key={hit.id} className="col-xxl-12 col-xl-4 col-lg-6 col-sm-8 mb-3">
						<ContentCard item={hit} />
					</div>
				))}
			</div>
			{ pub?.status === 'Active' && <CreatePublicationButton parent={pub} /> }
		</Row>
	);
}

export function ChildFeed({ pub, refresh }: { pub?: Content, refresh?: MutableRefObject<Function | undefined> }) {
	const api = useAPI();
	const [childFeeds, setChildFeeds] = useState<Feed[]>([]);
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		const fetchData = async (shouldRefresh = false) => {
			setChildFeeds([]);
			setLoading(true);
			if (pub && api) {
				const feeds: Feed[] = [];
				let resp = await api.fetchWithAuth<Results<Feed>>(`resources/Feed?content=${pub.$id}`, shouldRefresh ? { cache: 'reload' } : undefined);
				if (resp?.items) {
					feeds.push(...resp.items);
				}
				while (resp?.nextToken) {
					resp = await api.fetchWithAuth<Results<Feed>>(`resources/Feed?content=${pub.$id}&nextToken=${resp.nextToken}`, shouldRefresh ? { cache: 'reload' } : undefined);
					if (resp?.items) {
						feeds.push(...resp.items);
					}
				}
				// Sort Active feeds to the top
				feeds.sort((a) => (a.status === 'Active' ? -1 : 1));
				setChildFeeds(feeds);
			}
			setLoading(false);
		};
		fetchData();
		if (refresh) {
			refresh.current = () => {
				fetchData(true);
			};
		}
	}, [pub?.$id]);

	return (
		<Row style={{ display: (childFeeds?.length || isPublication(pub)) ? 'block' : 'none' }}>
			<h6>Feeds
				<Link to={`/feeds?content=${pub?.$id}`} color="secondary" className="ps-2">
					Table View <FontAwesomeIcon icon={faAnglesRight} />
				</Link>
			</h6>
			<hr />
			<LoadingSpinner loading={loading} />
			<div className="row">
				{childFeeds?.map((hit) => (
					<div key={hit.$id} className="col-xxl-12 col-xl-4 col-lg-6 col-sm-8 mb-3">
						<FeedCard item={hit} />
					</div>
				))}
			</div>
		</Row>
	);
}

export function ContentPage() {
	const params = useParams();
	const api = useAPI();
	const refreshFeeds = useRef<Function>();
	const refreshContent = useRef<Function>();
	const [pub, setContent] = useState<Content>();
	const [loading, setLoading] = useState(true);
	const [searchParams, setSearchParams] = useSearchParams();
	const [activeTab, setActiveTab] = useState(searchParams.get('tab') || 'default');
	const navigate = useNavigate();
	const location = useLocation();
	const userInfo = useUserInfo();

	// Update Modal
	const [showUpdateModal, setShowUpdateModal] = useState(false);
	const [contentUpdates, setContentUpdates] = useState<Partial<Content | Publication>>();
	const [modalTitle, setUpdateModalTitle] = useState('');

	const fetchData = async (refresh = false, signal?: AbortSignal) => {
		if (params.id) {
			const typeName = location.pathname.startsWith('/publications') ? 'Publication' : 'Publisher';
			if (signal?.aborted) {
				return;
			}
			setLoading(true);
			const resp = await api.fetchWithAuth(
				`resources/${typeName}/${params.id}`,
				refresh ? { signal, cache: 'reload' } : { signal },
			);

			if (signal?.aborted) {
				return;
			}

			if (resp.items?.[0]?.$id && location.pathname.startsWith('/content')) {
				if (isPublisher(resp.items[0])) {
					navigate(`/publishers/${encodeURIComponent(resp.items[0].$id)}`);
				} else {
					navigate(`/publications/${encodeURIComponent(resp.items[0].$id)}`);
				}
			} else if (!resp.items?.[0]?.$id && typeName === 'Publisher' && params.id) {
				// If no item was found and we are on a publisher page, redirect to the publications page
				// to try again.
				navigate(`/publications/${encodeURIComponent(params.id)}`);
			}

			if (!signal?.aborted) {
				setContent(resp.items?.[0]);
				setLoading(false);
			}
		}
	};

	useEffect(() => {
		const abortController = new AbortController();
		fetchData(false, abortController.signal);
		return () => {
			abortController.abort();
		};
	}, [params?.id]);

	return (
		<Container fluid>
			{showUpdateModal && pub && contentUpdates && <UpdateModal
				item={pub}
				updates={contentUpdates}
				title={modalTitle}
				onHide={(saved) => {
					setShowUpdateModal(false);
					if (saved) {
						// Set loading to "true" immediately
						setLoading(true);

						// Then wait one second before a full refresh
						setTimeout(() => {
							fetchData(true);
						}, 1000);
					}
				}}
			/>}
			<div className="title-wrapper pt-30">
				<DeletedAlert deleted={pub?.deleted} />
				<Row className="align-items-center">
					<Col md={6}>
						<div className="title">
							<h2>
								{pub?.name}
								<RequirePermissions permissions={['admin']}>
									&nbsp;<StatusBadge status={pub?.status} />
									&nbsp;<HubSpotLink item={pub} size={28} />
									&nbsp;<ObjectHistoryButton item={pub} size={28} />
								</RequirePermissions>
							</h2>
							<h6 className="text-muted">
								{pub?.$type}
								{pub?.newstex_id && (
									<>&nbsp; &mdash; &nbsp; <PropertyDisplayValue propName="newstex_id" propValue={pub?.newstex_id} /></>
								)}
								{pub?.url && (
									<span className="ms-2">
										<PropertyDisplayValue
											propName="url"
											propValue={pub?.url} />
									</span>
								)}
							</h6>
						</div>
					</Col>
					<Col md={6}>
						<div className="breadcrumb-wrapper">
							<nav aria-label="breadcrumb">
								<ol className="breadcrumb">
									<ParentBreadcrumb obj={pub} />
									<li className="breadcrumb-item active">
										<a>{pub?.name}</a>
									</li>
								</ol>
							</nav>
							<div className="float-end">
								<RequirePermissions permissions={['admin']}>
									<span className="ps-1">
										<EditButton
											item={pub}
											size="sm"
											refreshHandler={() => {
												return fetchData(true);
											}}
										/>
									</span>
									{isPublication(pub) && (
										<span className="ps-1">
											<RedeliverButton item={pub} size="sm" />
										</span>
									)}
									<span className="ps-1">
										<SyncToHubSpotButton item={pub} size="sm" onSync={() => {
											// After a sync, wait one second before a full refresh
											setTimeout(() => {
												fetchData(true);
											}, 1000);
										}} />
									</span>
								</RequirePermissions>
								{pub?.newstex_id && (<>
									<RequirePermissions permissions={['admin']}>
										<span className="ps-1">
											<ViewDashboardButton
												id="a4a25c65-9616-4538-bcfa-e4dee8c665b5"
												tab="Story Counts"
												content={pub?.newstex_id}
												size="sm"
											/>
										</span>
									</RequirePermissions>
									<span className="ps-1">
										<ViewStoriesButton publication={pub?.$id} size="sm" />
									</span>
								</>)}
								<RequirePermissions permissions={['admin']}>
									<span className="ps-1">
										<RefreshButton size="sm" refreshHandler={() => {
											if (refreshFeeds.current) {
												refreshFeeds.current();
											}

											if (refreshContent.current) {
												refreshContent.current();
											}
											return fetchData(true);
										}} />
									</span>
								</RequirePermissions>
							</div>
						</div>
					</Col>
				</Row>
			</div>
			<hr />

			<Row>
				<Col lg={12} xxl={9}>
					<RequirePermissions permissions={['admin']}>
						{pub?.closed_sites_only && <Alert variant="warning" className="text-center">
							<FontAwesomeIcon icon={faLock} className="me-2" />
							WARNING: This {isPublisher(pub) ? 'publisher' : 'publication'} is only allowed for CLOSED SITE DISTRIBUTION
							<FontAwesomeIcon icon={faLock} className="ms-2" />
						</Alert>}
						{!loading && !pub?.calculate_royalties && <Alert variant="danger" className="text-center">
							<div>
								<span className="fa-layers fa-fw me-2">
									<FontAwesomeIcon icon={faDollarCircle} transform="grow-10" />
									<FontAwesomeIcon icon={faBan} className="text-danger" transform="grow-10" />
								</span>
								WARNING: This {isPublisher(pub) ? 'publisher' : 'publication'} will not be able to collect royalties
								<span className="fa-layers fa-fw ms-2">
									<FontAwesomeIcon icon={faDollarCircle} transform="grow-10" />
									<FontAwesomeIcon icon={faBan} className="text-danger" transform="grow-10" />
								</span>
							</div>
							<div className="mt-2 text-muted">
								This {isPublisher(pub) ? 'publisher' : 'publication'} is not synced with NewsCrunch, and will not be included in royalty reports.
								This is probably an error.
							</div>
						</Alert>}
						{pub && isPublication(pub) && pub.product_status && (
							<ProductStatusList
								inline
								value={pub.product_status}
							/>
						)}
					</RequirePermissions>
					<Card className="company">
						<Card.Body>
							<LoadingSpinner loading={loading} />
							{!loading && pub && (
								<Tabs
									defaultActiveKey={searchParams.get('tab') || 'default'}
									id="content-tabs"
									className="mb-3"
									onSelect={(key) => {
										setActiveTab(key || 'default');
										setSearchParams({
											tab: key || '',
										});
									}}
								>
									<Tab eventKey="default" title="Default View">
										<dl className="row properties-table">
											<dt className="col-sm-3">Name</dt>
											<dd className="col-sm-9"><PropertyDisplayValue propName="name" propValue={pub.name} /></dd>

											<dt className="col-sm-3">Newstex ID</dt>
											<dd className="col-sm-9"><PropertyDisplayValue propName="newstex_id" propValue={pub.newstex_id} /></dd>

											<dt className="col-sm-3">Type</dt>
											<dd className="col-sm-9"><PropertyDisplayValue propName="type" propValue={pub.$type} /></dd>

											<dt className="col-sm-3">
												<HeaderWithTooltip
													title="Country Focus"
													tooltip="The country that this content is focused on. (country_focus in HubSpot)"
													placement="left"
												/>
											</dt>
											<dd className="col-sm-9"><PropertyDisplayValue propName="country" propValue={pub.country} /></dd>

											{isPublication(pub) && (
												<>
													<dt className="col-sm-3">
														<HeaderWithTooltip
															title="Pub Country"
															tooltip="The country that this content is published in. (pub_country in HubSpot)"
															placement="left"
														/>
													</dt>
													<dd className="col-sm-9"><PropertyDisplayValue propName="pub_country" propValue={pub.pub_country} /></dd>

													<dt className="col-sm-3">Content Type</dt>
													<dd className="col-sm-9"><PropertyDisplayValue propName="content_type" propValue={pub.content_type} /></dd>
												</>
											)}

											<dt className="col-sm-3">Closed Sites Only</dt>
											<dd className="col-sm-9"><PropertyDisplayValue propName="closed_sites_only" propValue={pub.closed_sites_only ? 'Yes' : 'No'} /></dd>

											<dt className="col-sm-3">URL</dt>
											<dd className="col-sm-9"><PropertyDisplayValue propName="url" propValue={pub.url} /></dd>

											<dt className="col-sm-3">Language</dt>
											<dd className="col-sm-9"><PropertyDisplayValue propName="language" propValue={pub.language} /></dd>

											<dt className="col-sm-3">Status</dt>
											<dd className="col-sm-9"><PropertyDisplayValue propName="status" propValue={pub.status} /></dd>

											{isPublication(pub) && (
												<>
													<dt className="col-sm-3">Content Status</dt>
													<dd className="col-sm-9">
														<PropertyDisplayValue propName="content_status" propValue={pub.content_status} />
													</dd>
													{pub.status === 'Inactive' && (
														<>
															<dt className="col-sm-3">Archive Date</dt>
															<dd className="col-sm-9"><PropertyDisplayValue propName="archive_date" propValue={pub.archive_date} /></dd>

															<dt className="col-sm-3">Deletion Date</dt>
															<dd className="col-sm-9"><PropertyDisplayValue propName="deletion_date" propValue={pub.deletion_date} /></dd>

															<dt className="col-sm-3">Termination Reason</dt>
															<dd className="col-sm-9"><PropertyDisplayValue propName="termination_reason" propValue={pub.termination_reason} /></dd>
														</>
													)}

													<dt className="col-sm-3">
														<HeaderWithTooltip
															title="Country Focus"
															tooltip="The country that this content is focused on."
															placement="left"
														/>
													</dt>
													<dd className="col-sm-9"><PropertyDisplayValue propName="country" propValue={pub.country} /></dd>

													<dt className="col-sm-3">
														<HeaderWithTooltip
															title="Country of Origin"
															tooltip="The country that this content is published in. (pub_country)"
															placement="left"
														/>
													</dt>
													<dd className="col-sm-9"><PropertyDisplayValue propName="pub_country" propValue={pub.pub_country} /></dd>
												</>
											)}
											<dt className="col-sm-3">
												<HeaderWithTooltip
													title="Technical Contact Email"
													tooltip="Email addresses that will be automatically notified when a feed issue is detected with any related Content records."
													placement="left"
												/>
											</dt>
											<dd className="col-sm-9">
												<PropertyDisplayValue propName="technical_contact_email" propValue={pub.technical_contact_email} />
											</dd>
											<dt className="col-sm-3">
												<HeaderWithTooltip
													title="Owner Emails"
													tooltip="Non-newstex users that are allowed to view revenue and usage information for this Content record."
													placement="left"
												/>
											</dt>
											<dd className="col-sm-9">
												<PropertyDisplayValue propName="owner_emails" propValue={pub.owner_emails} />
											</dd>

											<dt className="col-sm-3">Created</dt>
											<dd className="col-sm-9">
												<PropertyDisplayValue propName="created_at" propValue={pub.created_at} />
												{pub.created_by && (
													<>
														&nbsp;by&nbsp;
														<PropertyDisplayValue propName="created_by" propValue={pub.created_by} />
													</>
												)}
											</dd>

											<dt className="col-sm-3">
												<HeaderWithTooltip
													title="Last Updated"
													tooltip="The date and time when this record was last updated."
													placement="left"
												/>
											</dt>
											<dd className="col-sm-9">
												<PropertyDisplayValue propName="modified_at" propValue={pub.modified_at} />
												{pub.modified_by && (
													<>
														&nbsp;by&nbsp;
														<PropertyDisplayValue propName="modified_by" propValue={pub.modified_by} />
													</>
												)}
											</dd>

											<dt className="col-sm-3">
												<HeaderWithTooltip
													title="Last Post Date"
													tooltip="The date and time when we most recently received a post from this feed."
													placement="left"
												/>
											</dt>
											<dd className="col-sm-9">
												<PropertyDisplayValue propName="last_post_date" propValue={pub.last_post_date} />
											</dd>

											<dt className="col-sm-3">
												<HeaderWithTooltip
													title="Last Story Received"
													tooltip="The date and time when we most recently received a story from this feed."
													placement="left"
												/>
											</dt>
											<dd className="col-sm-9">
												<PropertyDisplayValue propName="last_received_date" propValue={pub.last_received_date} />
											</dd>

											<dt className="col-sm-3">Last Editorial Review</dt>
											<dd className="col-sm-9">
												<PropertyDisplayValue propName="last_editorial_review" propValue={pub.last_editorial_review} />
												{pub.reviewed_by && (
													<>
														&nbsp;by&nbsp;
														<PropertyDisplayValue propName="reviewed_by" propValue={pub.reviewed_by} />
													</>
												)}
												{pub.last_review_comment && (
													<>
														&nbsp;&mdash;&nbsp;
														<PropertyDisplayValue propName="last_review_comment" propValue={pub.last_review_comment} />
													</>
												)}
											</dd>

											<dt className="col-sm-3">Description</dt>
											<dd className="col-sm-9"><PropertyDisplayValue propName="description" propValue={pub.description} /></dd>

										</dl>
									</Tab>

									<Tab eventKey="integration" title="Integration Details">
										<dl className="row properties-table">
											<dt className="col-sm-3">Newstex ID</dt>
											<dd className="col-sm-9"><PropertyDisplayValue propName="newstex_id" propValue={pub.newstex_id} /></dd>

											<dt className="col-sm-3">Content Feed URL(s)</dt>
											<dd className="col-sm-9"><PropertyDisplayValue propName="feed_url" propValue={pub.xml_feed} /></dd>

											{isPublication(pub) && (
												<>
													<dt className="col-sm-3">Calculate Royalties</dt>
													<dd className="col-sm-9"><PropertyDisplayValue propName="calculate_royalties" propValue={pub.calculate_royalties} /></dd>

													<dt className="col-sm-3">Closed Sites Only</dt>
													<dd className="col-sm-9"><PropertyDisplayValue propName="closed_sites_only" propValue={pub.closed_sites_only} /></dd>

													<dt className="col-sm-3">Integration Completion Date</dt>
													<dd className="col-sm-9"><PropertyDisplayValue propName="integration_completion_date" propValue={pub.integration_completion_date} /></dd>

												</>
											)}
										</dl>
									</Tab>
									<Tab
										eventKey="story-stats"
										title="Story Stats"
										disabled={!pub.newstex_id}
									>
										{pub.newstex_id && activeTab === 'story-stats' && <StoryStats content={pub} defaultPeriod="day" />}
									</Tab>
									<Tab
										eventKey="revenue"
										title="Revenue"
										disabled={!pub.newstex_id}
									>
										{pub.newstex_id && activeTab === 'revenue' && <RevenueStats content={pub} />}
									</Tab>
									{hasPermission(['admin'], userInfo) && (
										<Tab eventKey="properties" title="All Properties">
											<PropertiesTable item={pub} />
										</Tab>
									)}
									{hasPermission(['admin'], userInfo) && isPublication(pub) && (
										<Tab eventKey="notes" title="Editorial Notes">
											{/* Defer loading of the editorial notes table until the tab is active */}
											{pub.newstex_id && activeTab === 'notes' && <EditorialNotesTable item={pub} />}
										</Tab>
									)}
								</Tabs>
							)}
						</Card.Body>
						<RequirePermissions permissions={['admin']}>
							{pub
								&& <Card.Footer className="d-flex justify-content-center">
									{
										pub.status === 'Active' && <span className="ps-1">
											<Button size="sm" variant="outline-danger"
												title="Archive Content"
												onClick={() => {
													setContentUpdates({
														status: 'Archived',
													});
													setUpdateModalTitle('Archive Content');
													setShowUpdateModal(true);
												}}>Archive Content</Button>
										</span>
									}
									{
										pub.status !== 'Active' && <span className="ps-1">
											<Button size="sm" variant="outline-success"
												title="Activate Content"
												onClick={() => {
													if (isPublication(pub)) {
														setContentUpdates({
															status: 'Active',
															content_status: ContentStatus.ACTIVE,
															calculate_royalties: true,
														});
													} else {
														setContentUpdates({
															status: 'Active',
														});
													}
													setUpdateModalTitle('Activate Content');
													setShowUpdateModal(true);
												}}>Activate Content</Button>
										</span>
									}
									{
										pub.status === 'Lead' && <span className="ps-1">
											<Button size="sm" variant="outline-danger"
												title="Reject Lead"
												onClick={() => {
													if (isPublication(pub)) {
														setContentUpdates({
															status: 'Inactive',
															content_status: ContentStatus.NON_QUALIFYING,
														});
													} else {
														setContentUpdates({
															status: 'Inactive',
														});
													}
													setUpdateModalTitle('Reject Lead');
													setShowUpdateModal(true);
												}}>Reject Lead</Button>
										</span>
									}

								</Card.Footer>
							}
						</RequirePermissions>
					</Card>
				</Col>
				<Col lg={12} xxl={3}>
					<RequirePermissions permissions={['admin']}>
						{pub?.parent === NEWSTEX_PARENT_ID && isPublication(pub) && (
							<span className="float-end pb-1 pt-0 mt-0">
								<CreateStoryButton publication={pub} size="sm" />
							</span>
						)}
					</RequirePermissions>

					<ChildContent pub={pub} key="child-contents" refresh={refreshContent} />
					<ChildFeed pub={pub} key="child-feeds" refresh={refreshFeeds} />
				</Col>
			</Row>
		</Container>
	);
}
