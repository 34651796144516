import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { Category } from '@newstex/types/category';
import { createColumnHelper } from '@tanstack/react-table';
import { useMemo, useRef } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import DataTable, { DataTableMethods } from '../DataTable';
import { LoadMoreButton } from '../load-more-button';
import { PropertyDisplayValue } from '../property-display-value';

export function CategoriesTable(
	{
		items,
		searchText,
		hasMore,
		onLoadMore,
	}: {
		items: Category[],
		searchText?: string,
		hasMore?: boolean,
		onLoadMore?: () => void,
	},
) {
	const dataTableRef = useRef<DataTableMethods>(null);
	const columnHelper = createColumnHelper<Category>();
	const columns = useMemo(() => [
		columnHelper.accessor('code', {
			header: 'Code',
			enableSorting: !hasMore,
			cell: ({ getValue, row: { original }}) => {
				return <>
					<Link to={`/categories/${original.code}`}>
						<PropertyDisplayValue propName="code" propValue={getValue()} />
					</Link>
				</>;
			},
		}),
		columnHelper.accessor('name', {
			header: 'Name',
			enableSorting: !hasMore,
			cell: ({ getValue, row: { original }}) => {
				return <>
					<Link to={`/categories/${original.code}`}>
						<PropertyDisplayValue propName="name" propValue={getValue()} />
					</Link>
				</>;
			},
		}),
		columnHelper.accessor('type', {
			header: 'Type',
			enableSorting: !hasMore,
		}),
		columnHelper.accessor('last_applied', {
			header: 'Last Applied',
			enableSorting: !hasMore,
			cell: ({ getValue }) => {
				return <PropertyDisplayValue propName="last_applied" propValue={getValue()} />;
			},
		}),
		columnHelper.accessor('story_count', {
			header: 'Story Count',
			enableSorting: !hasMore,
			cell: ({ getValue, row: { original }}) => {
				let storyCount = getValue();
				if (typeof storyCount === 'string') {
					storyCount = parseInt(storyCount, 10);
				}
				return <Link to={`/stories?categories=${original.code}`}>
					<PropertyDisplayValue propName="story_count" propValue={storyCount} />
				</Link>;
			},
		}),
	], [items]);

	console.log('items', {
		items,
		searchText,
		hasMore,
		onLoadMore,
	});
	return (<>
		<DataTable
			columns={columns}
			items={items || []}
			ref={dataTableRef}
			searchText={searchText}
			striped
		/>
		<LoadMoreButton show={hasMore} onClick={onLoadMore} />
		<div className="float-end pb-2">
			<Button variant="outline-secondary" size="sm" onClick={() => {
				dataTableRef.current?.exportToCSV('Categories');
			}}>
				<FontAwesomeIcon icon={faDownload} /> CSV
			</Button>
		</div>
	</>);
}
