import { Form } from 'react-bootstrap';

import { EditTextFieldProps } from './text';

export interface ProvenanceEditorProps extends Partial<EditTextFieldProps<'HubSpotCompany'>> {
	label?: string;
	className?: string;
}

export function ProvenanceEditor({
	propKey = 'provenance',
	updates,
	setUpdates,
	readOnly,
	label = 'Provenance',
	className = 'mb-3',
}: ProvenanceEditorProps) {
	return (
		<Form.Group className={className}>
			<Form.Label>{label}</Form.Label>
			<Form.Select
				name={propKey}
				value={updates?.[propKey] as string || ''}
				onChange={(e) => {
					if (!readOnly && setUpdates) {
						setUpdates({
							...updates,
							[propKey]: e.target.value,
						});
					}
				}}
				disabled={readOnly}
			>
				<option value="">[Not Set]</option>
				<option value="AI">AI</option>
				<option value="Amplemarket">Amplemarket</option>
				<option value="Apollo">Apollo</option>
				<option value="Cognism">Cognism</option>
				<option value="Content Acquisition Team">Content Acquisition Team</option>
				<option value="Created in HubSpot">Created in HubSpot</option>
				<option value="Created via email extension for HubSpot">Created via email extension for HubSpot</option>
				<option value="Direct Email">Direct Email</option>
				<option value="Direct Traffic">Direct Traffic</option>
				<option value="Documents Tool">Documents Tool</option>
				<option value="Email Marketing">Email Marketing</option>
				<option value="Eventbrite">Eventbrite</option>
				<option value="Feedly">Feedly</option>
				<option value="FeedSpot">FeedSpot</option>
				<option value="LinkedIn">LinkedIn</option>
				<option value="NewsCore">NewsCore</option>
				<option value="NewsCrunch">NewsCrunch</option>
				<option value="Nimble">Nimble</option>
				<option value="Organic Search">Organic Search</option>
				<option value="Organic Social">Organic Social</option>
				<option value="Other Campaigns">Other Campaigns</option>
				<option value="Paid Search">Paid Search</option>
				<option value="Paid Social">Paid Social</option>
				<option value="Reddit">Reddit</option>
				<option value="Referrals">Referrals</option>
				<option value="Referred from Company">Referred from Company</option>
				<option value="Smartsheet">Smartsheet</option>
				<option value="ZoomInfo">ZoomInfo</option>
			</Form.Select>
		</Form.Group>
	);
}
