// stories
import { faNewspaper } from '@fortawesome/free-solid-svg-icons';
import { faEdit } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SearchResults } from '@newstex/search/client';
import { Publication } from '@newstex/types/content';
import { Story } from '@newstex/types/story';
import omit from 'object.omit';
import { useState } from 'react';
import {
	Button,
	Form,
	OverlayTrigger,
	Table,
	Tooltip,
} from 'react-bootstrap';
import { Link, useSearchParams } from 'react-router-dom';
import { CreateEditorialNoteModal } from '~/components/create-editorial-note-modal';
import { DateTime } from '~/components/date-time';
import { GroupSelectionActions } from '~/components/group-selection-actions';
import { PropertyDisplayValue } from '~/components/property-display-value';
import { SearchFacetConfig, SearchWithFacets } from '~/components/search-with-facets';
import { StoriesTable } from '~/components/tables/stories-table';

export const StoryFacetConfig: SearchFacetConfig[] = [
	{
		attribute: 'date',
		title: 'Publication Date',
		type: 'Date',
	},
	{
		attribute: 'received_at',
		title: 'Received At',
		type: 'Date',
	},
	{
		attribute: '_tags',
		title: 'Tags',
	},
	{
		attribute: 'categories',
		title: 'Category',
	},
	{
		attribute: 'language',
		title: 'Language',
	},
	{
		attribute: 'products',
		title: 'Products',
		searchable: true,
	},
	{
		attribute: 'content',
		title: 'Content',
		searchable: true,
	},
	{
		attribute: 'source',
		title: 'Source (byline)',
	},
	{
		attribute: 'provider_categories',
		title: 'Provider Categories',
	},
	{
		attribute: 'provider_tickers',
		title: 'Provider Tickers',
	},
];

export const GroupedStoriesTable: React.FC<{
	groupedHits: SearchResults<Story>['grouped_hits'],
}> = ({
	groupedHits,
}) => {
	const [searchParams] = useSearchParams();
	const [editingPublication, setEditingPublication] = useState<Partial<Publication> | null>(null);
	const [selectedGroups, setSelectedGroups] = useState<string[]>([]);

	console.log('Grouped Hits', groupedHits);
	return (<>
		{editingPublication && (
			<CreateEditorialNoteModal
				publication={editingPublication}
				show={!!editingPublication}
				onHide={() => setEditingPublication(null)}
				onSave={() => {
					window.location.reload();
				}}
			/>
		)}

		<Table striped bordered hover>
			<thead>
				<tr>
					<th>
						<GroupSelectionActions
							selectedGroups={selectedGroups}
							groupKey="publication"
							onClearSelection={() => setSelectedGroups([])}
							onSelectAll={() => setSelectedGroups(groupedHits?.map((g) => g.group_key[0]) || [])}
						/>
					</th>
					<th>Publication</th>
					<th>Stories</th>
					<th>Most Recent Date</th>
					<th>Average Monthly Revenue</th>
					<th>Last Reviewed</th>
				</tr>
			</thead>
			<tbody>
				{groupedHits?.map((group) => (
					<tr key={group.group_key[0]}>
						<td>
							<Form.Check
								type="checkbox"
								checked={selectedGroups.includes(group.group_key[0])}
								onChange={(e) => {
									if (e.target.checked) {
										setSelectedGroups([...selectedGroups, group.group_key[0]]);
									} else {
										setSelectedGroups(selectedGroups.filter((g) => g !== group.group_key[0]));
									}
								}}
							/>
						</td>
						<td>
							<Link to={`/publications/${group.group_key[0]}`}>
								{group.hits?.[0]?.Publication?.name || group.group_key[0]}
								{group.hits?.[0]?.Publication?.newstex_id && ` [${group.hits?.[0]?.Publication?.newstex_id}]`}
							</Link>
							{group.hits?.[0]?.Publication?.Publisher?.name && (<div className="text-muted">
								<small>
									{group.hits[0].Publication.Publisher.name} {group.hits[0].Publication.Publisher.newstex_id && `[${group.hits[0].Publication.Publisher.newstex_id}]`}
								</small>
							</div>)}
						</td>
						<td><Link to={`?${new URLSearchParams({
							...omit(Object.fromEntries(searchParams.entries()), ['group_by', 'sort_by', 'page']),
							sort_by: 'received_at:desc',
							[searchParams.get('group_by') || 'group']: group.group_key[0],
						})}`}>{group.found}</Link></td>
						<td><DateTime ts={group.hits?.[0]?.received_at} dateOnly /></td>
						<td>{group.hits?.[0]?.Publication?.average_monthly_revenue?.toLocaleString('en-US', {
							style: 'currency',
							currency: 'USD',
						})}</td>
						<td>
							{group.hits?.[0]?.Publication?.last_editorial_review && (<>
								<div>
									<DateTime ts={group.hits[0].Publication.last_editorial_review} dateOnly />
									{group.hits[0].Publication.reviewed_by && (<span className="text-muted">
										&nbsp;&mdash; <PropertyDisplayValue propValue={group.hits[0].Publication.reviewed_by} propName="reviewed_by" />
									</span>)}
								</div>
								{group.hits[0].Publication.last_review_comment && (<div className="comment">
									{group.hits[0].Publication.last_review_comment}
								</div>)}
							</>)}
							{group.hits[0].Publication && (
								<div className="float-end">
									<OverlayTrigger
										placement="left"
										overlay={<Tooltip>Edit publication notes</Tooltip>}
									>
										<Button variant="link" onClick={() => {
											setEditingPublication({
												$type: 'Publication',
												...group.hits[0].Publication,
											});
										}}>
											<FontAwesomeIcon icon={faEdit} />
										</Button>
									</OverlayTrigger>
								</div>
							)}
						</td>
					</tr>
				))}
			</tbody>
		</Table>
	</>);
};

export const StorySearchPage = () => {
	const fallbackSearch = async (q: string): Promise<Story[]> => {
		console.log('Fallback Search', q);
		return [];
	};

	return (
		<SearchWithFacets
			indexName="Story"
			title="Stories"
			info="Stories are searchable for 60 days, except for high volume publishers, which are searchable for 1 day."
			defaultSort="received_at:desc"
			resultsTable={StoriesTable}
			facetStats={{
				received_at: 'Received',
				date: 'Published',
			}}
			groupByOptions={[{
				id: 'publication',
				icon: faNewspaper,
				sort: '_group_found:desc,received_at:desc',
			}]}
			fallbackSearch={fallbackSearch}
			includeFields='$Publication(id,name,newstex_id,average_monthly_revenue,last_editorial_review,reviewed_by,last_review_comment,$Publisher(id,name,newstex_id))'
			groupTable={GroupedStoriesTable}
			facets={StoryFacetConfig}
		/>
	);
};
