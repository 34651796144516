import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { NormalizedSearchResponse } from '@newstex/search/types';
import { Publication } from '@newstex/types/content';
import { createColumnHelper } from '@tanstack/react-table';
import { useMemo, useRef, useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { formatDateString, formatDateYYYYMMDD } from '~/lib/utils';
import { useUserInfo } from '~/providers/user-info';

import Avatar from './Avatar';
import ContentActions from './content-actions';
import DataTable, { DataTableMethods } from './DataTable';
import { headerWithTooltip } from './header-with-tooltip';
import { PropertyDisplayValue } from './property-display-value';
import { StatusBadge } from './status-badge';

export type PublicationSearchHit = Publication & {
	id: string,
	Publisher?: {
		id: string;
		name: string;
		newstex_id: string;
	};
};
export type IntegratedContent = NormalizedSearchResponse<PublicationSearchHit> | null;

export interface ContentProps {
	searchResponse: IntegratedContent;
	title: string;
	storyCounts?: Record<string, number> | null;
}

export default function RecentlyIntegratedContent({ searchResponse, title, storyCounts }: ContentProps) {
	// TODO: Find a less hacky way to make the data table re-fetch when the story counts change
	if (storyCounts === undefined) {
		return null;
	}
	const userInfo = useUserInfo();
	const dataTableRef = useRef<DataTableMethods>(null);
	const [searchText, setSearchText] = useState<string>('');
	const columnHelper = createColumnHelper<PublicationSearchHit>();
	const columns = useMemo(() => [
		columnHelper.accessor('name', {
			header: 'Name',
			enableSorting: true,
			cell: ({ getValue, row: { original }}) => {
				if (!original) {
					return null;
				}
				return (
					<div className="d-flex align-items-center">
						<Avatar
							image={original.logo_url}
							text={original.name}
							variant="primary"
							alt={original.name}
							border
							className={'me-2'}
							style={{ padding: '0.25rem' }}
						/>
						<div className="pt-1">
							<Link to={`/content/${original.id}`}>
								<strong>{getValue()}</strong>
							</Link>
							<br />
							<span className="text-muted text-sm">
								<PropertyDisplayValue
									propName="url"
									propValue={original.url}
									className="text-secondary"
								/>
							</span>
						</div>
					</div>
				);
			},
		}),
		columnHelper.accessor('status', {
			header: 'Status',
			cell: ({ getValue }) => {
				return <StatusBadge status={getValue()} indicator />;
			},
		}),
		columnHelper.accessor('parent', {
			header: 'Publisher',
			cell: ({ row: { original }}) => {
				return (
					<div
						className="d-flex align-items-center"
						style={{ minWidth: 125 }}
					>
						{original?.Publisher?.id && (
							<Link to={`/publishers/${original?.Publisher?.id}`}>
								<span className="me-2">
									{original?.Publisher?.name}
									{original?.Publisher?.newstex_id && (
										<div className="text-muted text-sm">
											{` ${original?.Publisher?.newstex_id}`}
										</div>
									)}
								</span>
							</Link>
						)}
					</div>
				);
			},
		}),
		columnHelper.accessor('integration_completion_date', {
			header: headerWithTooltip({
				title: 'Integrated Date',
				tooltip: 'The date we first activated a feed for this content.',
			}),
			enableSorting: true,
			cell: ({ getValue }) => {
				return (
					<div className="d-flex align-items-center justify-content-between">
						<span className="me-3">
							<PropertyDisplayValue
								propName="integration_completion_date"
								propValue={getValue()}
							/>
						</span>
					</div>
				);
			},
		}),
		columnHelper.accessor('last_post_date', {
			header: headerWithTooltip({
				title: 'Last Post Date',
				tooltip: 'The date provided on the most recently received story for this content.',
			}),
			enableSorting: true,
			cell: ({ getValue }) => {
				return (
					<div className="d-flex align-items-center justify-content-between">
						<span className="me-3">
							<PropertyDisplayValue
								propName="last_post_date"
								propValue={getValue()}
							/>
						</span>
					</div>
				);
			},
		}),
		columnHelper.accessor((original) => {
			return storyCounts ? storyCounts[original.newstex_id || ''] || 0 : '...';
		}, {
			id: 'stories',
			header: headerWithTooltip({
				title: 'Stories (30d)',
				tooltip: 'The number of stories received for this content in the last 30 days.',
			}),
			enableSorting: true,
			cell: ({ getValue, row: { original }}) => {
				return (
					<div className="d-flex align-items-center justify-content-between">
						<span className="me-3">
							<Link to={`/stories?content=${original.newstex_id}`}>
								{getValue().toLocaleString()}
							</Link>
						</span>
					</div>
				);
			},
		}),
		columnHelper.display({
			id: 'actions',
			cell: ({ row: { original }}) => {
				return <ContentActions content={original} />;
			},
		}),
	], [storyCounts]);

	return (
		<Card className="card-table mb-4">
			<Card.Header>
				<div className="card-heading">{title}
					<Form
						className="d-inline-block float-end"
						onSubmit={(e) => e.preventDefault()}
					>
						<Form.Control
							type="text"
							placeholder={'Search'}
							onChange={(e) => setSearchText(e.target.value)}
							onSubmit={(e) => e.preventDefault()}
						/>
					</Form>
				</div>
			</Card.Header>
			<Card.Body>
				<DataTable
					columns={columns}
					columnVisibility={{
						actions: Boolean(userInfo?.auth_groups?.includes('admin')),
					}}
					items={searchResponse?.hits || []}
					defaultPageSize={20}
					defaultSort="integration_completion_date"
					searchText={searchText}
					ref={dataTableRef}
					nameAsc
					striped
				/>
			</Card.Body>
			<Card.Footer>
				<div className="float-end">
					<Button variant="outline-secondary" size="sm" onClick={() => {
						dataTableRef.current?.exportToCSV(`Recently Integrated - ${formatDateYYYYMMDD(new Date())}`, {
							'Publisher Newstex ID': (original) => original.Publisher?.newstex_id || '',
							'Publisher Name': (original) => original.Publisher?.name || '',
							'Publication Newstex ID': (original) => original.newstex_id || '',
							'Publication Name': 'name',
							'Publication URL': 'url',
							'Publication Status': 'status',
							'Feed URL': 'feed_url',
							'Integration Completion Date': (original) => formatDateString(original.integration_completion_date),
							'Last Post Date': (original) => formatDateString(original.last_post_date),
							'Stories (30d)': (original) => String(storyCounts?.[original.newstex_id || ''] || 0),
						});
					}}>
						<FontAwesomeIcon icon={faDownload} /> CSV
					</Button>
				</div>
			</Card.Footer>
		</Card>
	);
}
