import { Publication } from '@newstex/types/content';
import { Story } from '@newstex/types/story';
import { marked } from 'marked';
import { useState } from 'react';
import {
	Alert,
	Button,
	Form,
	Modal,
} from 'react-bootstrap';
import ReactQuill from 'react-quill';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAPI } from '~/providers/api-provider';
import 'react-quill/dist/quill.snow.css';

interface CreateStoryModalProps {
	publication: Publication;
	show: boolean;
	onClose: (saved: boolean) => void;
}

const REQUIRED_FIELDS: (keyof Story)[] = ['headline', 'external_id', 'html_content'];

export function CreateStoryModal({
	publication, show, onClose,
}: CreateStoryModalProps) {
	if (!publication) {
		console.trace('Publication is required');
		return null;
	}
	const navigate = useNavigate();
	const [error, setError] = useState<string | null>(null);
	const [newStory, setNewStory] = useState<Story>({
		__type__: 'Story',
		__id__: '',
		headline: '',
		external_id: `${new URL(publication.url || 'blog.newstex.com').hostname}-${Date.now()}`,
		html_content: '',
		provider_categories: [],
		provider_tickers: [],
	});
	const api = useAPI();

	const handleSave = async () => {
		if (!publication) {
			setError('Publication is required');
			return;
		}
		const missingFields = REQUIRED_FIELDS.filter((key) => !newStory[key]);
		if (missingFields.length > 0) {
			setError(`Missing required fields: ${missingFields.join(', ')}`);
			return;
		}
		console.log('Save newStory', newStory, publication);
		try {
			const response = await api.fetchWithAuth<Story>('resources/Story', {
				method: 'POST',
				body: JSON.stringify({ ...newStory, publication: publication.$id }),
			});

			if (response) {
				onClose(true);
				toast.success('Story created successfully', {
					onClick: () => {
						navigate(`/stories/${response.__id__}`);
					},
				});
			} else {
				console.error('Failed to save story');
				toast.error(`Failed to save story: ${response}`);
			}
		} catch (err) {
			console.error('Error saving story:', err);
		}
	};

	const handleContentChange = async (value: string, isMarkdown = false) => {
		console.log('handleContentChange', value);
		// Handle pasted markdown conversion to html
		if (
			typeof value === 'string'
			&& (
				isMarkdown
				|| (
					!newStory.html_content
					&& (value.trim().includes('**') || value.trim().includes('###'))
				)
			)
		) {
			// Look for a `Headline:` in the first few lines, and if found use it as
			// the story headline
			let cleanValue = value.replace(/<\/?(p|br)>/g, '\r\n');
			const lines = cleanValue.split('\r\n');
			console.log(lines.slice(0, 10));
			const headlineLine = lines.slice(0, 10).find((line) => line.trim().match(/^[#*]*\s*Headline:\s*(.+?)\s*[#*]*$/));
			if (headlineLine) {
				const headline = headlineLine.replace(/^[#*]*\s*Headline:\s*/, '').replace(/\s*[#*]*$/, '').trim();
				cleanValue = cleanValue.replace(headlineLine, '').trim();
				setNewStory((s) => ({ ...s, headline }));
			}
			const htmlContent = await marked(cleanValue);
			setNewStory((s) => ({ ...s, html_content: htmlContent }));
		} else {
			setNewStory((s) => ({ ...s, html_content: value }));
		}
	};

	return (
		<Modal show={show} onHide={() => onClose(false)} size="xl" fullscreen>
			<Modal.Header closeButton>
				<Modal.Title>Create New Story for {publication.name}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{error && <Alert className="text-center mb-3" variant="danger">{error}</Alert>}
				<Form>
					<Form.Group className="mb-3">
						<Form.Label>GUID</Form.Label>
						<Form.Control
							type="text"
							tabIndex={1}
							value={newStory.external_id}
							onChange={(e) => setNewStory({ ...newStory, external_id: e.target.value })}
							required
						/>
					</Form.Group>
					<Form.Group className="mb-3">
						<Form.Label>Headline</Form.Label>
						<Form.Control
							type="text"
							value={newStory.headline}
							onChange={(e) => setNewStory({ ...newStory, headline: e.target.value })}
							required
							tabIndex={0}
						/>
					</Form.Group>
					<Form.Group className="mb-3">
						<Form.Label>Thumbnail URL</Form.Label>
						<Form.Control
							type="url"
							value={newStory.thumb_url}
							onChange={(e) => setNewStory({ ...newStory, thumb_url: e.target.value })}
							placeholder="https://static.newstex.blog/img/finance.webp"
							tabIndex={1}
						/>
					</Form.Group>
					<Form.Group className="mb-3">
						<Form.Label>Categories</Form.Label>
						<Form.Control
							type="text"
							value={newStory.provider_categories?.join(', ') ?? ''}
							onChange={(e) => setNewStory({ ...newStory, provider_categories: e.target.value.split(',').map((c) => c.trim()) })}
							placeholder="Finance, Business, Technology"
							maxLength={255}
							tabIndex={1}
						/>
					</Form.Group>
					<Form.Group className="mb-3">
						<Form.Label>Excerpt</Form.Label>
						<Form.Control
							type="text"
							value={newStory.excerpt ?? ''}
							onChange={(e) => setNewStory({ ...newStory, excerpt: e.target.value })}
							placeholder="A short description of the story"
							maxLength={255}
							tabIndex={1}
						/>
					</Form.Group>
					<Form.Group className="mb-3">
						<Form.Label>Content</Form.Label>
						<ReactQuill
							theme="snow"
							value={newStory.html_content ?? ''}
							tabIndex={2}
							style={{ height: '500px' }}
							onChange={async (value) => {
								handleContentChange(value);
							}}
						/>
					</Form.Group>
					<div className="clearfix" />
					<div className="d-flex justify-content-end" style={{ marginTop: '60px' }}>
						<Button size="sm" disabled={!newStory.html_content} variant="outline-secondary" onClick={() => {
							handleContentChange(newStory.html_content ?? '', true);
						}}>Convert from Markdown</Button>
					</div>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={() => onClose(false)}>
					Cancel
				</Button>
				<Button variant="primary" onClick={handleSave}>
					Create Story
				</Button>
			</Modal.Footer>
		</Modal>
	);
}
