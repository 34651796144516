import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef, useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';

import { RefreshButton } from './refresh-button';

/**
 * A native search box with hooks
 */
export function SearchBox({
	refineHook,
	refreshHook,
	initialQuery = '',
	searchAsYouType = true,
	disableSearch = false,
}: {
	refineHook?: (query: string) => void;
	refreshHook?: () => Promise<void>;
	initialQuery?: string;
	searchAsYouType?: boolean;
	disableSearch?: boolean;
}) {
	const [loading, setLoading] = useState(false);
	const [inputValue, setInputValue] = useState(initialQuery);
	const inputRef = useRef<HTMLInputElement>(null);
	const [searchParams, setSearchParams] = useSearchParams();

	function refine(query: string) {
		if (!disableSearch) {
			setSearchParams((params) => {
				if (query) {
					params.set('q', query);
				} else {
					params.delete('q');
				}
				return params;
			});
		}
		refineHook?.(query);
	}

	function setQuery(newQuery: string) {
		setInputValue(newQuery);

		if (inputRef.current) {
			inputRef.current.value = newQuery;
		}

		if (searchAsYouType) {
			refine(newQuery);
		}
	}

	function onReset() {
		setQuery('');

		if (!searchAsYouType) {
			refine('');
		}
	}

	function onChange(event: React.ChangeEvent<HTMLInputElement>) {
		setInputValue(event.currentTarget.value);
		if (searchAsYouType) {
			refine(event.currentTarget.value);
		}
	}

	function onSubmit(event: React.KeyboardEvent<any>) {
		if (!searchAsYouType) {
			refine(inputValue);
		}
	}

	useEffect(() => {
		if (disableSearch) {
			if (inputValue) {
				setQuery('');
			}
			return;
		}
		const q = searchParams.get('q') || '';
		if (q !== inputValue) {
			setQuery(q);
		}
	}, [searchParams, inputRef.current, disableSearch]);

	return (
		<InputGroup className="mb-3">
			<InputGroup.Text>
				<FontAwesomeIcon icon={faSearch} />
			</InputGroup.Text>
			<Form.Control
				placeholder={disableSearch ? 'Search Disabled' : 'Search'}
				aria-label="Search"
				aria-describedby="basic-addon1"
				autoFocus
				ref={inputRef}
				disabled={disableSearch || loading}
				onChange={onChange}
				onKeyDown={(event) => {
					if (event.key === 'Enter') {
						return onSubmit(event);
					}

					if (event.key === 'Escape') {
						return onReset();
					}
				}}
			/>
			{refreshHook && <RefreshButton refreshHandler={refreshHook} /> }
		</InputGroup>
	);
}
