import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Content } from '@newstex/types/content';
import { Feed } from '@newstex/types/feed';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { HubSpotLink } from './hubspot-link';
import { PropertyDisplayValue } from './property-display-value';
import { StatusBadge } from './status-badge';

interface FeedCardProps {
	item?: Feed;
	pub?: Content;
	hitCount?: number;
}

export function FeedCard(props: FeedCardProps) {
	if (!props.item) {
		return <></>;
	}
	return (
		<Card className="feed">
			<Card.Body>
				<Card.Title>
					<Link to={`/feeds/${props.item.$id}`}>
						{props.item.name}
						{props.hitCount && (
							<>
								&nbsp;
								<span className="badge text-bg-secondary">{props.hitCount}</span>
								&nbsp;
							</>
						)}
					</Link>
					<div className="float-end">
						<HubSpotLink item={props.item} size={28} />
					</div>
					<h6 className="text-muted mb-3">
						{props.item.type}
						<div className="float-end">
							<StatusBadge status={props.item.status} />
						</div>
					</h6>
				</Card.Title>
				<div className="card-text">
					<dl>
						{props.item.feed_uri && (<>
							<dt>Feed URI</dt>
							<dd>
								<a className="website" href={props.item.feed_uri} target="_blank">
									{props.item.feed_uri} <FontAwesomeIcon icon={faExternalLinkAlt} />
								</a>
							</dd>
						</>)}
						{props.item.created_at && (<>
							<dt>Created At</dt>
							<dd>
								<PropertyDisplayValue
									propName="created_at"
									propValue={props.item.created_at}
								/>
							</dd>
						</>)}
						{props.item.last_read && (<>
							<dt>Last Read</dt>
							<dd>
								<PropertyDisplayValue
									propName="last_read"
									propValue={props.item.last_read}
								/>
							</dd>
						</>)}
						{props.item.next_read && (<>
							<dt>Next Read</dt>
							<dd>
								<PropertyDisplayValue
									propName="next_read"
									propValue={props.item.next_read}
								/>
							</dd>
						</>)}
						{props.item.last_error && (<>
							<dt>Last Error</dt>
							<dd>
								<PropertyDisplayValue
									propName="last_error"
									propValue={props.item.last_error}
								/>
							</dd>
						</>)}
						<dt>Last Post Date</dt>
						<dd>
							<PropertyDisplayValue
								propName="last_post_date"
								propValue={props.item.last_post_date}
							/>
						</dd>
						<dt>Last Received</dt>
						<dd>
							<PropertyDisplayValue
								propName="last_received_date"
								propValue={props.item.last_received_date}
							/>
						</dd>
						<dt>Priority</dt>
						<dd>
							<PropertyDisplayValue
								propName="priority"
								propValue={props.item.priority || 'default'}
							/>
						</dd>
						<dt>Segment</dt>
						<dd>
							<PropertyDisplayValue
								propName="segment"
								propValue={props.item.segment}
							/>
						</dd>
					</dl>
				</div>
			</Card.Body>
		</Card>
	);
}
