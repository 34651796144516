import Case from 'case';
import {
	BarController,
	BarElement,
	CategoryScale,
	Chart,
	ChartConfiguration,
	Filler,
	Legend,
	LineController,
	LineElement,
	LinearScale,
	PointElement,
	Tooltip,
} from 'chart.js';
import Annotation, { AnnotationPluginOptions } from 'chartjs-plugin-annotation';
import { useEffect, useRef } from 'react';
import { Dropdown } from 'react-bootstrap';

import LoadingSpinner from '../LoadingSpinner';

import { colors, gradients } from './defaults';

const colorKeys = Object.keys(colors) as (keyof typeof colors)[];
// Register everything with Chart.js outside of the component
Chart.register(
	Annotation,
	LineController,
	CategoryScale,
	BarController,
	BarElement,
	PointElement,
	LinearScale,
	LineElement,
	Tooltip,
	Legend,
	Filler,
);

/**
 * Calculate the maximum value for a bar chart, excluding any outliers, and rounding up to the
 * nearest 10, 100, 1000, or 5000 depending on the maximum value.
 *
 * @param data A list of numbers to calculate the max value for.
 * @returns The maximum value for the data, excluding any outliers, rounded
 */
function calculateStandardMax(data: number[]): number {
	const sortedData = [...data].sort((a, b) => a - b);
	// Remove any outliers
	const avg = sortedData.reduce((acc, value) => acc + value, 0) / sortedData.length;
	const stdDev = Math.sqrt(sortedData.reduce((acc, value) => acc + (value - avg) ** 2, 0) / sortedData.length);
	const filteredData = sortedData.filter((value) => Math.abs(value - avg) <= 2 * stdDev);
	const maxVal = Math.max(...filteredData);
	let roundingFactor = 5000; // Default rounding to nearest 5k
	if (maxVal < 100) {
		roundingFactor = 10; // Round to nearest 10s if under 100
	} else if (maxVal < 1000) {
		roundingFactor = 100; // Round to nearest 100s if under 1k
	} else if (maxVal < 10000) {
		roundingFactor = 1000; // Round to nearest 1k if under 10k
	}
	const roundedMax = Math.ceil((maxVal + (maxVal * 0.10)) / roundingFactor) * roundingFactor;
	return roundedMax;
}

export interface BarChartLabel {
	name: string;
	backgroundColor?: string;
}

export interface BarChartProps {
	loading?: boolean;
	hideLegend?: boolean;
	className?: string;
	datasets: {
		type?: 'bar' | 'line';
		label: string;
		data: number[];
		xAxisID?: string;
		yAxisID?: string;
		borderWidth?: number;
		color?: keyof typeof colors;
		gradient?: keyof ReturnType<typeof gradients>;
		fill?: boolean | string;
		backgroundColor?: string;
		borderColor?: string;
		borderDash?: [number, number];
		borderJoinStyle?: 'miter' | 'round' | 'bevel';
		order?: number;
		pointRadius?: number;
		spanGaps?: boolean;
	}[];
	labels: BarChartLabel[];
	hideX?: boolean;
	grid?: boolean;
	min?: number;
	max?: number;
	height?: string;
	setPeriod?: (period: 'day' | 'week' | 'month') => void;
	period?: 'day' | 'week' | 'month';
}

export default function BarChart({
	loading,
	className,
	datasets,
	labels,
	hideX,
	height,
	grid,
	min,
	max,
	hideLegend,
	setPeriod,
	period,
}: BarChartProps) {
	const chartRef = useRef<HTMLCanvasElement>(null);
	const annotations = labels.map((label, index) => (label.backgroundColor ? {
		type: 'box',
		xMin: index - 0.5,
		xMax: index + 0.5,
		borderWidth: 0,
		borderColor: 'transparent',
		z: 0,
		backgroundColor: label.backgroundColor,
	} : null)).filter((v) => v != null) as AnnotationPluginOptions['annotations'];
	const options: ChartConfiguration<'bar'>['options'] = {
		plugins: {
			legend: {
				display: !hideLegend,
			},
			tooltip: {
				// Show all values for the same date
				mode: 'index',
				intersect: false,
			},
			annotation: {
				annotations,
			},
		},
		scales: {
			yAxes: {
				display: true,
				min: min || 0,
				grid: {
					display: grid,
					color: '#eee',
				},
				position: 'left',
			},
		},
		maintainAspectRatio: false,
		responsive: true,
	};

	if (datasets.some((dataset) => dataset.type === 'line' && !dataset.yAxisID)) {
		options.scales!.yAxesRight = {
			min: 0,
			display: true,
			position: 'right',
		};
	}
	useEffect(() => {
		// NOTE: We need this "any" override because the typescript definitions for Chart.js do not
		// allow mixing bar and line charts together
		const chartData: any = {
			labels: labels.map((l) => l.name),
			datasets: datasets.map((dataset, index) => {
				const datasetColor = dataset.color || colorKeys[index % colorKeys.length];
				const backgroundColor = dataset.backgroundColor || (
					dataset.gradient
						? gradients(chartRef)[dataset.gradient]
						: colors[datasetColor]
				);

				return {
					type: dataset.type || 'bar',
					label: dataset.label,
					backgroundColor,
					hoverBackgroundColor: backgroundColor,
					borderColor: dataset.type === 'line' ? colors[datasetColor] : undefined,
					borderWidth: dataset.borderWidth,
					borderDash: dataset.borderDash,
					borderJoinStyle: dataset.borderJoinStyle || 'miter',
					pointRadius: dataset.pointRadius,
					fill: dataset.fill,
					xAxisID: dataset.xAxisID,
					yAxisID: dataset.yAxisID || (dataset.type === 'line' ? 'yAxesRight' : 'yAxes'),
					data: dataset.data,
					order: dataset.order || (dataset.type === 'line' ? 1 : 2),
					spanGaps: dataset.spanGaps,
				};
			}),
		};
		Chart.defaults.font.family = 'Poppins, system-ui, -apple-system, Roboto, Arial, system-ui, -apple-system, sans-serif';
		Chart.defaults.plugins.tooltip.padding = 10;
		Chart.defaults.plugins.tooltip.backgroundColor = 'rgba(0, 0, 0, 0.7)';
		Chart.defaults.scale.ticks.color = 'rgba(0, 0, 0, 0.4)';
		if (chartRef.current && chartData.datasets.length > 0) {
			const chart = new Chart(chartRef.current!, {
				type: 'bar',
				options,
				data: chartData,
			});
			return () => {
				chart.destroy();
			};
		}
	}, [datasets, labels, options]);
	return <div style={{
		height: height || '300px',
		maxHeight: height || '300px',
		paddingBottom: '35px',
	}}>
		<div className="float-end toolbar">
			{setPeriod && <Dropdown className="me-2 float-end">
				<Dropdown.Toggle variant="outline-dark" size="sm" id="periodDropdown">
					{Case.title(period || 'day')}
				</Dropdown.Toggle>

				<Dropdown.Menu>
					<Dropdown.Item onClick={() => setPeriod('day')}>Day</Dropdown.Item>
					<Dropdown.Item onClick={() => setPeriod('week')}>Week</Dropdown.Item>
					<Dropdown.Item onClick={() => setPeriod('month')}>Month</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
			}
		</div>
		<LoadingSpinner loading={!!loading} title="Loading Chart" />
		{!loading && <canvas className={className} ref={chartRef} />}
	</div>;
}
