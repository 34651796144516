import { createAmazonBedrock } from '@newstex/ai/bedrock';
import { AIChatContext, ChatMessage, StreamTextResult } from '@newstex/ai/chat';
import {
	QualificationScores,
	QualifyPublicationParams,
	QualifyStoryParams,
	qualifyPublication,
	qualifySite,
	qualifyStory,
} from '@newstex/ai/qualify';
import { rerank } from '@newstex/ai/rerank';
import { sleep } from '@newstex/core/sleep';
import { DetectFeedResponse } from '@newstex/types/detect-feed';
import { KnowledgeBase, RAGMemory, isKnowledgeBase } from '@newstex/types/rag';
import { tool } from 'ai';
import { ChartConfiguration } from 'chart.js';
import cloneDeep from 'clone-deep';
import omit from 'object.omit';
import {
	createContext,
	useContext,
	useEffect,
	useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { z } from 'zod';

import { useAnalytics } from './analytics-provider';
import { useAPI } from './api-provider';
import { useCache } from './cache-provider';
import { useSearch } from './search';
import { useUserInfo } from './user-info';

export interface AIContextType {
	qualifyStory: (options: Omit<QualifyStoryParams, 'modelProvider'>) => Promise<QualificationScores>;
	qualifySite: (params: {
		detectResp: DetectFeedResponse;
		criteria: string;
	}) => Promise<QualificationScores>;
	qualifyPublication: (options: Omit<QualifyPublicationParams, 'modelProvider'>) => Promise<QualificationScores>;
	chat: (
		options: {
			messages: ChatMessage[],
			onToolCall?: (cmdName: string, cmdParams: Record<string, any>) => void,
			onStepFinish?: (step: any) => void,
			prompt?: string | null,
		}
	) => Promise<StreamTextResult | undefined>;
	refreshCredentials: () => Promise<ReturnType<typeof createAmazonBedrock> | null>;
}

const AIContext = createContext<AIContextType | undefined>(undefined);

export function AIProvider({ children }: React.PropsWithChildren<{}>) {
	const userInfo = useUserInfo();
	const [modelProvider, setModelProvider] = useState<ReturnType<typeof createAmazonBedrock> | null>(null);
	const [aiChatCtx, setAIChatCtx] = useState<AIChatContext | null>(null);
	const searchClient = useSearch();
	const api = useAPI();
	const navigate = useNavigate();
	const cache = useCache();
	const analytics = useAnalytics();

	useEffect(() => {
		if (userInfo?.aws && searchClient?.searchClient) {
			console.log('Setting model provider', userInfo.aws);
			const newModelProvider = createAmazonBedrock({
				region: 'us-east-1',
				accessKeyId: userInfo.aws.AccessKeyId,
				secretAccessKey: userInfo.aws.SecretAccessKey,
				sessionToken: userInfo.aws.SessionToken,
			});
			setModelProvider(() => newModelProvider);
			setAIChatCtx(() => new AIChatContext({
				searchClient: searchClient.searchClient,
				saveResource: async (resource) => {
					if (!resource.$type) {
						throw new Error('Resource type is required');
					}

					if (isKnowledgeBase(resource)) {
						if (!resource.$id && (!resource.title || !resource.answer)) {
							throw new Error('Invalid resource, title and answer are required');
						}
					}

					if (resource.$id) {
						await api.updateItem({
							$type: resource.$type,
							$id: resource.$id,
						}, omit(resource, ['$id', '$type', 'created_at', 'created_by']) as Partial<KnowledgeBase | RAGMemory>);
					} else {
						await api.createItem({
							$type: resource.$type,
							...omit(resource, ['$id', '$type', 'created_at', 'created_by']),
						});
					}
				},
				modelProvider: newModelProvider,
				logger: console,
				user: userInfo,
				extraTools: {
					navigateTo: tool({
						description: 'Navigate to a specific path in NewsCore.',
						parameters: z.object({
							path: z.string()
								.describe('the path to navigate to'),
						}),
						execute: async ({ path }: { path: string }) => {
							navigate(path);
							return 'OK';
						},
					}),
					generateChart: tool({
						description: 'Generate a chart using Chart.js configuration',
						parameters: z.object({
							type: z.enum(['line', 'bar', 'pie', 'doughnut', 'radar', 'polarArea', 'scatter', 'bubble'])
								.describe('The type of chart to generate'),
							data: z.object({
								labels: z.array(z.string()).describe('Labels for the data points'),
								datasets: z.array(z.object({
									label: z.string().describe('Label for the dataset'),
									data: z.array(z.number()).describe('Data points for the dataset'),
									backgroundColor: z.string().optional().describe('Background color for the dataset'),
									borderColor: z.string().optional().describe('Border color for the dataset'),
									fill: z.boolean().optional().describe('Whether to fill the area under the line'),
								})),
							}).describe('The data to display in the chart'),
							options: z.object({
								responsive: z.boolean().optional(),
								maintainAspectRatio: z.boolean().optional(),
								plugins: z.object({
									title: z.object({
										display: z.boolean().optional(),
										text: z.string().optional(),
									}).optional(),
									legend: z.object({
										display: z.boolean().optional(),
										position: z.enum(['top', 'bottom', 'left', 'right']).optional(),
									}).optional(),
								}).optional().describe('Chart.js options configuration'),
							}).optional().describe('Chart.js options configuration'),
						}),
						execute: async (config: ChartConfiguration) => {
							return config;
						},
					}),
				},
			}));
		}
	}, [userInfo?.aws, searchClient]);

	const getModelProvider = async () => {
		if (modelProvider) {
			return modelProvider;
		}

		if (userInfo?.aws) {
			const newModelProvider = createAmazonBedrock({
				region: 'us-east-1',
				accessKeyId: userInfo.aws.AccessKeyId,
				secretAccessKey: userInfo.aws.SecretAccessKey,
				sessionToken: userInfo.aws.SessionToken,
			});
			setModelProvider(() => newModelProvider);
			return newModelProvider;
		}

		console.error('No AWS credentials found', userInfo);
		throw new Error('No AWS credentials found');
	};

	const refreshCredentials = async () => {
		if (!userInfo?.refresh) {
			throw new Error('No refresh function available');
		}

		toast.warning('Credentials expired, refreshing...');
		const updateInfo = await userInfo.refresh();

		if (!updateInfo?.aws) {
			throw new Error('No AWS credentials found');
		}

		const newProvider = createAmazonBedrock({
			region: 'us-east-1',
			accessKeyId: updateInfo.aws.AccessKeyId,
			secretAccessKey: updateInfo.aws.SecretAccessKey,
			sessionToken: updateInfo.aws.SessionToken,
		});

		setModelProvider(() => newProvider);
		if (aiChatCtx) {
			aiChatCtx.modelProvider = newProvider;
		}
		await sleep(1000);
		return newProvider;
	};

	const handlerWithRetry = async <T, R>(
		fn: (options: T) => Promise<R>,
		options: T,
	): Promise<R> => {
		const provider = await getModelProvider();
		if (!provider) {
			throw new Error('Model provider not initialized');
		}

		return fn({
			modelProvider: provider,
			...options,
		}).catch(async (err) => {
			console.error('AWS Bedrock Error', err);
			if (String(err).toLowerCase().includes('expired') && userInfo?.refresh) {
				setModelProvider(null);
				const newProvider = await refreshCredentials();
				return fn({
					modelProvider: newProvider,
					...options,
				});
			}
			throw err;
		});
	};

	const wrappedQualifyStory = async (options: Omit<QualifyStoryParams, 'modelProvider'>) => {
		return handlerWithRetry(qualifyStory, options);
	};

	const wrappedQualifySite = async ({
		detectResp,
		criteria,
	}: {
		detectResp: DetectFeedResponse;
		criteria: string;
	}) => {
		const cacheKey = `qualify:${detectResp.url}:${criteria}`;

		const cachedResult = cache?.get<QualificationScores>(cacheKey);
		if (cachedResult) {
			return cachedResult;
		}

		analytics?.trackEvent('Qualify Site', {
			url: detectResp.url,
			criteria,
		});
		console.log('Qualifying site', detectResp.url, criteria);
		const resp = await handlerWithRetry(qualifySite, {
			detectResp,
			criteria,
		});
		cache?.set(cacheKey, resp);
		return resp;
	};

	const wrappedQualifyPublication = async (options: Omit<QualifyPublicationParams, 'modelProvider'>) => {
		return handlerWithRetry(qualifyPublication, options);
	};

	const sanitizeMessages = (msgs: ChatMessage[]) => {
		for (const msg of msgs) {
			console.log('Checking message', msg);
			if (msg.content && Array.isArray(msg.content)) {
				for (const content of msg.content) {
					console.log('Checking', content);
					if ('toolCallId' in content && !content.toolCallId) {
						content.toolCallId = `placeholder_${Date.now()}_${Math.random().toString(36).slice(2)}`;
						console.log('FIXED', content.toolCallId);
					}
				}
			}
		}
		return msgs;
	};

	const wrappedChat = async ({
		messages,
		onToolCall,
		onStepFinish,
		prompt,
	}: {
		messages: ChatMessage[],
		onToolCall?: (cmdName: string, cmdParams: Record<string, any>) => void,
		onStepFinish?: (step: any) => void,
		prompt?: string | null,
	}) => {
		if (!aiChatCtx) {
			return;
		}
		aiChatCtx.toolContext.onToolCall = onToolCall;
		console.debug('*** AI CHAT Messages ***', messages);

		// On the first message, search the knowledge base
		if (messages.length < 2) {
			const question = messages[0].content as string;
			console.log('FIRST MESSAGE', question);
			const searchResults = await aiChatCtx.toolContext.searchClient.search<KnowledgeBase>({
				indexName: 'RAG',
				query: question,
				exclude_fields: 'embedding',
				query_by: ['question', 'questions', 'title', 'answer', 'embedding'],
			});
			if (searchResults.hits.length > 0) {
				// Re-rank search results based on relevance to the user's question
				const rerankedResults = rerank(question, searchResults.hits);

				// Add the most relevant results to the context
				if (rerankedResults.length > 0) {
					messages.unshift({
						role: 'system',
						content: `Relevant knowledge base articles:\n\n${rerankedResults.slice(0, 3).map((result: KnowledgeBase) => `Title: ${result.title}\n${result.answer}\n---`).join('\n')}`,
					});
					console.log('Updated Messages', messages);
				}
				console.log('Ranked Results', rerankedResults);
			}
		}

		try {
			return aiChatCtx.streamChat({
				messages: cloneDeep(messages),
				onStepFinish,
				prompt,
			});
		} catch (err) {
			console.error('AWS Bedrock Error', err);
			if (String(err).toLowerCase().includes('expired') && userInfo?.refresh) {
				await refreshCredentials();
				return aiChatCtx.streamChat({
					messages,
					onStepFinish,
					prompt,
				});
			}

			if (String(err).includes('validation errors detected')) {
				console.warn('Bedrock validation error, retrying with sanitized messages');
				return aiChatCtx.streamChat({
					messages: sanitizeMessages(messages),
					onStepFinish,
					prompt,
				});
			}
			console.error('Chat Error', String(err));
			throw err;
		}
	};

	const value: AIContextType = {
		qualifyStory: wrappedQualifyStory,
		qualifySite: wrappedQualifySite,
		qualifyPublication: wrappedQualifyPublication,
		chat: wrappedChat,
		refreshCredentials,
	};

	return <AIContext.Provider value={value}>{children}</AIContext.Provider>;
}

export function useAI() {
	const context = useContext(AIContext);
	if (context === undefined) {
		throw new Error('useAI must be used within an AIProvider');
	}
	return context;
}
