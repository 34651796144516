import type { SearchIndexName } from '@newstex/search';
import type { SearchParams } from 'typesense/lib/Typesense/Documents';

import { type BaseObject, isBaseObjectType } from './base';
import { UnixTimestamp } from './dates';

export enum AlertStatus {
	OK = 1,
	WARNING = 2,
	CRITICAL = 3,
	NO_DATA = 4,
	DISABLED = -1,
}

/**
 * Custom Alerts
 */
export interface Alert extends BaseObject {
	$type: 'Alert';

	/**
	 * Alert Status
	 */
	status?: AlertStatus;

	/**
	 * Collection to run the alert on
	 */
	collection: SearchIndexName;

	/**
	 * Search parameters
	 */
	search: SearchParams;

	/**
	 * Cron Schedule to run the alert
	 */
	schedule: string;

	/**
	 * Expected range of values
	 */
	expected: {
		/** Minimum number of results to expect */
		min?: number;
		/** Maximum number of results to expect */
		max?: number;
		/**
		 * Difference from average of `last_values`
		 */
		diff?: number;
	};

	/**
	 * Field to check in the response (default `found`, but you can also use a facet count)
	 */
	check_field?: string;

	/**
	 * Last run timestamp
	 */
	last_run?: UnixTimestamp;

	/**
	 * Last 10 values when this was run
	 */
	last_values?: {
		/** Value */
		value: number;
		/** Timestamp */
		timestamp: UnixTimestamp;
	}[];
}

export function isAlert(obj: any): obj is Alert {
	return isBaseObjectType(obj) && obj.$type === 'Alert';
}
