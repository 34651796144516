/**
 * Edit Delivery Modal
 */
import { arrayEqual } from '@newstex/core/array-utils';
import { Delivery } from '@newstex/types/delivery';
import { useRef, useState } from 'react';
import {
	Alert,
	Button,
	Col,
	Container,
	Form,
	Modal,
	Row,
} from 'react-bootstrap';
import { useAPI } from '~/providers/api-provider';

import { ProductEditor } from '../products';
import EditableSelectField from '../select';
import EditableTextField from '../text';

export interface EditDeliveryModalProps {
	item?: Delivery,
	show: boolean,
	onClose: (saved: boolean) => void,
}

export default function EditDeliveryModal({
	item,
	show,
	onClose,
}: EditDeliveryModalProps) {
	const api = useAPI();
	const commentRef = useRef<HTMLInputElement>(null);
	const [saving, setSaving] = useState(false);
	const [error, setError] = useState<any>();
	const [updates, setUpdates] = useState<Partial<Delivery>>({});

	const doUpdate = async () => {
		if (item) {
			setError(null);

			if (!commentRef.current?.value) {
				commentRef.current?.focus();
				commentRef.current?.select();
				commentRef.current?.setCustomValidity('Please enter a comment');
				return;
			}
			setSaving(true);
			console.log('Saving', item, commentRef.current?.value, updates);
			try {
				await api.updateItem<Delivery>(item, {
					$comment: commentRef.current?.value,
					...updates,
				});
				onClose(true);
			} catch (err) {
				console.error(err);
				setError(err);
			}
			setTimeout(() => {
				setSaving(false);
				onClose(true);
			}, 2000);
		}
	};

	return <Modal show={item && show} onHide={() => onClose(false)} size="xl" fullscreen="xl-down">
		<Modal.Header closeButton>
			<Modal.Title>Edit Delivery <i>{item?.name}</i></Modal.Title>
			{ error && <Alert variant="danger">{error.message || String(error)}</Alert> }
		</Modal.Header>
		<Form onSubmit={(e) => {
			e.preventDefault();
			doUpdate();
		}}>
			<Modal.Body>
				<Container fluid className={saving ? 'opacity-50' : ''}>
					<Row className="mb-3">
						<EditableTextField
							typeName="Delivery"
							propKey="name"
							item={item}
							updates={updates}
							setUpdates={setUpdates}
						/>
					</Row>
					<Row className="mb-3">
						<EditableSelectField
							typeName="Delivery"
							propKey="type"
							item={item}
							updates={updates}
							setUpdates={setUpdates} options={[
								'S3',
								'SFTP',
								'HTTP',
							]}
						/>
					</Row>
					<Row className="mb-3">
						<EditableTextField
							typeName="Delivery"
							propKey="tech_contact"
							item={item}
							updates={updates}
							setUpdates={setUpdates}
						/>
					</Row>
					<Row className="mb-3">
						<EditableTextField
							typeName="Delivery"
							propKey="recipient"
							item={item}
							updates={updates}
							setUpdates={setUpdates}
						/>
					</Row>
					<Row className="mb-3">
						<ProductEditor products={item?.products} onChange={(products) => {
							const productIds = products.map((p) => p.$id);
							if (!arrayEqual(productIds, item?.products || [], true)) {
								setUpdates({
									...updates,
									products: productIds,
								});
							}
						}} />
					</Row>
					<Row className="mt-3 pt-3" style={{ borderTop: 'dashed 1px #ccc' }}>
						<Col md={3} className="text-end fw-bold">
							<Form.Label>Comment</Form.Label>
						</Col>
						<Col md={9}>
							<Form.Control type="text" name="$comment" ref={commentRef} required placeholder="Required: Enter a comment" />
						</Col>
					</Row>
				</Container>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={() => onClose(false)} disabled={saving}>
				Cancel
				</Button>
				<Button variant="primary" onClick={() => {
					doUpdate();
				}} disabled={saving || !updates || Object.keys(updates).length === 0}>
				Submit
				</Button>
			</Modal.Footer>
		</Form>
	</Modal>;
}
