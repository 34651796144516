import { now } from '@newstex/core/date';
import { NormalizedSearchResponse } from '@newstex/search/types';
import { Story } from '@newstex/types/story';
import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';

import RecentlyIntegratedContent, { IntegratedContent } from '../../components/recently-integrated-content';
import MetricStats from '../../components/Widgets/MetricStats';
import SearchStats from '../../components/Widgets/SearchStats';
import { useSearch } from '../../providers/search';

export function AdminDashboard() {
	const searchContext = useSearch();
	const [thisWeekStats, setThisWeekStats] = useState<NormalizedSearchResponse<Story> | null>(null);
	const [lastWeekStats, setLastWeekStats] = useState<NormalizedSearchResponse<Story> | null>(null);
	const [recentlyIntegrated, setRecentlyIntegrated] = useState<IntegratedContent>(null);
	const [storyCounts, setStoryCounts] = useState<Record<string, number> | undefined>();

	useEffect(() => {
		const fetchData = async () => {
			if (searchContext.searchClient) {
				const sharedStorySearchParams = {
					// No hits, just facets
					per_page: 0,
					facet_by: [
						'_tags',
						'categories',
						'stats.word_count',
					],
				};
				const resp = await searchContext.searchClient.multiSearch<any>([
					{
						...sharedStorySearchParams,
						indexName: 'Story',
						filter_by: `received_at:>${now().add(-1, 'week').unix()}`,
					},
					{
						...sharedStorySearchParams,
						indexName: 'Story',
						filter_by: `received_at:=[${now().add(-2, 'week').unix()}..${now().add(-1, 'week').unix()}]`,
					},
					// Recently Integrated Content
					{
						indexName: 'Publication',
						per_page: 100,
						facet_by: [
							'content_type',
							'language',
						],
						filter_by: `integration_completion_date:>${now().add(-1, 'month').unix()}`,
						sort_by: 'integration_completion_date:desc',
						include_fields: '$Publisher(id,name,newstex_id)',
					},
				]);
				const [
					thisWeek,
					lastWeek,
					integrated,
				] = resp;
				setThisWeekStats(thisWeek);
				setLastWeekStats(lastWeek);
				setRecentlyIntegrated(integrated);
				if (integrated?.hits) {
					console.log('Integrated', integrated.hits);
					const contentIds = integrated.hits
						.map((content) => content.newstex_id)
						.filter((id: string) => !!id) as string[];
					const fetchStoryCounts = async () => {
						if (searchContext.searchClient) {
							const recentlyIntegratedStorySearchResp = await searchContext.searchClient.searchStories({
								per_page: 0,
								facet_by: 'content',
								filter_by: `(content:${contentIds.join(' || content:')}) && received_at:>${now().add(-30, 'day').unix()}`,
							});

							setStoryCounts(recentlyIntegratedStorySearchResp
								.facet_counts
								?.find((facet) => facet.field_name === 'content')
								?.counts
								?.reduce((acc: Record<string, number>, count) => {
									acc[count.value] = count.count;
									return acc;
								}, {})
								|| {});
						}
					};
					fetchStoryCounts();
				}
			}
		};
		fetchData();
	}, [searchContext.searchClient]);

	return (
		<Container fluid className="px-lg-4 px-xl-5">
			<div className="page-header d-flex justify-content-between align-items-center">
				<h1 className="page-heading">Activity Dashboard</h1>
			</div>
			<section className="mb-3 mb-lg-5">
				<MetricStats />
				<SearchStats
					thisWeek={thisWeekStats}
					lastWeek={lastWeekStats}
				/>
				{/* Recently Integrated */}
				<RecentlyIntegratedContent
					title="Recently Integrated"
					searchResponse={recentlyIntegrated}
					storyCounts={storyCounts}
				/>
			</section>
		</Container>
	);
}
