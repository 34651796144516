import type { AccountInfoResponse } from '@newstex/types/responses/info';
/**
 * User Info (/info) Provider
 */
import {
	createContext,
	useContext,
	useEffect,
	useState,
} from 'react';
import { Modal } from 'react-bootstrap';

import { useAPI } from './api-provider';
import { useAuth } from './auth';

export interface UserInfo extends AccountInfoResponse {
	refresh: () => Promise<UserInfo | null>;
	initials?: string;
}
const UserInfoContext = createContext<UserInfo | null>(null);

export function UserInfoProvider(props: React.PropsWithChildren<{}>) {
	const [data, setData] = useState<AccountInfoResponse | null>(null);
	const [error, setError] = useState<Error | null>(null);
	const { fetchWithAuth } = useAPI();
	const { user, runAs } = useAuth();

	const refresh = async () => {
		return fetchWithAuth<AccountInfoResponse>('info', {
			cache: 'reload',
		}).then((response) => {
			setError(null);
			setData(response);
			localStorage.setItem('userInfo', JSON.stringify(response));
			return {
				...response,
				refresh,
			};
		}).catch((e) => {
			setError(e);
			return null;
		});
	};
	useEffect(() => {
		refresh();
	}, [user, runAs]);

	return (
		<UserInfoContext.Provider value={data ? {
			...data,
			initials: data.name?.split(' ').map((n) => n[0]).join('').toUpperCase(),
			refresh,
		} : null}>{
				error?.message ? <Modal
					size="lg"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					show={true}
				>
					<Modal.Header>
						<Modal.Title id="contained-modal-title-vcenter">
						Fatal Error
						</Modal.Title>
					</Modal.Header>
					<Modal.Body className="d-flex justify-content-center pt-5 pb-5">
						{window.location.hostname === 'admin-dev.newstex.com' && (
							<div className="alert alert-warning mb-3">
								You are currently on the development environment. Consider switching to <a href="https://admin.newstex.com">production</a>.
							</div>
						)}
						{error.message}<br /><br />
					Try refreshing the page. If the problem persists, contact support.
					</Modal.Body>
					<Modal.Footer>
						<button className="btn btn-danger" onClick={() => window.location.reload()}>Refresh</button>
					</Modal.Footer>
				</Modal> : props.children
			}</UserInfoContext.Provider>
	);
}

export function useUserInfo() {
	const result = useContext(UserInfoContext);
	if (result === undefined) {
		throw new Error('useUserInfo must be used within a UserInfoProvider');
	}
	return result;
}
