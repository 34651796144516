/**
 * Multi-Value Edit Field
 */
import { TypeNameToTypeMap } from '@newstex/types/infer-types';
import React, { useEffect, useState } from 'react';
import { Col, Form } from 'react-bootstrap';

import PropertyDisplayName from '../property-display-name';

import { EditTextFieldProps } from './text';

export interface EditMultiValueFieldProps<T extends keyof TypeNameToTypeMap> extends EditTextFieldProps<T> {
	trimValues?: boolean,
}

export default function EditableMultiValueField<T extends keyof TypeNameToTypeMap>({
	typeName,
	propKey,
	item,
	updates,
	setUpdates,
	trimValues,
}: EditMultiValueFieldProps<T>) {
	const [values, setValues] = useState<string[]>([]);

	useEffect(() => {
		const initialValues = updates[propKey] as string[] || item?.[propKey] as string[] || [];
		setValues([
			...initialValues,
			// Always end with an empty value
			'',
		]);
	}, [updates, item]);

	const handleChange = (index: number, value: string) => {
		const newValues = [...values];
		newValues[index] = value;

		if (trimValues) {
			value = value.trim();
		}

		if (value === '') {
			newValues.splice(index, 1);
		} else if (index === values.length - 1) {
			newValues.push('');
		}
		setValues([
			...newValues,
			// Always end with an empty value
			'',
		]);
		if (setUpdates) {
			setUpdates({
				...updates,
				[propKey]: newValues.filter((v) => v !== ''),
			});
		}
	};

	return (
		<>
			<Col md={3} className="text-end fw-bold">
				<Form.Label><PropertyDisplayName propKey={propKey} typeName={typeName} /></Form.Label>
			</Col>
			<Col md={9}>
				{values.map((value, index) => (
					<Form.Control
						key={index}
						type="text"
						value={value}
						onChange={(e) => handleChange(index, e.target.value)}
						className="mb-2"
					/>
				))}
			</Col>
		</>
	);
}
