import { faHubspot } from '@fortawesome/free-brands-svg-icons';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HubSpotCompany } from '@newstex/types/hubspot';
import { KnowledgeBase } from '@newstex/types/rag';
import { useEffect, useRef, useState } from 'react';
import {
	Alert,
	Col,
	Container,
	Row,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { MermaidGraph } from '~/components/mermaid-graph';
import PageHeader from '~/components/PageHeader';
import { LeadData, LeadQualificationTable } from '~/components/qualify/lead-qualification-table';
import { LeadSubmissionForm } from '~/components/qualify/lead-submission-form';
import { useAI } from '~/providers/ai-provider';
import { useAnalytics } from '~/providers/analytics-provider';
import { AbortError, useAPI } from '~/providers/api-provider';

function cleanURL(url: string) {
	let trimmedUrl = url?.trim();
	if (!trimmedUrl) {
		return;
	}

	if (!trimmedUrl.match(/^https?:\/\//i)) {
		trimmedUrl = `https://${trimmedUrl}`;
	}
	return trimmedUrl;
}

interface LeadItem {
	url: string;
	hubspotID?: string;
	hubspotRecord?: Partial<HubSpotCompany>;
}

const DEFAULT_FLOW_DIAGRAM = `graph TD
    A[URL Input] --> C[Technical<br>Qualification]
    C --> D[HubSpot]`;

const AI_FLOW_DIAGRAM = `graph TD
    A[URL Input] --> B{Technical<br>Qualification}
    B -->|Yes| C[AI Review - 10¢]
    C --> D[HubSpot]
    B -->|No| D`;

function FlowDiagramSelector({ knowledgeBaseArticle }: { knowledgeBaseArticle: KnowledgeBase | null }) {
	return (
		<MermaidGraph
			className="text-center"
			chart={knowledgeBaseArticle ? AI_FLOW_DIAGRAM : DEFAULT_FLOW_DIAGRAM}
		/>
	);
}

export function QualifyLeadsPage() {
	const [knowledgeBaseArticle, setKnowledgeBaseArticle] = useState<KnowledgeBase | null>(null);
	const [items, setItems] = useState<LeadItem[]>([]);
	const [running, setRunning] = useState(false);
	const [syncing, setSyncing] = useState(false);
	const [synced, setSynced] = useState(false);
	const [tableData, setTableData] = useState<LeadData[]>([]);
	const abortController = useRef<AbortController>(new AbortController());
	const api = useAPI();
	const { qualifySite } = useAI();
	const analytics = useAnalytics();

	const runQualification = async (submittedUrls?: string[], article?: KnowledgeBase | null) => {
		const id = crypto.randomUUID();
		analytics?.trackEvent('Qualify Leads', {
			name: article?.title || 'None',
			id,
		}, submittedUrls?.length || 1);
		abortController.current = new AbortController();
		setRunning(true);
		setSyncing(false);
		setSynced(false);
		setKnowledgeBaseArticle(article || null);
		let validItems: LeadItem[] = [];
		if (submittedUrls) {
			for (const url of submittedUrls) {
				const trimmedUrl = cleanURL(url);
				// Ignore blank and comment lines
				if (!trimmedUrl || trimmedUrl.startsWith('#')) {
					continue;
				}

				const hubspotMatch = trimmedUrl.match(/^https?:\/\/app\.hubspot\.com\/contacts\/(\d+)\/(objectLists|lists)\/(\d+)\/filters/);
				if (hubspotMatch) {
					console.log('HubSpot List ID:', hubspotMatch[3]);
					const toastId = toast.loading('Fetching HubSpot records...');
					const hubspotRecords = await api.getHubSpotRecords({
						id: hubspotMatch[3],
						type: 'list',
					}).catch(() => {
						toast.update(toastId, {
							render: 'Error fetching HubSpot records',
							closeButton: true,
							type: 'error',
							isLoading: false,
							autoClose: 5000,
						});
						return null;
					});

					if (hubspotRecords) {
						toast.update(toastId, {
							render: `Loaded ${hubspotRecords.items.length} HubSpot records`,
							closeButton: true,
							type: 'success',
							isLoading: false,
							autoClose: 5000,
						});
						if (hubspotRecords?.items?.length) {
							for (const hubspotItem of hubspotRecords.items) {
								if (hubspotItem.website) {
									const cleanedUrl = cleanURL(hubspotItem.website);
									if (cleanedUrl) {
										validItems.push({
											url: cleanedUrl,
											hubspotID: String(hubspotItem.hs_object_id),
											hubspotRecord: hubspotItem,
										});
									}
								}
							}
						}
					}
				} else if (trimmedUrl.includes(';')) {
					// Allow a semicolon to specify a URL and a HubSpot ID
					const [validUrl, hubspotID] = trimmedUrl.split(';');
					validItems.push({
						url: validUrl.trim(),
						hubspotID: hubspotID.trim(),
					});
				} else {
					validItems.push({ url: trimmedUrl });
				}
			}
			setTableData(validItems.map((item) => ({
				url: item.url,
				hubspotID: item.hubspotID,
				hubspot_records: item.hubspotRecord ? [{
					...item.hubspotRecord,
					id: item.hubspotID || String(item.hubspotRecord.hs_object_id || ''),
				}] : [],
				running: false,
			})));
			setItems(validItems);
		} else {
			validItems = items;
		}

		for (const item of validItems) {
			if (abortController.current.signal.aborted) {
				console.log('Cancelled');
				break;
			}
			const currentItem = tableData.find((i) => i.url === item.url);
			if (currentItem?.qualified != null) {
				console.log('Skipping', item, currentItem);
				continue;
			}

			try {
				setTableData((prev) => (
					prev.map((i) => ({
						...i,
						running: Boolean(i.url === item.url),
					}))
				));
				const detectResp = await api.detect({
					url: item.url,
					hubspot_id: item.hubspotID,
					classify: true,
				}, {
					signal: abortController.current.signal,
				});

				const result: LeadData = {
					url: item.url,
					qualified: false,
					...detectResp,
				};
				// Submitted hubspot ID takes priority
				if (item.hubspotID) {
					result.hubspot_id = item.hubspotID;
					if (item.hubspotRecord) {
						result.hubspot_records = [{
							...item.hubspotRecord,
							id: item.hubspotID,
						}];
					}
				}

				if (!result.qualified && !result.feed_url && !result.rejection_reasons?.length) {
					result.rejection_reasons = ['No RSS feed found'];
				}
				console.log('Checking', {
					qualified: result.qualified,
					knowledgeBaseArticle: article?.title,
				});

				// If it's qualified and we have qualification criteria, add the article score to the result
				if (result.qualified && article) {
					console.log('Qualifying', item, article.title);

					// Run the qualification criteria against the article
					const resp = await qualifySite({
						detectResp: result,
						criteria: article.answer!,
					});
					console.log('AI Qualification', item, resp);
					result.ai_rankings = resp;
					analytics?.trackEvent('Qualified Lead', {
						id,
						criteria: article.title,
						url: item.url,
						qualified: result.qualified,
						score: Math.max(...Object.values(resp).map((r) => r.score)),
					});
				} else if (article) {
					analytics?.trackEvent('Unqualified Lead', {
						id,
						criteria: article.title,
						url: item.url,
						score: 0,
						qualified: result.qualified,
					});
				}

				console.log(item, result);
				setTableData((prev) => (
					prev.map((i) => (i.url === item.url ? result : i))
				));
			} catch (e: any) {
				if (e instanceof AbortError) {
					console.log('Cancelled', item);
					setTableData((prev) => (
						prev.map((i) => (i.url === item.url ? { ...i, running: false } : i))
					));
					continue;
				}
				const errorResult: LeadData = {
					url: item.url,
					hubspot_id: item.hubspotID,
					qualified: false,
					rejection_reasons: [`Error: ${e instanceof Error ? e.message : 'Unknown error'}`],
				};
				setTableData((prev) => (
					prev.map((i) => (i.url === item.url ? errorResult : i))
				));
			}
		}

		setRunning(false);
	};

	useEffect(() => {
		return () => {
			abortController.current.abort();
		};
	}, []);

	return (
		<Container fluid>
			<PageHeader title="Qualify Leads" />
			<Row className="mb-3">
				<Col>
					<Container fluid>
						{knowledgeBaseArticle && (
							<Row className="mb-3">
								<Col>
									<Alert variant="info">
							Selected criteria:&nbsp;
										<Link to={`/docs/kb/${knowledgeBaseArticle.$id}`} target="_blank">
											{knowledgeBaseArticle.title}
										</Link>
									</Alert>
								</Col>
							</Row>
						)}
						{running && (
							<Alert className="text-center" variant="info">
								<FontAwesomeIcon icon={faSpinner} spin className="me-2" />
					Qualifying leads. PLEASE DO NOT CLOSE THIS PAGE.
								{tableData.length > 0
									? ` ${Math.round((tableData.filter((lead) => lead.qualified != null).length / tableData.length) * 100)}% complete`
									: ' Starting...'
								}
								<FontAwesomeIcon icon={faSpinner} spin className="ms-2" />
							</Alert>
						)}
						{syncing && (
							<Alert className="text-center" variant="info">
								<FontAwesomeIcon icon={faHubspot} spin className="me-2" />
					Syncing selected leads to HubSpot. PLEASE DO NOT CLOSE THIS PAGE.
								<FontAwesomeIcon icon={faHubspot} spin className="ms-2" />
							</Alert>
						)}
						{synced && (
							<Alert className="text-center" variant="success">
					Selected leads have been synced to HubSpot.
							</Alert>
						)}
						{!running && tableData.length === 0 && (
							<LeadSubmissionForm
								onSubmit={runQualification}
								onSelectKnowledgeBase={setKnowledgeBaseArticle}
							/>
						)}
						{items.length > 0 && (
							<LeadQualificationTable
								className={synced ? 'opacity-50' : ''}
								items={items}
								data={tableData}
								running={running}
								abortController={abortController.current}
								showScores={knowledgeBaseArticle != null}
								runQualification={() => {
									abortController.current = new AbortController();
									runQualification();
								}}
								clearData={() => {
									setTableData([]);
									setItems([]);
								}}
							/>
						)}
					</Container>
				</Col>
				{!items.length && (
					<Col lg={2} md={12}>
						<FlowDiagramSelector
							knowledgeBaseArticle={knowledgeBaseArticle}
						/>
					</Col>
				)}
			</Row>
		</Container>
	);
}
