import { faChevronDown, faChevronRight, faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChatMessage } from '@newstex/ai/chat';
import { KnowledgeBase } from '@newstex/types/rag';
import { useLocalStorage } from '@uidotdev/usehooks';
import type { CoreMessage, ToolResultPart } from 'ai';
import { ChartConfiguration } from 'chart.js';
import cloneDeep from 'clone-deep';
import { saveAs } from 'file-saver';
import React, {
	KeyboardEvent,
	createContext,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import {
	Button,
	Form,
	InputGroup,
	Modal,
	OverlayTrigger,
	Tooltip,
} from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import remarkGfm from 'remark-gfm';
import ChartComponent from '~/components/charts/Chart';
import LoadingSpinner from '~/components/LoadingSpinner';
import { getChatSuggestions } from '~/lib/chat-suggestions';
import { sleep } from '~/lib/utils';

import { useAI } from './ai-provider';
import { useUserInfo } from './user-info';

export interface AIChatContextType {
	visible: boolean;
	createWithPrompt: (kb: KnowledgeBase) => Promise<void>;
	show: () => void;
	hide: () => void;
	sendMessage: (message: string) => Promise<void>;
	messages: ChatMessage[];
	exportToCSV: () => void;
}

const AIChatContext = createContext<AIChatContextType | null>(null);

interface ChartToolCall extends ToolResultPart {
	chartConfig?: ChartConfiguration;
}

export function AIToolCallDisplay({ toolCall, args }: { toolCall: ChartToolCall; args: any }) {
	const [isExpanded, setIsExpanded] = useState(false);

	if (toolCall.toolName === 'generateChart' && (toolCall.chartConfig || toolCall.result)) {
		const chartConfig = toolCall.chartConfig || (toolCall.result as ChartConfiguration);
		return (
			<div className="chart-container" style={{ width: '100%', margin: '1rem 0' }}>
				<ChartComponent
					className="w-100"
					type={chartConfig.type}
					data={chartConfig.data}
					options={chartConfig.options || {}}
				/>
			</div>
		);
	}

	return (
		<>
			<h6 onClick={() => setIsExpanded((e) => !e)} style={{ cursor: 'pointer' }}>
				<FontAwesomeIcon icon={isExpanded ? faChevronDown : faChevronRight} className="me-2" />
				{toolCall.toolName}
			</h6>
			<pre data-tool-call-id={toolCall.toolCallId} style={{ display: isExpanded ? 'block' : 'none' }}>
				{Boolean(args) && (
					<>
						<b>Args:</b>
						<br />
						{JSON.stringify(args, null, 2)}
						<br />
						<b>Result:</b>
						<br />
					</>
				)}
				{JSON.stringify(toolCall.result, null, 2)}
			</pre>
		</>
	);
}

const MAX_MESSAGES = 20; // Adjust this number based on your needs
const MAX_TOOL_RESULT_LENGTH = 500; // Maximum length for tool results in characters

function summarizeMessages(messages: CoreMessage[]): CoreMessage[] {
	if (messages.length <= MAX_MESSAGES) {
		return messages;
	}

	// Always keep the system message if it exists
	const systemMessage = messages.find((m) => m.role === 'system');

	// Keep the last MAX_MESSAGES/2 messages
	const recentMessages = messages.slice(-Math.floor(MAX_MESSAGES / 2));

	// Summarize older messages
	const olderMessages = messages.slice(0, -Math.floor(MAX_MESSAGES / 2));
	const summarizedContent = olderMessages
		.filter((m) => m.role !== 'system') // Skip system message as we handle it separately
		.map((m) => {
			if (m.role === 'tool') {
				// Summarize tool results
				return {
					...m,
					content: m.content.map((c: any) => ({
						...c,
						result: typeof c.result === 'string'
							? `${c.result.substring(0, MAX_TOOL_RESULT_LENGTH)}...`
							: `${JSON.stringify(c.result).substring(0, MAX_TOOL_RESULT_LENGTH)}...`,
					})),
				};
			}
			// Summarize text content
			return {
				role: m.role,
				content: typeof m.content === 'string'
					? `${m.content.substring(0, 200)}...`
					: m.content,
			};
		});

	// Combine messages with a summary message
	const summaryMessage: ChatMessage = {
		role: 'system',
		content: 'Previous conversation summary: The conversation included earlier messages and tool calls that have been summarized for brevity.',
	};

	return [
		...(systemMessage ? [systemMessage] : []),
		summaryMessage,
		...summarizedContent.slice(-3), // Keep last 3 summarized messages for better context
		...recentMessages,
	] as CoreMessage[];
}

export function AIChatProvider({ children }: React.PropsWithChildren<{}>) {
	const [visible, setVisible] = useState(false);
	const [messages, setMessages] = useLocalStorage<ChatMessage[]>('ai-chat-messages', []);
	const [input, setInput] = useState('');
	const [prompt, setPrompt] = useState<string | null>(null);
	const { chat, refreshCredentials } = useAI();
	const location = useLocation();
	const [suggestions, setSuggestions] = useState<string[]>([]);
	const [loading, setLoading] = useState(false);
	const [hasMore, setHasMore] = useState(false);
	const [isTextarea, setIsTextarea] = useState(false);
	const inputRef = useRef<HTMLInputElement & HTMLTextAreaElement>(null);
	const [error, setError] = useState<any>(null);
	const userInfo = useUserInfo();

	const show = () => {
		setVisible(true);
		let attempt = 0;
		const interval = setInterval(() => {
			if (inputRef.current) {
				inputRef.current.focus();
				clearInterval(interval);
			} else if (attempt > 10) {
				clearInterval(interval);
			}
			attempt++;
		}, 100);
	};
	const hide = () => setVisible(false);

	useEffect(() => {
		setSuggestions(getChatSuggestions(location.pathname));
	}, [location.pathname]);

	const clearMessages = async () => {
		setMessages([]);
		setSuggestions(getChatSuggestions(location.pathname));
		setInput('');
		setLoading(false);
		setHasMore(false);
		setPrompt(null);
		for (const key of Object.keys(localStorage)) {
			if (key.startsWith('ai-chat-tool-call-') || key.startsWith('ai-qualify-publication-')) {
				localStorage.removeItem(key);
			}
		}
	};

	const cleanUserMessage = (msg: string) => {
		const message = msg?.trim();
		if (!message) {
			return message;
		}

		if (message.toLowerCase() === '/clear') {
			clearMessages();
			return '';
		}

		if (message.toLowerCase() === '/back') {
			// Delete the most recent message
			setMessages((prevMessages) => prevMessages.slice(0, -1));
			return '';
		}

		if (message.toLowerCase() === '/continue') {
			return 'Continue';
		}

		if (message.startsWith('/')) {
			toast.info(`Command not found: ${message}`);
			return '';
		}

		if (message.toLowerCase().includes('new knowledge base') && !message.toLowerCase().includes('confirm')) {
			return `${message} Do not save this until you confirm with me first.`;
		}

		return message;
	};

	const doChat = async (currentMessages: ChatMessage[], startingMessages: ChatMessage[], attempt = 0) => {
		try {
			let currentMessage = '';
			let toolMessage: ChatMessage | null = null;

			// Add summarization here
			const summarizedMessages = summarizeMessages(currentMessages);

			const result = await chat({
				messages: summarizedMessages, // Use summarized messages instead of full messages
				prompt,
				onToolCall: (cmdName, cmdParams) => {
					console.log('*** CMD ***', cmdName, cmdParams);
					let toolCallContent: any = {
						type: 'tool-result',
						toolCallId: '',
						toolName: cmdName,
						result: cmdParams,
					};

					if (cmdName === 'generateChart') {
						try {
							const chartConfig = typeof cmdParams === 'string'
								? JSON.parse(cmdParams)
								: cmdParams;

							toolCallContent = {
								...toolCallContent,
								chartConfig: chartConfig.result || chartConfig,
							};
						} catch (e) {
							console.error('Failed to parse chart configuration:', e);
						}
					}

					toolMessage = {
						role: 'tool',
						content: [toolCallContent],
					};
					setMessages((prevMessages) => [...prevMessages, toolMessage!]);
				},
				onStepFinish: (step) => {
					currentMessages.push({
						role: 'assistant',
						content: step.text,
					});
					if (toolMessage) {
						currentMessages.push(toolMessage);
						toolMessage = null;
					}
					setMessages(currentMessages);
					currentMessage = '';
					if (step.toolCalls?.length) {
						for (const toolCall of step.toolCalls) {
							console.debug('*** TOOL CALL ***', toolCall);
							if (toolCall.toolCallId && toolCall.args) {
								localStorage.setItem(`ai-chat-tool-call-${toolCall.toolCallId}`, JSON.stringify(toolCall.args));
							}
						}
					}
				},
			});
			if (!result) {
				throw new Error('No result from chat');
			}
			for await (const chunk of result.textStream) {
				currentMessage += chunk;
				setMessages([
					...currentMessages,
					{ role: 'assistant', content: currentMessage },
				]);
			}
			const finishReason = await result.finishReason;
			if (finishReason === 'tool-calls') {
				setHasMore(true);
			} else {
				setHasMore(false);
			}
			const responseMessages = (await result.response).messages;
			setMessages([
				...startingMessages,
				...responseMessages,
			]);
			setError(null);
			setLoading(false);
		} catch (e: any) {
			console.error('Error sending message:', e);
			if (String(e.message || e.msg || e).toLowerCase().includes('expired')) {
				toast.warning('Credentials expired, refreshing...');
				if (refreshCredentials) {
					await refreshCredentials();
				}
			}

			if (attempt < 3) {
				await sleep(1000);
				return doChat(currentMessages, startingMessages, attempt + 1);
			}
			// Handle error (e.g., show an error message to the user)
			toast.error(`Error sending message: ${String(e.message || e.msg || e)}`);
			setError(e);
			setLoading(false);
		}
	};

	const sendMessage = async (msg: string) => {
		setError(null);
		const message = cleanUserMessage(msg);
		if (!message) {
			return;
		}
		setLoading(true);
		const userMessage: ChatMessage = { role: 'user', content: message };
		const currentMessages = msg === 'Continue'
			? cloneDeep(messages)
			: cloneDeep([...messages, userMessage]);
		const startingMessages = cloneDeep(currentMessages);

		setMessages((prevMessages) => [...prevMessages, userMessage]);
		setInput('');
		setSuggestions([]); // Clear suggestions after sending a message

		doChat(currentMessages, startingMessages);
	};

	// Add this new function to handle CSV export
	const exportToCSV = () => {
		const csvContent = messages.map((msg) => {
			const content = msg.role === 'tool'
				? `${msg.content[0].toolName}: ${JSON.stringify(msg.content[0].result)}`
				: msg.content;
			return `"${msg.role}","${String(content).replace(/"/g, '""')}"`; // Escape double quotes
		}).join('\n');

		const blob = new Blob([`Role,Content\n${csvContent}`], { type: 'text/csv;charset=utf-8;' });
		saveAs(blob, `chat-transcript-${new Date().toISOString().split('T')[0].split('-').join('')}.csv`);
	};

	const handleKeyDown = (e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		if ((e.ctrlKey || e.altKey || e.metaKey) && e.key === 'Enter') {
			e.preventDefault();
			setIsTextarea((ta) => !ta);
		} else if (e.key === 'Enter' && !isTextarea) {
			e.preventDefault();
			if (input.trim()) {
				sendMessage(input.trim());
			}
		}
	};

	/**
	 * Create a new chat with a prompt
	 * @param kb - The knowledge base article to use and search against
	 */
	const createWithPrompt = async (kb: KnowledgeBase) => {
		await clearMessages();
		setSuggestions([kb.title || 'What can you tell me about this article?']);
		setPrompt(`## Knowledge Base Article follows:
${kb.title}
_________________________________________
${kb.answer}
_________________________________________`);
		show();
	};

	const getToolCallArgs = (toolCallId: string) => {
		const item = localStorage.getItem(`ai-chat-tool-call-${toolCallId}`);
		if (!item) {
			return;
		}
		return JSON.parse(item);
	};

	useEffect(() => {
		if (inputRef.current) {
			inputRef.current.focus();
		}
	}, [isTextarea]);

	const value: AIChatContextType = {
		visible,
		show,
		hide,
		sendMessage,
		messages,
		exportToCSV,
		createWithPrompt,
	};

	return (
		<AIChatContext.Provider value={value}>
			{children}
			<Modal restoreFocus={true} show={visible} onHide={hide} className="ai-chat modal-lg">
				<Modal.Header closeButton>
					<Modal.Title>AI Chat</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="chat-messages" key="ai-chat-messages">
						{messages.filter((msg) => msg.role !== 'system').map((msg, index) => (<div key={`chat-${msg.role}-${index}`}>
							<div className={`message ${msg.role}`}>
								{msg.role === 'tool'
									? (<div key={`chat-tools-${index}`}>
										{msg.content.map((content: any) => (
											<AIToolCallDisplay
												key={content.toolCallId}
												toolCall={content}
												args={getToolCallArgs(content.toolCallId)}
											/>
										))}
									</div>
									) : (
										<ReactMarkdown remarkPlugins={[remarkGfm]}>
											{typeof msg.content === 'string'
												? msg.content
												: msg.content.map((c: any) => c.text).join('\n')}
										</ReactMarkdown>
									)
								}
							</div>
							<div className="clearfix" />
						</div>))}
						{messages.length === 0 && suggestions.length > 0 && (
							<div className="suggestions" key="ai-chat-suggestions">
								{suggestions.map((suggestion, index) => (
									<Button
										key={`chat-suggestion-${index}`}
										variant="outline-secondary"
										className="mb-2 me-2"
										onClick={() => sendMessage(suggestion)}
									>
										{suggestion}
									</Button>
								))}
							</div>
						)}
						<LoadingSpinner loading={loading} hideTitle/>
						<div className="clearfix" />
						{error && <div className="text-center text-danger">
							ERROR: {String(error.message || error.msg || error)}
							<div className="mt-2 text-center">
								<Button variant="outline-secondary" disabled={loading} onClick={() => {
									setError(null);
									sendMessage('Continue');
								}}>
									Retry
								</Button>
							</div>
						</div>}
						{hasMore && <div className="text-center" key="ai-chat-continue">
							<Button variant="outline-secondary" onClick={() => {
								setHasMore(false);
								sendMessage('Continue');
							}}>
								Continue Chat
							</Button>
						</div>}
						<div className="float-end" key="ai-chat-export">
							<OverlayTrigger
								placement="top"
								overlay={<Tooltip id="export-csv-tooltip">Export Chat Transcript to CSV</Tooltip>}
							>
								<Button
									variant="outline-secondary"
									size="sm"
									onClick={exportToCSV}
									disabled={messages.length === 0}
								>
									<FontAwesomeIcon icon={faDownload} />
								</Button>
							</OverlayTrigger>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer className="p-2">
					<Form
						onSubmit={(e) => {
							e.preventDefault();
							if (input.trim()) {
								sendMessage(input.trim());
								setIsTextarea(false);
							}
						}}
						className="w-100"
					>
						<InputGroup>
							{isTextarea ? (
								<Form.Control
									ref={inputRef}
									as="textarea"
									rows={3}
									value={input}
									onChange={(e) => setInput(e.target.value)}
									onKeyDown={handleKeyDown}
									placeholder={`${messages.length > 1 ? 'Reply, or type /clear to start over' : 'Type your question...'}`}
									disabled={loading}
								/>
							) : (
								<Form.Control
									ref={inputRef}
									type="text"
									value={input}
									onChange={(e) => setInput(e.target.value)}
									onKeyDown={handleKeyDown}
									placeholder={`${messages.length > 1 ? 'Reply, or type /clear to start over' : 'Type your question...'}`}
									disabled={loading}
								/>
							)}
							<Button type="submit" variant="primary" disabled={loading}>Send</Button>
						</InputGroup>
					</Form>
				</Modal.Footer>
			</Modal>
		</AIChatContext.Provider>
	);
}

export function useAIChat() {
	const context = useContext(AIChatContext);
	if (!context) {
		throw new Error('useAIChat must be used within an AIChatProvider');
	}
	return context;
}
