import * as Sentry from '@sentry/react';
import { Component, ErrorInfo, ReactNode } from 'react';
import { Container } from 'react-bootstrap';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';

interface Props {
	children?: ReactNode;
}

interface State {
	hasError: boolean;
	error?: Error;
}

function ErrorFallback({ error }: { error: Error }) {
	return (
		<Container fluid>
			<center className="p-5">
				<h1>Something went wrong</h1>
				<div>{error.message}</div>
			</center>
		</Container>
	);
}

export class CustomErrorBoundary extends Component<Props, State> {
	public static getDerivedStateFromError(error: Error): State {
		return { hasError: true, error };
	}

	public state: State = {
		hasError: false,
	};

	public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		console.error('Uncaught error:', error, errorInfo);
		Sentry.captureException(error);
	}

	public render() {
		if (this.state.hasError) {
			return <ErrorFallback error={this.state.error!} />;
		}

		return this.props.children;
	}
}

export function RouteErrorBoundary() {
	const error = useRouteError();

	if (isRouteErrorResponse(error)) {
		return (
			<Container fluid>
				<center className="p-5">
					<h1>{error.status} {error.statusText}</h1>
					<div>{error.data}</div>
				</center>
			</Container>
		);
	}

	return <ErrorFallback error={error as Error} />;
}
