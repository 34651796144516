/**
 * Create Publication Modal
 *
 * @see https://app.asana.com/0/214270922471/1207889058997289/f
 */
import { COUNTRIES, LANGUAGES } from '@newstex/core/constants';
import { type Content, type Publication, isPublication } from '@newstex/types/content';
import { ContentStatus } from '@newstex/types/content-status';
import { DetectFeedResponse } from '@newstex/types/detect-feed';
import { useEffect, useRef, useState } from 'react';
import {
	Alert,
	Button,
	Col,
	Container,
	Form,
	Modal,
	Row,
	Spinner,
} from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAPI } from '~/providers/api-provider';
import { useSearch } from '~/providers/search';

import { StatusBadge } from './status-badge';

export interface CreatePublicationButtonProps {
	parent: Content,
	pubURL: string,
	hide: (created?: boolean) => void,
}

function matchesURL(hit: Content, urls: string[]) {
	for (const url of urls) {
		if (url.length) {
			const trimmedURL = url.trim()?.replace(/\/+$/, '');
			if (hit.url?.trim()?.replace(/\/+$/, '') === trimmedURL || hit.alt_urls?.includes(trimmedURL) || hit.xml_feed?.includes(trimmedURL)) {
				return true;
			}
		}
	}
	return false;
}

export default function CreatePublicationModal({ parent, pubURL, hide }: CreatePublicationButtonProps) {
	const [isLoading, setIsLoading] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [error, setError] = useState('');
	const [conflicts, setConflicts] = useState<Publication[]>([]);
	const formRef = useRef<HTMLFormElement>(null);

	const api = useAPI();
	const search = useSearch();
	const navigate = useNavigate();

	useEffect(() => {
		setError('');
		const controller = new AbortController();
		async function createPublication() {
			setIsLoading(true);
			setConflicts([]);
			try {
				const searchURLs: string[] = [pubURL];
				// First, run a detect operation to fetch a possible XML Feed and other auto-identified information
				const qs = new URLSearchParams({
					url: pubURL,
				});
				const detectResp = await api.fetchWithAuth<DetectFeedResponse>(`detect?${qs.toString()}`, {
					signal: controller.signal,
					maxAttempts: 2,
				}).catch((e) => {
					console.error('ERROR DETECTING FEED', e);
					return null;
				});

				if (detectResp && formRef.current) {
					console.log('Detected', detectResp);
					if (detectResp.name) {
						(formRef.current.querySelector('[name="name"]') as HTMLInputElement).value = detectResp.name;
					}

					if (detectResp.description) {
						(formRef.current.querySelector('[name="description"]') as HTMLInputElement).value = detectResp.description;
					} else if (parent.description) {
						(formRef.current.querySelector('[name="description"]') as HTMLInputElement).value = parent.description;
					}

					if (detectResp.feed_url) {
						searchURLs.push(detectResp.feed_url);
						(formRef.current.querySelector('[name="xml_feed"]') as HTMLInputElement).value = detectResp.feed_url;
					}

					if (detectResp.url && detectResp.url !== pubURL) {
						searchURLs.push(detectResp.url);
					}
				}

				// Next, search for any existing publications with the same URL
				if (search.searchClient) {
					const foundMatchIDs: string[] = [];
					const foundMatches: Publication[] = [];
					const searchResp = await search.searchClient.multiSearch(searchURLs.map((url) => ({
						query: url.replace(/^https?:\/\//, ''),
						per_page: 10,
						indexName: 'Publication',
					})));

					for (const resp of searchResp) {
						if (resp.hits?.length) {
							for (const hit of resp.hits) {
								if (
									hit.$id
									&& !foundMatchIDs.includes(hit.$id)
									&& isPublication(hit)
									&& hit.content_status !== ContentStatus.DELETED
									&& matchesURL(hit, searchURLs)
								) {
									foundMatchIDs.push(hit.$id);
									foundMatches.push(hit);
								}
							}
						}
					}
					setConflicts(foundMatches);
				}
			} catch (e) {
				console.error(e);
			}

			if (!controller.signal.aborted) {
				setIsLoading(false);
			}
		}
		createPublication();
		return () => {
			controller.abort();
		};
	}, [parent, pubURL]);

	const savePublication = () => {
		if (formRef.current) {
			setIsSaving(true);
			const formData = new FormData(formRef.current);
			const pubData: Partial<Publication> = {
				$type: 'Publication',
				parent: parent.$id,
				name: formData.get('name')?.toString(),
				url: formData.get('url')?.toString(),
				description: formData.get('description')?.toString(),
				xml_feed: formData.get('xml_feed')?.toString()?.split(/\r\n/g),
				status: 'Lead',
				content_status: ContentStatus.INTEGRATION,
				content_type: 'Weblog',
				closed_sites_only: formData.get('closed_sites_only') === 'on' ? true : parent.closed_sites_only,
				calculate_royalties: formData.get('calculate_royalties') === 'on',
				feed_priority: parent.feed_priority,
				owner: parent.owner,
			};

			if (formData.get('pub_country')) {
				pubData.pub_country = formData.get('pub_country')?.toString();
			}

			if (formData.get('country')) {
				pubData.country = formData.get('country')?.toString();
			}

			if (formData.get('language')) {
				pubData.language = formData.get('language')?.toString();
			}
			console.log('Saving', pubData);
			toast.info('Creating Publication...');
			api.fetchWithAuth('resources/Publication', {
				method: 'POST',
				body: JSON.stringify(pubData),
			}).then((resp) => {
				console.log('Saved', resp);
				toast.success('Publication created', {
					onClick: () => {
						navigate(`/publications/${resp.$id}`);
					},
				});
				setIsSaving(false);
				hide(true);
				if (resp?.$id) {
					navigate(`/publications/${resp.$id}`);
				}
			}).catch((e) => {
				console.error(e);
				toast.error(`Publication creation failed: ${String(e)}`);
				setError(String(e));
				setIsSaving(false);
			});
		}
	};

	return (
		<Modal
			size="xl"
			fullscreen="xxl-down"
			show={true}
			onHide={() => hide(false)}
		>
			<Modal.Header closeButton>
				<Modal.Title>Create Publication for <i>{parent.name}</i> [{parent.newstex_id || parent.status || 'NEW'}]</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{error && <Alert variant="danger">{error}</Alert>}
				{conflicts.length > 0 && (
					<Alert variant="warning">
						This publication may be a duplicate of the existing publication{(conflicts.length > 1 ? 's' : '')}:
						{conflicts.map((c) => <li key={c.$id}>
							<Link to={`/publications/${c.$id}`}>
								{c.name} [{c.newstex_id || 'NEW'}] <StatusBadge status={c.status} />
							</Link>
						</li>)}
					</Alert>
				)}
				{isLoading && <center><Spinner animation="border" size="sm" /></center>}
				<Form ref={formRef}>
					<Container fluid className="row-padding">
						<Row>
							<Col>
								<Form.Group>
									<Form.Label className="fw-bold">URL</Form.Label>
									<Form.Control type="text" value={pubURL} name="url" readOnly disabled={isLoading || isSaving} />
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col>
								<Form.Group>
									<Form.Label className="fw-bold">Publication Name</Form.Label>
									<Form.Control type="text" name="name" disabled={isLoading || isSaving} />
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col>
								<Form.Group>
									<Form.Label className="fw-bold">Description</Form.Label>
									<Form.Control
										as="textarea"
										placeholder="Enter a description for the publication"
										name="description"
										rows={3}
										disabled={isLoading || isSaving}
									/>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col>
								<Form.Group>
									<Form.Label className="fw-bold">Feed URLs</Form.Label>
									<Form.Control
										as="textarea"
										name="xml_feed"
										placeholder="Enter one feed URL per line"
										rows={3}
										disabled={isLoading || isSaving}
									/>
									<Form.Text className="text-muted">
							Enter each feed URL on a separate line. These URLs should point to RSS or Atom feeds for the publication.
									</Form.Text>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col>
								<Form.Group>
									<Form.Label className="fw-bold">Country of Origin</Form.Label>
									<Form.Select name="pub_country" disabled={isLoading || isSaving}>
										<option value="">Select a country</option>
										{Object.entries(COUNTRIES).map(([code, name]) => (
											<option key={code} value={code}>{name} ({code})</option>
										))}
									</Form.Select>
								</Form.Group>
							</Col>
							<Col>
								<Form.Group>
									<Form.Label className="fw-bold">Country Focus</Form.Label>
									<Form.Select name="country" disabled={isLoading || isSaving}>
										<option value="">Select a country</option>
										{Object.entries(COUNTRIES).map(([code, name]) => (
											<option key={code} value={code}>{name} ({code})</option>
										))}
									</Form.Select>
									<Form.Text className="text-muted">
							The primary country that this publication focuses on in its content
									</Form.Text>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col>
								<Form.Group>
									<Form.Label className="fw-bold">Language</Form.Label>
									<Form.Select name="language" disabled={isLoading || isSaving}>
										<option value="">Select a language</option>
										{Object.entries(LANGUAGES).map(([code, name]) => (
											<option key={code} value={code}>{name} ({code})</option>
										))}
									</Form.Select>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col>
								<Form.Group>
									<Form.Check
										type="checkbox"
										id="closed-sites-only"
										name="closed_sites_only"
										label="Closed Sites Only"
										disabled={isLoading || isSaving}
										defaultChecked={parent.closed_sites_only}
									/>
									<Form.Text className="text-muted">
							If checked, this content can only be delivered to "Closed Site" distributors (no open web distribution)
									</Form.Text>
								</Form.Group>
							</Col>
							<Col>
								<Form.Group>
									<Form.Check
										type="checkbox"
										id="calculate-royalties"
										name="calculate_royalties"
										label="Calculate Royalties"
										disabled={isLoading || isSaving}
										defaultChecked={true}
									/>
									<Form.Text className="text-muted">
							If checked, this publication will be synced with NewsCrunch for royalty calculations
									</Form.Text>
								</Form.Group>
							</Col>
						</Row>
					</Container>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				{isSaving && <Spinner animation="border" size="sm" /> }
				<Button variant="secondary" onClick={() => hide(false)}>Cancel</Button>
				<Button variant="primary" onClick={(e) => {
					e.preventDefault();
					savePublication();
				}} disabled={isLoading || isSaving}>Create Publication</Button>
			</Modal.Footer>

		</Modal>
	);
}
