// Delivery/:id
import type { Delivery } from '@newstex/types/delivery';
import { ReactNode, useEffect, useState } from 'react';
import {
	Button,
	Card,
	Col,
	Container,
	Row,
	Tab,
	Tabs,
} from 'react-bootstrap';
import { useParams, useSearchParams } from 'react-router-dom';
import EditButton from '~/components/edit-button';
import LoadingSpinner from '~/components/LoadingSpinner';
import { ObjectHistoryButton } from '~/components/object-history-button';
import { PropertyDisplayValue } from '~/components/property-display-value';
import { RefreshButton } from '~/components/refresh-button';
import { RequirePermissions } from '~/components/require-permissions';
import { DeliveryStats } from '~/components/story/delivery-stats';
import { PropertiesTable } from '~/components/tables/properties-table';
import { useAPI } from '~/providers/api-provider';
import { useConfirm } from '~/providers/confirm';

export function DeliveryPage() {
	const params = useParams();
	const api = useAPI();
	const { confirmDialog } = useConfirm();
	const [delivery, setDelivery] = useState<Delivery>();
	const [loading, setLoading] = useState(true);
	const [searchParams, setSearchParams] = useSearchParams();

	const fetchData = async (refresh = false) => {
		setLoading(true);
		const resp = await api.fetchWithAuth(
			`resources/Delivery/${params.id}`,
			refresh ? { cache: 'reload' } : undefined,
		);
		setDelivery(resp.items?.[0]);
		setLoading(false);
	};

	const updateDelivery = async (updates: Partial<Delivery>, actionVerb: string, details?: ReactNode) => {
		if (await confirmDialog({
			title: `Confirm ${actionVerb}`,
			message: `Are you sure you want to ${actionVerb} this Delivery?`,
			details: <div className="mt-2">
				This will set the status of the delivery
				<i> {delivery?.name} </i>
				to <b> {updates.status} </b>
				{details && <div className="mt-2">{details}</div>}
			</div>,
		})) {
			if (delivery && api) {
				await api.updateItem<Delivery>(delivery, {
					$comment: actionVerb,
					...updates,
				});
			}
		}
	};

	useEffect(() => {
		fetchData();
	}, [params]);

	return (
		<Container fluid>
			<div className="title-wrapper pt-30">
				<Row className="align-items-center">
					<Col md={6}>
						<div className="title">
							<h2>
								{delivery?.name}
								<RequirePermissions permissions={['admin']}>
									<span className="ps-1">
										<ObjectHistoryButton
											item={delivery}
										/>
									</span>
								</RequirePermissions>
							</h2>
							<h6 className="text-muted">
								{delivery?.type}
							</h6>
						</div>
					</Col>
					<Col md={6} className="text-end">
						<div className="breadcrumb-wrapper">
							<nav aria-label="breadcrumb" className="mb-0">
								<ol className="breadcrumb">
									<li className="breadcrumb-item active">
										<a>Delivery</a>
									</li>
								</ol>
							</nav>
							<div className="float-end">
								<RequirePermissions permissions={['admin', 'tech']}>
									<span className="ps-1">
										<EditButton
											item={delivery}
											size="sm"
											refreshHandler={() => fetchData(true)}
										/>
									</span>
								</RequirePermissions>
								<span className="ps-1">
									<RefreshButton
										loading={loading}
										setLoading={setLoading}
										size="sm"
										refreshHandler={() => {
											return fetchData(true);
										}}
									/>
								</span>
							</div>
						</div>
					</Col>
				</Row>
			</div>
			<hr />
			<Row>
				<Col md={12}>
					{!loading && delivery?.$id && <DeliveryStats deliveryID={delivery?.$id} />}
				</Col>
			</Row>
			<hr />

			<Row>
				<Col md={12}>
					<Card className="delivery">
						<Card.Body>
							<LoadingSpinner loading={loading} />
							<Tabs
								defaultActiveKey={searchParams.get('tab') || 'default'}
								id="content-tabs"
								className="mb-3"
								onSelect={(key) => {
									setSearchParams({
										tab: key || '',
									});
								}}
							>
								<Tab eventKey="default" title="General Info">
									<dl className="row properties-table">
										<dt className="col-sm-3">Name</dt>
										<dd className="col-sm-9"><PropertyDisplayValue propName="name" propValue={delivery?.name} /></dd>

										<dt className="col-sm-3">Delivery Type</dt>
										<dd className="col-sm-9"><PropertyDisplayValue propName="type" propValue={delivery?.type} /></dd>

										<dt className="col-sm-3">Status</dt>
										<dd className="col-sm-9"><PropertyDisplayValue propName="status" propValue={delivery?.status} /></dd>

										<dt className="col-sm-3">Products</dt>
										<dd className="col-sm-9"><PropertyDisplayValue propName="products" propValue={delivery?.products} /></dd>

										<dt className="col-sm-3">Recipient</dt>
										<dd className="col-sm-9"><PropertyDisplayValue propName="recipient" propValue={delivery?.recipient} /></dd>

										<dt className="col-sm-3">Technical Contact</dt>
										<dd className="col-sm-9"><PropertyDisplayValue propName="tech_contact" propValue={delivery?.tech_contact} /></dd>
									</dl>
								</Tab>
								<Tab eventKey="config" title="Configuration">
									<dl className="row properties-table">
										<dt className="col-sm-3">Template</dt>
										<dd className="col-sm-9"><PropertyDisplayValue propName="story_template" propValue={delivery?.story_template} /></dd>

										<dt className="col-sm-3">Queue</dt>
										<dd className="col-sm-9"><PropertyDisplayValue propName="queue_name" propValue={delivery?.queue_name} /></dd>

										<dt className="col-sm-3">Verify Receipt</dt>
										<dd className="col-sm-9"><PropertyDisplayValue propName="verify" propValue={delivery?.verify} /></dd>

										{(delivery?.type === 'HTTP' || delivery?.type === 'SFTP') && (
											<>
												<dt className="col-sm-3">Username</dt>
												<dd className="col-sm-9"><PropertyDisplayValue propName="username" propValue={delivery?.username} /></dd>

												<dt className="col-sm-3">Password</dt>
												<dd className="col-sm-9"><PropertyDisplayValue propName="password" propValue={delivery?.password} /></dd>

												<dt className="col-sm-3">Private Key</dt>
												<dd className="col-sm-9"><PropertyDisplayValue propName="private_key" propValue={delivery?.private_key} /></dd>

											</>
										)}

										{delivery?.type === 'SFTP' && (
											<>
												<dt className="col-sm-3">Host</dt>
												<dd className="col-sm-9"><PropertyDisplayValue propName="host" propValue={delivery?.host} /></dd>

												<dt className="col-sm-3">Port</dt>
												<dd className="col-sm-9"><PropertyDisplayValue propName="port" propValue={delivery?.port} /></dd>
											</>
										)}

										{delivery?.type === 'S3' && (
											<>
												<dt className="col-sm-3">Bucket</dt>
												<dd className="col-sm-9"><PropertyDisplayValue propName="bucket_name" propValue={delivery?.bucket_name} /></dd>

												<dt className="col-sm-3">User ID/Email</dt>
												<dd className="col-sm-9"><PropertyDisplayValue propName="user_id" propValue={delivery?.user_id || delivery?.user_email} /></dd>
											</>
										)}

										{delivery?.type === 'HTTP' && (
											<>
												<dt className="col-sm-3">URL</dt>
												<dd className="col-sm-9"><PropertyDisplayValue propName="url" propValue={delivery?.url} /></dd>

												<dt className="col-sm-3">HTTP Method</dt>
												<dd className="col-sm-9"><PropertyDisplayValue propName="method" propValue={delivery?.method} /></dd>

												<dt className="col-sm-3">Base64 Encoding?</dt>
												<dd className="col-sm-9"><PropertyDisplayValue propName="encode" propValue={delivery?.encode} /></dd>
											</>
										)}
									</dl>
								</Tab>
								<Tab eventKey="properties" title="All Properties">
									<PropertiesTable item={delivery} />
								</Tab>
							</Tabs>
						</Card.Body>
						<Card.Footer>
							<RequirePermissions permissions={['admin', 'tech']}>
								<div className="d-flex justify-content-center">
									{
										delivery?.status === 'Active'
									&& <span className="ps-1">
										<Button size="sm" variant="outline-warning"
											title="Pause Delivery"
											onClick={async (e) => {
												await updateDelivery({
													status: 'Standby',
												}, 'Pause', <>
													This will stop active deliveries, but queue up stories for delivery
													once the delivery is set to active again. Stories may be queued for up to 7 days.
												</>);
											}}>Pause Delivery</Button>
									</span>
									}
									{
										delivery?.status !== 'Active'
									&& <span className="ps-1">
										<Button size="sm" variant="outline-success"
											title="Activate Delivery"
											onClick={() => updateDelivery({
												status: 'Active',
											}, 'Activate')}>Activate Delivery</Button>
									</span>
									}
									{
										delivery?.status !== 'Inactive'
									&& <span className="ps-1">
										<Button size="sm" variant="outline-danger"
											title="Disable Delivery"
											onClick={() => updateDelivery({
												status: 'Inactive',
											}, 'Disable', <>
												This will disable the delivery, and stories will <b>not be queued</b> for later delivery.
												Any stories that were queued will also be removed from the queue.
											</>)}>Disable Delivery</Button>
									</span>
									}
								</div>
							</RequirePermissions>
						</Card.Footer>
					</Card>
				</Col>
			</Row>
		</Container>
	);
}
