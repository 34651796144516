import { type Alert, AlertStatus } from '@newstex/types/alert';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { AlertBadge } from '../alert-badge';
import { LoadMoreButton } from '../load-more-button';
import { PropertyDisplayValue } from '../property-display-value';

interface AlertsTableProps {
	alerts: Alert[];
	hasMore?: boolean;
	onLoadMore?: () => void;
}

export function AlertsTable({ alerts, hasMore, onLoadMore }: AlertsTableProps) {
	return (
		<>
			<Table hover responsive striped>
				<thead>
					<tr>
						<th>Name</th>
						<th>Collection</th>
						<th>Schedule</th>
						<th>Last Run</th>
						<th>Status</th>
					</tr>
				</thead>
				<tbody>
					{alerts.map((alert) => (
						<tr key={alert.$id} className={`alert-${AlertStatus[alert.status || AlertStatus.NO_DATA]?.toLowerCase()}`}>
							<td>
								<Link to={`/alerts/${alert.$id}`}>
									{alert.name}
								</Link>
							</td>
							<td>{alert.collection}</td>
							<td>
								<PropertyDisplayValue propName="schedule" propValue={alert.schedule} short />
							</td>
							<td>
								{alert.last_run
									? new Date(alert.last_run * 1000).toLocaleString()
									: 'Never'}
							</td>
							<td>
								<AlertBadge status={alert.status || AlertStatus.NO_DATA} />
							</td>
						</tr>
					))}
				</tbody>
			</Table>
			<LoadMoreButton show={hasMore} onClick={onLoadMore} />
		</>
	);
}
