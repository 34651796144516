import { RevenueAndEarningsForContact, RevenueAndEarningsReport } from '@newstex/types/revenue';
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import LoadingSpinner from '~/components/LoadingSpinner';
import { PageTitle } from '~/components/page-title';
import Preloader from '~/components/Preloader';
import { SearchBox } from '~/components/search-box';
import { SimpleFacetMenu } from '~/components/simple-facet-menu';
import { RevenueTable } from '~/components/tables/revenue-table';
import { useAPI } from '~/providers/api-provider';

export function RevenueListPage() {
	const [searchParams, setSearchParams] = useSearchParams();
	const api = useAPI();
	const [loading, setLoading] = useState<boolean>(false);
	const [allRevenue, setAllRevenue] = useState<RevenueAndEarningsReport[]>([]);
	const [revenue, setRevenue] = useState<RevenueAndEarningsReport[]>([]);
	const [distributors, setDistributors] = useState<{
		label: string,
		value: string,
		count: string,
	}[]>([]);
	const [periods, setPeriods] = useState<{
		label: string,
		value: string,
		count: string,
	}[]>([]);
	const [publications, setPublications] = useState<{
		label: string,
		value: string,
		count: string,
	}[]>([]);

	// Filter Results
	useEffect(() => {
		let filteredRevenue = allRevenue;
		for (const facet of ['period', 'client', 'publicationNewstexID'] as (keyof RevenueAndEarningsReport)[]) {
			const filter = searchParams.get(facet);
			if (filter) {
				filteredRevenue = filteredRevenue.filter((r) => r[facet] === filter);
			}
		}

		if (searchParams.get('q')) {
			// Implement search functionality here
			// This is a simple example, you might want to use a library like Fuse.js for more complex searching
			const searchTerm = searchParams.get('q')?.toLowerCase() || '';
			setRevenue(filteredRevenue.filter((r) => r.publicationName.toLowerCase().includes(searchTerm)));
		} else {
			setRevenue(filteredRevenue);
		}
	}, [allRevenue, searchParams]);

	const fetchData = async () => {
		setLoading(true);
		const resp = await api.fetchWithAuth<RevenueAndEarningsForContact>('revenue');
		setAllRevenue(resp?.earnings || []);
		setPeriods(() => {
			const reportPeriods: {
				label: string,
				value: string,
				count: string,
			}[] = [];
			const totalEarningsByPeriod: Record<string, number> = {};
			if (resp?.earnings) {
				for (const row of resp.earnings) {
					if (!totalEarningsByPeriod[row.period]) {
						totalEarningsByPeriod[row.period] = 0;
					}
					totalEarningsByPeriod[row.period] += row.earnings || 0;
				}
			}
			for (const period in totalEarningsByPeriod) {
				reportPeriods.push({
					label: new Date(period).toLocaleDateString(undefined, { month: 'long', year: 'numeric' }),
					value: period,
					count: `$${totalEarningsByPeriod[period].toFixed(2)}`,
				});
			}
			return reportPeriods.sort((a, b) => new Date(b.value).getTime() - new Date(a.value).getTime());
		});
		setDistributors(() => {
			const distributorCounts: Record<string, number> = {};
			for (const row of resp.earnings) {
				if (!distributorCounts[row.client]) {
					distributorCounts[row.client] = 0;
				}
				distributorCounts[row.client] += row.earnings || 0;
			}
			const currentDistributors: {
				label: string,
				value: string,
				count: number,
			}[] = [];
			for (const [distributor, count] of Object.entries(distributorCounts)) {
				currentDistributors.push({
					label: distributor,
					value: distributor,
					count,
				});
			}
			return currentDistributors.sort((a, b) => b.count - a.count).map((d) => ({
				...d,
				count: `$${d.count.toFixed(2)}`,
			}));
		});
		setPublications(() => {
			const publicationCounts: Record<string, number> = {};
			for (const row of resp.earnings) {
				if (!publicationCounts[row.publicationNewstexID]) {
					publicationCounts[row.publicationNewstexID] = 0;
				}
				publicationCounts[row.publicationNewstexID] += row.earnings || 0;
			}
			const currentPublications: {
				label: string,
				value: string,
				count: number,
			}[] = [];
			for (const row of resp.earnings) {
				const count = publicationCounts[row.publicationNewstexID];
				if (count !== undefined) {
					currentPublications.push({
						label: row.publicationName,
						value: row.publicationNewstexID,
						count,
					});
					// Remove the counted publication to avoid duplicates
					delete publicationCounts[row.publicationNewstexID];
				}
			}
			return currentPublications.sort((a, b) => b.count - a.count).map((p) => ({
				...p,
				count: `$${p.count.toFixed(2)}`,
			}));
		});
		setLoading(false);
	};

	// Load all results
	useEffect(() => {
		fetchData();
	}, [api]);

	return (
		<Container fluid>
			<PageTitle title="Revenue Reports" />
			<Row>
				<Col>
					<SearchBox />
				</Col>
			</Row>
			{loading && <Preloader type="three-bounce" variant="success" />}
			<hr/>
			<Row>
				<Col md={4} xxl={2}>
					{Boolean(periods?.length && periods.length > 1) && (
						<SimpleFacetMenu
							title="Reporting Period"
							prop="period"
							options={[
								{
									label: 'All',
									value: '',
								},
								...periods,
							]}
						/>
					)}
					{Boolean(distributors?.length && distributors.length > 1) && (
						<SimpleFacetMenu
							title="Distributor"
							prop="client"
							options={[
								{
									label: 'All',
									value: '',
								},
								...distributors,
							]}
						/>
					)}
					{Boolean(publications?.length && publications.length > 1) && (
						<SimpleFacetMenu
							title="Publication"
							prop="publicationNewstexID"
							options={[
								{
									label: 'All',
									value: '',
								},
								...publications,
							]}
						/>
					)}
				</Col>
				<Col md={8} xxl={10}>
					{loading
						? <LoadingSpinner loading={loading} />
						: <RevenueTable revenue={revenue || []} />
					}
				</Col>
			</Row>
		</Container>
	);
}
