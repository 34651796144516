/* eslint-disable no-restricted-globals */
import { useLocalStorage } from '@uidotdev/usehooks';
import type React from 'react';
import { createContext, useContext } from 'react';
import { Modal, Spinner } from 'react-bootstrap';

interface AuthData {
	[accountID: string]: UserType;
}

export interface UserType {
	type?: string;
	isAdmin?: boolean;
	name: string;
	given_name?: string;
	family_name?: string;
	email: string;
	accountID: string;
	accessToken: string;
	idToken?: string;
	sessionToken: string;
	picture?: string;
}

function get() {
	return JSON.parse(localStorage.getItem('auth') || '{}') as AuthData;
}

function set(auth: AuthData) {
	return localStorage.setItem('auth', JSON.stringify(auth));
}

function login() {
	const params = new URLSearchParams({
		client_id: 'solid',
		redirect_uri: `${location.origin}/`,
		response_type: 'token',
		provider: 'google',
	});
	const url = `${import.meta.env.VITE_API_URL}/auth/google/authorize?${params.toString()}`;
	return url;
}

interface AuthContextType {
	user?: UserType;
	runAs?: string | null;
	assumeUser?: (email: string | null) => void;
	logout: () => void;
}

export function Logout() {
	console.log('Logging out');
	localStorage.clear();
	setTimeout(() => {
		window.location.reload();
	}, 500);
	return (
		<Modal
			size="lg"
			centered
			show={true}
		>
			<Modal.Header>
				<Modal.Title>
					Logging Out
				</Modal.Title>
			</Modal.Header>
			<Modal.Body className="text-center p-4">
				<Spinner animation="border" role="status">
					<span className="visually-hidden">Logging Out...</span>
				</Spinner>
			</Modal.Body>
		</Modal>
	);
}

const AuthContext = createContext<AuthContextType>({
	logout: Logout,
});

export function AuthProvider({ children }: React.PropsWithChildren<{}>) {
	const tokens = get();
	const [runAs, setRunAs] = useLocalStorage<string | null>('runAs', null);
	const fragment = new URLSearchParams(location.hash.substring(1));
	const sessionToken = fragment.get('session_token');
	const idToken = fragment.get('id_token');
	if (idToken && sessionToken) {
		const [headerEncoded, payloadEncoded] = idToken.split('.');
		const payload = JSON.parse(
			atob(payloadEncoded.replace(/-/g, '+').replace(/_/g, '/')),
		);
		tokens[payload.sub] = {
			accessToken: fragment.get('access_token'),
			idToken,
			sessionToken,
			isAdmin: payload.email?.endsWith('@newstex.com'),
			...payload,
		};
		set(tokens);
		location.hash = '';
	} else if (sessionToken && Object.values(tokens).length === 0) {
		tokens.session = {
			sessionToken,
			name: 'Session',
			email: fragment.get('email') || 'session@newstex.com',
			accountID: fragment.get('account_id') || 'session',
			accessToken: fragment.get('access_token') || '',
			idToken: sessionToken,
			isAdmin: false,
		};
		set(tokens);
	}

	if (Object.values(tokens).length === 0) {
		location.href = login();
		return null;
	}

	const ctx: AuthContextType = {
		runAs,
		assumeUser: (email) => {
			setRunAs(email);
			// Wait one second then reload the page
			setTimeout(() => {
				window.location.reload();
			}, 1000);
		},
		logout: Logout,
	};

	for (const token of Object.values(tokens)) {
		if (token.type === 'user' || !token.type) {
			ctx.user = token;
		}
	}

	return (
		<AuthContext.Provider value={ctx}>{children}</AuthContext.Provider>
	);
}

export function useAuth() {
	const result = useContext(AuthContext);
	if (!result) throw new Error('useAuth must be used within an AuthProvider');
	return result;
}
