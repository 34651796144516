/**
 * Trims empty properties from an object, used for serializing items into DynamoDB
 * @param originalItem - The item to update
 * @param replacementValue - Optional value to replace empty values with
 * 	(should be either undefined or null)
 */
function replaceEmptyPropsWithValue<T = any>(
	item: T,
	replacementValue?,
	replaceNulls = false,
): T {
	if (item === null || item === undefined) {
		if (replaceNulls && item === null) {
			return replacementValue;
		}
		return item;
	}

	if (Array.isArray(item)) {
		if (item.length === 0) {
			return replacementValue;
		}
		const newVal: any[] = [];
		for (const value of item) {
			const checkVal = replaceEmptyPropsWithValue(value, replacementValue, replaceNulls);
			if (checkVal !== undefined) {
				newVal.push(checkVal);
			}
		}
	} else if (typeof item === 'string' && item.length === 0) {
		// Trim out empty strings
		return replacementValue;
	} else if (typeof item === 'object') {
		for (const key of Object.keys(item)) {
			const value = replaceEmptyPropsWithValue(item[key], replacementValue, replaceNulls);
			if (value === undefined) {
				delete item[key];
			} else {
				item[key] = value;
			}
		}
	}
	return item;
}

/**
 * Trims empty properties from a given value, removing them from the object provided
 * For use on the back-end before serializing to DynamoDB
 *
 * @param item - Item to trim from
 * @returns empty properties removed from item
 */
export function trimEmptyProps<T = any>(item: T, replaceNulls = false): T {
	return replaceEmptyPropsWithValue(item, undefined, replaceNulls);
}

/**
 * Replaces all empty strings or empty arrays with "null"
 * For use with the front-end application to "serialize" objects before
 * sending them along to the API
 *
 * @param item - Item to trim from
 * @returns empty properties replaced with Null in the Item
 */
export function replaceEmptyPropsWithNull<T = any>(item: T): T {
	return replaceEmptyPropsWithValue(item, null);
}
