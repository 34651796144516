/**
 * Edit Content Modal
 */
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { faLock } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { arrayEqual } from '@newstex/core/array-utils';
import { COUNTRIES } from '@newstex/core/constants';
import { unixNow } from '@newstex/core/date';
import { PUBLICATION_STATUSES, PUBLICATION_TYPES, STATUSES } from '@newstex/core/enums';
import { Content, Publication, isPublication } from '@newstex/types/content';
import omit from 'object.omit';
import { useMemo, useRef, useState } from 'react';
import {
	Alert,
	Button,
	Col,
	Container,
	Form,
	Modal,
	Row,
} from 'react-bootstrap';
import { useAPI } from '~/providers/api-provider';
import { useUserInfo } from '~/providers/user-info';

import EditableBooleanField from '../boolean';
import EditableDateField from '../date';
import EditableMultiValueField from '../multi-value';
import { ProductEditor } from '../products';
import EditableSelectField from '../select';
import EditableTextField from '../text';

const COUNTRY_OPTIONS = Object.entries(COUNTRIES).map(([code, name]) => ({
	value: code,
	label: name,
}));

export interface EditContentModalProps {
	item: Content,
	show: boolean,
	onClose: (saved: boolean) => void,
}

export default function EditContentModal({
	item,
	show,
	onClose,
}: EditContentModalProps) {
	const userInfo = useUserInfo();
	const api = useAPI();
	const commentRef = useRef<HTMLInputElement>(null);
	const [saving, setSaving] = useState(false);
	const [error, setError] = useState<any>();
	const [updates, setUpdates] = useState<Partial<typeof item>>({});
	const typeName = useMemo(() => (isPublication(item) ? 'Publication' : 'Publisher'), [item]);

	const doUpdate = async () => {
		if (item) {
			setError(null);

			if (!commentRef.current?.value) {
				// Focus and highlight the input
				commentRef.current?.focus();
				commentRef.current?.select();
				commentRef.current?.setCustomValidity('Please enter a comment');
				return;
			}
			setSaving(true);

			// If products are updated, we need to update the product status
			if (updates.products) {
				const productStatus: Publication['product_status'] = (item as Publication).product_status || {};
				// Approve new products
				for (const p of updates.products) {
					if (!productStatus[p] || productStatus[p].status !== 'Approved') {
						productStatus[p] = {
							status: 'Approved',
							reviewed_by: userInfo?.$id,
							reviewed_at: unixNow(),
							comment: commentRef.current?.value,
						};
					}
				}
				// Reject products that were unselected
				for (const [p, pStatus] of Object.entries(productStatus)) {
					if (pStatus.status === 'Approved' && !updates.products?.includes(p)) {
						productStatus[p] = {
							status: 'Rejected',
							reviewed_by: userInfo?.$id,
							reviewed_at: unixNow(),
							comment: commentRef.current?.value,
						};
					}
				}
				(updates as Partial<Publication>).product_status = productStatus;
			}
			try {
				await api.updateItem<Content>(item, {
					$comment: commentRef.current?.value,
					...updates,
				});
				onClose(true);
			} catch (err) {
				console.error(err);
				setError(err);
			}
			setTimeout(() => {
				setSaving(false);
				onClose(true);
			}, 2000);
		}
	};

	return <Modal show={item && show} onHide={() => onClose(false)} size="xl" fullscreen="xl-down">
		<Modal.Header closeButton>
			<Modal.Title>Edit {typeName} <i>{item?.name}</i></Modal.Title>
			{ error && <Alert variant="danger">{error.message || String(error)}</Alert> }
		</Modal.Header>
		<Form onSubmit={(e) => {
			e.preventDefault();
			doUpdate();
		}}>
			<Modal.Body>
				<Container fluid className={saving ? 'opacity-50' : ''}>
					<Row className="mb-3">
						<EditableTextField
							typeName={typeName}
							propKey="name"
							item={item}
							updates={updates}
							setUpdates={setUpdates}
						/>
					</Row>
					<Row className="mb-3">
						<EditableTextField
							typeName={typeName}
							propKey="url"
							item={item}
							updates={updates}
							setUpdates={setUpdates}
						/>
					</Row>
					<Row className="mb-3">
						<EditableTextField
							typeName={typeName}
							propKey="description"
							item={item}
							updates={updates}
							setUpdates={setUpdates}
							multiLine
						/>
					</Row>
					<Row className="mb-3">
						<EditableSelectField
							typeName={typeName}
							propKey="status"
							item={item}
							updates={updates}
							setUpdates={setUpdates}
							options={STATUSES}
						/>
					</Row>
					{ isPublication(item) && <EditPublicationFields
						item={item}
						updates={updates as Partial<Publication>}
						setUpdates={setUpdates}
					/>}
					<Row className="mb-3">
						<EditableMultiValueField
							typeName={typeName}
							propKey="owner_emails"
							item={item}
							updates={updates}
							setUpdates={setUpdates}
							trimValues
						/>
					</Row>
					<Row className="mb-3">
						<EditableBooleanField
							typeName={typeName}
							propKey="closed_sites_only"
							item={item}
							updates={updates}
							setUpdates={setUpdates}
							details="If enabled, this content can only be delivered to 'Closed Site' distributors (no open web distribution)"
						/>
						<EditableBooleanField
							typeName={typeName}
							propKey="calculate_royalties"
							item={item}
							updates={updates}
							setUpdates={setUpdates}
							details="If enabled, this publication will be synced with NewsCrunch for royalty calculations"
						/>
					</Row>

					<Row className="mt-3 pt-3" style={{ borderTop: 'dashed 1px #ccc' }}>
						<Col md={3} className="text-end fw-bold">
							<Form.Label>Comment</Form.Label>
						</Col>
						<Col md={9}>
							<Form.Control type="text" name="$comment" ref={commentRef} required placeholder="Required: Enter a comment" />
						</Col>
					</Row>
				</Container>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={() => onClose(false)} disabled={saving}>
				Cancel
				</Button>
				<Button variant="primary" onClick={() => {
					doUpdate();
				}} disabled={saving || !updates || Object.keys(updates).length === 0}>
				Submit
				</Button>
			</Modal.Footer>
		</Form>

	</Modal>;
}

export function EditPublicationFields({
	item,
	updates,
	setUpdates,
}: {
	item: Publication,
	updates: Partial<Publication>,
	setUpdates: (updates: Partial<Publication>) => void,
}) {
	return <>
		<Row className="mb-3">
			<EditableSelectField
				typeName="Publication"
				propKey="content_status"
				item={item}
				updates={updates}
				setUpdates={setUpdates}
				options={PUBLICATION_STATUSES}
			/>
		</Row>

		<Row className="mb-3">
			<EditableMultiValueField
				typeName="Publication"
				propKey="xml_feed"
				item={item}
				updates={updates}
				setUpdates={setUpdates}
				trimValues
			/>
		</Row>
		<Row className="mb-3">
			<EditableSelectField
				typeName="Publication"
				propKey="content_type"
				item={item}
				updates={updates}
				setUpdates={setUpdates}
				options={PUBLICATION_TYPES}
			/>
		</Row>
		<Row className="mb-3">
			<EditableSelectField
				typeName="Publication"
				propKey="country"
				item={item}
				updates={updates}
				setUpdates={setUpdates}
				options={COUNTRY_OPTIONS}
			/>
		</Row>
		<Row className="mb-3">
			<EditableSelectField
				typeName="Publication"
				propKey="pub_country"
				item={item}
				updates={updates}
				setUpdates={setUpdates}
				options={COUNTRY_OPTIONS}
			/>
		</Row>
		<Row className="mb-3">
			<EditableDateField
				typeName="Publication"
				propKey="deletion_date"
				item={item}
				updates={updates}
				setUpdates={setUpdates}
			/>
		</Row>
		<Row className="mb-3 p-2 bg-danger-subtle">
			<div className="text-muted text-center mb-3">
				<FontAwesomeIcon icon={faExclamationTriangle} className="me-2" />
				WARNING: This is a dangerous feature intended for use by developers.
				<FontAwesomeIcon icon={faExclamationTriangle} className="ms-2" />
				<br/>
				DO NOT MODIFY THIS UNLESS YOU KNOW WHAT YOU ARE DOING.
			</div>

			<EditableMultiValueField
				typeName="Publication"
				propKey="norm_remove_tags"
				item={item}
				updates={updates}
				setUpdates={setUpdates}
			/>
		</Row>
		<Row className="mb-3">
			{item?.closed_sites_only && <Alert variant="warning" className="text-center">
				<FontAwesomeIcon icon={faLock} className="me-2" />
				WARNING: This publication is only allowed for CLOSED SITE DISTRIBUTION
				<FontAwesomeIcon icon={faLock} className="ms-2" />
			</Alert>}
			<ProductEditor products={item?.products} onChange={(products) => {
				const productIds = products.map((p) => p.$id);
				if (!arrayEqual(productIds, item?.products || [], true)) {
					setUpdates({
						...updates,
						products: productIds,
					});
				} else if (updates.products) {
					setUpdates(omit(updates, [
						'products',
						'product_status',
					]));
				}
			}} />
		</Row>
	</>;
}
