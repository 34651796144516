import { Publication } from '@newstex/types/content';
import { AccountInfoRelatedContent } from '@newstex/types/responses/info';
import type { RevenueAndEarningsForContact } from '@newstex/types/revenue';
import { useEffect, useState } from 'react';
import {
	Card,
	Col,
	Container,
	Row,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import BarChart, { BarChartProps } from '~/components/charts/BarChart';
import LoadingSpinner from '~/components/LoadingSpinner';
import MyPubs from '~/components/my-pubs';
import KPIStatsWidget from '~/components/Widgets/KPIStats';
import { formatDateYYYYMMDD } from '~/lib/utils';
import { useAPI } from '~/providers/api-provider';
import { useSearch } from '~/providers/search';
import { useUserInfo } from '~/providers/user-info';

export function RevenueChart({ data }: { data: RevenueAndEarningsForContact }) {
	const [stats, setStats] = useState<BarChartProps['datasets']>([]);
	const [dataLabels, setLabels] = useState<BarChartProps['labels']>([]);

	useEffect(() => {
		const datasets: BarChartProps['datasets'] = [];
		const periods: string[] = [];
		const today = new Date();
		today.setMonth(today.getMonth() - 4); // Start from 4 months ago

		for (let i = 0; i < 12; i++) {
			const year = today.getFullYear();
			const month = (today.getMonth() + 1).toString().padStart(2, '0');
			periods.unshift(`${year}-${month}`);
			today.setMonth(today.getMonth() - 1);
		}
		const earningsByClient: Record<string, Record<string, number>> = {};
		for (const row of data.earnings) {
			if (!earningsByClient[row.client]) {
				earningsByClient[row.client] = {};
			}

			if (!earningsByClient[row.client][row.period]) {
				earningsByClient[row.client][row.period] = 0;
			}
			earningsByClient[row.client][row.period] += row.earnings || 0;
		}
		for (const [clientName, clientData] of Object.entries(earningsByClient)) {
			datasets.push({
				label: clientName,
				data: periods.map((period) => clientData[period] || 0),
			});
		}
		setStats(datasets);
		setLabels(periods.map((name) => ({ name })));
	}, [data]);

	return (<>
		{stats.length > 0 && <BarChart
			labels={dataLabels}
			datasets={stats}
			period="month"
		/>}
	</>);
}

export function UserDashboard() {
	const userInfo = useUserInfo();
	const api = useAPI();
	const search = useSearch();
	const [loading, setLoading] = useState(true);
	const [revenue, setRevenue] = useState<RevenueAndEarningsForContact>();
	const [myPubs, setMyPubs] = useState<AccountInfoRelatedContent[]>([]);

	useEffect(() => {
		const fetchData = async () => {
			if (api) {
				const resp = await api.fetchWithAuth<RevenueAndEarningsForContact>('revenue');
				setRevenue(resp);
			}
			// Use the search client to fetch last post date stats for each publication
			const pubs = userInfo?.my_pubs || [];
			if (search.searchClient && pubs.length) {
				const searchResp = await search.searchClient.search<Publication>({
					indexName: 'Publication',
					q: '*',
					query_by: 'name',
					sort_by: 'last_post_date:desc',
					per_page: 100,
				});
				for (const hit of searchResp.hits) {
					if (hit.$id && hit.last_post_date) {
						const pub = pubs.find((item) => item.newscore_id === hit.$id);
						if (pub) {
							pub.last_post_date = formatDateYYYYMMDD(hit.last_post_date);
						}
					}
				}
			}
			setMyPubs(pubs.sort((a, b) => (b.last_post_date || '').localeCompare(a.last_post_date || '')));
			setLoading(false);
		};
		setLoading(true);
		fetchData();
	}, [api, search]);

	return (
		<Container fluid className="px-lg-4 px-xl-5">
			<div className="page-header d-flex justify-content-between align-items-center">
				<h1 className="page-heading">Activity Dashboard</h1>
			</div>
			{loading && <LoadingSpinner loading={loading} /> }
			{!loading && (<>
				<Row className="mb-3">
					<Col sm={6} lg={3} className="mb-4">
						<Card className="h-100 hover-animate">
							<Card.Body className="d-flex align-items-center justify-content-between">
								<div>
									<h4 className='fw-normal'>{revenue?.currentPeriod && (<>
										{new Date(revenue.currentPeriod).toLocaleDateString(undefined, { month: 'long', year: 'numeric' })}
									</>)}</h4>
									<div className="subtitle text-sm text-muted mb-0">Most recent payment period</div>
								</div>
								<Link to={`/revenue?period=${revenue?.currentPeriod}`} className="stretched-link" />
							</Card.Body>
						</Card>
					</Col>
					<Col sm={6} lg={3} className="mb-4">
						<KPIStatsWidget
							name="Total Lifetime Earnings"
							icon="dollar-badge-1"
							prefix="$"
							number={revenue?.totalLifetimeEarnings?.toFixed(2) || '0.00'}
							footer="Total lifetime earnings for your account"
							color="green"
							link="/revenue"
						/>
					</Col>
					<Col sm={6} lg={3} className="mb-4">
						<KPIStatsWidget
							name="Current Month Earnings"
							icon="dollar-sign-1"
							prefix="$"
							number={revenue?.currentMonthEarnings?.toFixed(2) || '0.00'}
							footer={`Revenue earned in ${revenue?.currentPeriod
								? new Date(revenue?.currentPeriod).toLocaleDateString(undefined, { month: 'long', year: 'numeric' })
								: 'the current month'}`
							}
							link={`/revenue?period=${revenue?.currentPeriod}`}
							color="green"
						/>
					</Col>
					<Col sm={6} lg={3} className="mb-4">
						<KPIStatsWidget
							name="Accrued Revenue"
							icon="money-box-1"
							prefix="$"
							number={revenue?.currentMonthPredisbursementAmount?.toFixed(2) || '0.00'}
							footer="Revenue that has been earned but not yet paid out"
							color="blue"
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						{revenue && <RevenueChart data={revenue} /> }
					</Col>
				</Row>
			</>)}
			<section className="mb-3 mb-lg-5">
				{userInfo?.my_pubs && (
					<MyPubs
						title="My Publications"
						pubs={myPubs}
					/>
				)}
			</section>
		</Container>
	);
}
