import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	ColumnDef,
	Row,
	SortingState,
	VisibilityState,
	flexRender,
	getCoreRowModel,
	getFilteredRowModel,
	getSortedRowModel,
	useReactTable,
} from '@tanstack/react-table';
import Case from 'case';
import { download, generateCsv, mkConfig } from 'export-to-csv';
import React, {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useState,
} from 'react';
import { Table } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';

export interface DataTableProps<T = any> {
	columns: ColumnDef<T, any>[];
	items: T[];
	nameAsc?: boolean;
	cellClassname?: string;
	defaultSort?: string;
	hideHeader?: boolean;
	striped?: boolean;
	defaultPageSize?: number;
	searchText?: string;
	tableRowClassNameHelper?: (row: Row<T>) => string;
	onSort?: (state: SortingState) => void;
	loading?: boolean;
	fallbackSearch?: (q: string) => Promise<T[]>,
	columnVisibility?: VisibilityState,
}

const SUPPORTED_CSV_TYPES = ['number', 'string', 'boolean', 'null', 'undefined'];

export interface DataTableMethods {
	exportToCSV: (filename?: string, exportConfig?: Record<string, string | ((item: any) => string)>) => void;
}

const DataTable = forwardRef(({
	columns,
	items,
	nameAsc,
	cellClassname,
	defaultSort,
	hideHeader,
	striped,
	defaultPageSize,
	searchText,
	tableRowClassNameHelper,
	onSort,
	loading,
	fallbackSearch,
	columnVisibility,
}: DataTableProps, ref) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [fallbackItems, setFallbackItems] = useState<any[]>([]);
	const data = React.useMemo(() => {
		if (fallbackItems.length) {
			return fallbackItems;
		}
		return items;
	}, [items, fallbackItems]);
	const initialSort: SortingState = [];
	if (searchParams.get('sort_by')) {
		const sortBy = searchParams.get('sort_by')!.split(':');
		initialSort.push({
			id: sortBy[0],
			desc: sortBy[1] === 'desc',
		});
	} else if (defaultSort) {
		initialSort.push({
			id: defaultSort,
			desc: !nameAsc,
		});
		console.log('initialSort', {
			initialSort,
			nameAsc,
		});
	}
	const [sorting, setSorting] = React.useState<SortingState>(initialSort);
	const table = useReactTable({
		columns,
		data,
		enableSorting: true,
		onSortingChange: (state) => {
			setSorting(state);
		},
		getFilteredRowModel: getFilteredRowModel(),
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		initialState: {
			sorting: [{
				id: defaultSort ?? 'name',
				desc: !nameAsc,
			}],
		},
		state: {
			sorting,
			columnVisibility,
		},
	});
	// Separate hook here because sorting can be modified in a few different ways
	useEffect(() => {
		if (onSort) {
			onSort(sorting);
		} else {
			// Set the query string parameters if no custom function was specified
			setSearchParams((params) => {
				if (sorting.length > 0) {
					params.set('sort_by', sorting.map((s) => `${s.id}:${s.desc ? 'desc' : 'asc'}`).join(','));
				} else {
					params.delete('sort_by');
				}
				return params;
			});
		}
	}, [sorting]);
	useEffect(() => {
		table.setPageSize(defaultPageSize ?? 10);
	}, [defaultPageSize]);
	useEffect(() => {
		setFallbackItems([]);
		if (searchText === '*') {
			table.setGlobalFilter('');
		} else {
			table.setGlobalFilter(searchText);
			// Give it a second to update the filtered results, and also to debounce the fallback search
			const timeout = setTimeout(() => {
				if (searchText && searchText.length > 3 && !table.getRowModel().rows.length && fallbackSearch) {
					fallbackSearch(searchText).then((filteredItems) => {
						console.log('fallbackSearch', filteredItems);
						setFallbackItems(() => {
							table.setGlobalFilter('');
							return filteredItems;
						});
					});
				}
			}, 1000);
			return () => clearTimeout(timeout);
		}
	}, [searchText]);

	const exportToCSV: DataTableMethods['exportToCSV'] = (filename, exportConfig) => {
		const csvConfig = mkConfig({
			filename: filename || 'Newstex-Report',
			fieldSeparator: ',',
			decimalSeparator: '.',
			quoteCharacter: '"',
			quoteStrings: true,
			useKeysAsHeaders: true,
		});

		// Convert items to a format that can be handled by export-to-csv
		const dataToExport = items.map((item) => {
			const exportData: Record<string, any> = {};
			if (exportConfig) {
				for (const [key, value] of Object.entries(exportConfig)) {
					if (typeof value === 'function') {
						exportData[key] = value(item);
					} else if (typeof value === 'string') {
						exportData[key] = item[value] || '';
					}

					if (Array.isArray(exportData[key])) {
						exportData[key] = exportData[key].join(';');
					}

					// If the value is an object, convert it to a string
					if (typeof exportData[key] === 'object') {
						console.warn('OBJECT DUMP', key, typeof exportData[key], exportData[key]);
						exportData[key] = JSON.stringify(exportData[key]);
					}

					// Anything other than a number, string, boolean, null, or undefined should be converted to a string
					if (!SUPPORTED_CSV_TYPES.includes(typeof exportData[key])) {
						exportData[key] = String(exportData[key]);
					}
				}
			} else {
				for (const column of columns as any[]) {
					if (column.header) {
						const accessor = column.accessorFn || column.accessorKey || column.id;
						const header = typeof column.header === 'string' ? column.header : column.id;
						if (header) {
							if (typeof accessor === 'function') {
								exportData[header] = accessor(item);
							} else if (typeof accessor === 'string') {
								exportData[header] = item[accessor];
							} else {
								console.warn('No accessor', accessor);
							}

							if (Array.isArray(exportData[header])) {
								exportData[header] = exportData[header].join(';');
							}

							// If the value is an object, convert it to a string
							if (typeof exportData[header] === 'object') {
								console.warn('OBJECT DUMP', header, typeof exportData[header], exportData[header]);
								exportData[header] = JSON.stringify(exportData[header]);
							}

							// Anything other than a number, string, boolean, null, or undefined should be converted to a string
							if (!SUPPORTED_CSV_TYPES.includes(typeof exportData[header])) {
								exportData[header] = String(exportData[header]);
							}
						}
					}
				}
			}
			return exportData;
		});

		// Generates the CSV string
		const csv = generateCsv(csvConfig)(dataToExport);

		// Triggers the download
		download(csvConfig)(csv);
	};

	useImperativeHandle(ref, () => ({
		exportToCSV,
	}));

	return (
		<React.Fragment>
			<Table
				hover
				className="align-middle mb-0"
				striped={striped}
				responsive
				style={{
					minHeight: '300px',
				}}
			>
				<thead className={hideHeader ? 'd-none' : ''}>
					{table.getHeaderGroups().map((headerGroup) => (
						<tr key={headerGroup.id}>
							{headerGroup.headers.map((header) => {
								return (
									<th
										colSpan={header.colSpan}
										rowSpan={header.rowSpan}
										style={{ width: `${header.getSize()}px` }}
										className={`py-4 prop-${Case.kebab(header.id)}`}
										key={header.id}
										onClick={header.column.getToggleSortingHandler()}
									>
										<span className="d-flex align-items-center text-transform-none ">
											{header.isPlaceholder ? null : flexRender(
												header.column.columnDef.header,
												header.getContext(),
											)}
											{header.column.columnDef.enableSorting ? (
												<span className="d-grid ms-auto">
													<FontAwesomeIcon
														icon={faCaretUp}
														className={`ms-1 ${
															header.column.getIsSorted() === 'asc' ? '' : 'opacity-6'
														}`}
														color={
															header.column.getIsSorted() === 'asc' ? '#f71' : '#999'
														}
														size="sm"
													/>
													<FontAwesomeIcon
														icon={faCaretDown}
														className={`ms-1 ${
															header.column.getIsSorted() === 'desc' ? '' : 'opacity-6'
														}`}
														color={
															header.column.getIsSorted() === 'desc' ? '#f71' : '#999'
														}
														size="sm"
													/>
												</span>
											) : null}
										</span>
									</th>
								);
							})}
						</tr>
					))}
				</thead>
				<tbody className={`position-relative border-top-0 ${loading ? 'opacity-50 loading' : ''}`} data-row-count={table.getRowModel().rows.length}>
					{table.getRowModel().rows.map((row) => (
						<tr id={`TABLE-ROW-${row.id}`} key={row.id} className={tableRowClassNameHelper?.(row) || ''}>
							{row.getVisibleCells().map((cell) => {
								return (
									<td
										className={cellClassname ?? 'prop-value'}
										key={cell.id}
										style={{ width: `${cell.column.getSize()}px` }}
									>
										{flexRender(cell.column.columnDef.cell, cell.getContext())}
									</td>
								);
							})}
						</tr>
					))}
				</tbody>
			</Table>
			{/*
			<div className="dataTable-bottom align-items-center d-flex">
				<div className="flex-shrink-0 mb-2 mb-md-0 me-auto">
          Showing page {pageIndex + 1} of {pageCount}
				</div>

				{pageOptions.length > 1 && (
					<DataTablePagination
						canPreviousPage={canPreviousPage}
						canNextPage={canNextPage}
						gotoPage={gotoPage}
						nextPage={nextPage}
						previousPage={previousPage}
						pageIndex={pageIndex}
						pageOptions={pageOptions}
						viewportWidth={size?.width}
					/>
				)}
			</div>
			*/}
		</React.Fragment>
	);
});
export default DataTable;
