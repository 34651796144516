import { faDownload, faPlus, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Publication } from '@newstex/types/content';
import { KnowledgeBase } from '@newstex/types/rag';
import { Results } from '@newstex/types/results';
import { Story } from '@newstex/types/story';
import { createColumnHelper } from '@tanstack/react-table';
import {
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import {
	Alert,
	Button,
	Card,
	Col,
	Container,
	Form,
	Modal,
	OverlayTrigger,
	ProgressBar,
	Row,
	Tooltip,
} from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import remarkGfm from 'remark-gfm';
import DataTable, { DataTableMethods } from '~/components/DataTable';
import PageHeader from '~/components/PageHeader';
import { PropertyDisplayValue } from '~/components/property-display-value';
import { formatDateString } from '~/lib/utils';
import { useAI } from '~/providers/ai-provider';
import { useAPI } from '~/providers/api-provider';
import { useSearch } from '~/providers/search';

export function AIQualifyPublicationsPage() {
	const [knowledgeBaseArticle, setKnowledgeBaseArticle] = useState<KnowledgeBase | null>(null);
	const [knowledgeBaseOptions, setKnowledgeBaseOptions] = useState<KnowledgeBase[]>([]);
	const [newstexIds, setNewstexIds] = useState<string[]>([]);
	const [running, setRunning] = useState(false);
	const [tableData, setTableData] = useState<(Publication & { score: number, reason: string })[]>([]);
	const { searchClient } = useSearch();
	const ai = useAI();
	const api = useAPI();
	const dataTableRef = useRef<DataTableMethods>(null);
	const abortController = useRef<AbortController>(new AbortController());
	const columnHelper = createColumnHelper<Publication & { score: number, reason: string }>();
	const [modalVisible, setModalVisible] = useState(true);
	const [progressMessage, setProgressMessage] = useState('');

	const columns = useMemo(() => [
		columnHelper.accessor('newstex_id', {
			header: 'Newstex ID',
			enableSorting: true,
			maxSize: 25,
			cell: ({ getValue, row: { original }}) => {
				if (!original) {
					return null;
				}
				return (
					<Link to={`/publications/${original.$id}`}>
						{getValue()}
					</Link>
				);
			},
		}),
		columnHelper.accessor('name', {
			header: 'Name',
			enableSorting: true,
			cell: ({ getValue, row: { original }}) => {
				if (!original) {
					return null;
				}
				return (
					<Link to={`/publications/${original.$id}`}>
						<strong>{getValue()}</strong>
					</Link>
				);
			},
		}),
		columnHelper.accessor('url', {
			header: 'Publication URL',
			enableSorting: true,
			cell: ({ getValue }) => (
				<PropertyDisplayValue
					propName="url"
					propValue={getValue()}
				/>
			),
		}),
		columnHelper.accessor('score', {
			header: 'Score',
			enableSorting: true,
			maxSize: 25,
			cell: ({ getValue, row: { original }}) => (
				<OverlayTrigger
					placement="left"
					overlay={
						<Tooltip className="card-tooltip" id={`score-tooltip-${original?.$id}`}>
							<Container>
								<Card className="info-card">
									<Card.Header>
										<Card.Title>
											[{original.newstex_id}]: {original.name}
										</Card.Title>
									</Card.Header>
									<Card.Body style={{ textAlign: 'left' }}>
										<ReactMarkdown remarkPlugins={[remarkGfm]}>
											{original.reason || 'No reason provided'}
										</ReactMarkdown>
									</Card.Body>
									<Card.Footer>
										<strong>Total Score: {getValue()}</strong>
									</Card.Footer>
								</Card>
							</Container>
						</Tooltip>
					}
				>
					<span>
						<PropertyDisplayValue
							propName="score"
							propValue={getValue()}
						/>
					</span>
				</OverlayTrigger>
			),
		}),
	], []);

	const searchKnowledgeBase = async (query: string) => {
		if (!searchClient) {
			toast.error('Search client not initialized');
			return;
		}

		const results = await searchClient.search({
			indexName: 'RAG',
			query,
			filter_by: 'category:"Qualification"',
		});

		if (results.hits.length === 0) {
			toast.warning('No matching knowledge base articles found');
			return;
		}

		setKnowledgeBaseOptions(results.hits);
	};

	const runQualification = async (page = 1) => {
		setProgressMessage('');
		if (!searchClient) {
			toast.error('Search client not initialized');
			return;
		}

		if (!knowledgeBaseArticle) {
			toast.error('Please select a knowledge base article first');
			return;
		}

		setRunning(true);

		if (abortController.current.signal.aborted) {
			return;
		}
		console.log('Fetching Publications for', newstexIds.length, 'IDs');
		const results = await searchClient.multiSearch([{
			indexName: 'Publication',
			query: '*',
			per_page: 100,
			page,
			filter_by: `newstex_id:[${newstexIds.join(',')}]`,
		}]);
		if (abortController.current.signal.aborted) {
			return;
		}

		for (const publication of results[0].hits || []) {
			if (abortController.current.signal.aborted) {
				return;
			}
			try {
				// Check our cache first
				const cacheKey = `ai-qualify-publication-${knowledgeBaseArticle.$id}-${publication.$id}`;
				const cachedResult = localStorage.getItem(cacheKey);
				if (cachedResult) {
					const { score, reason } = JSON.parse(cachedResult);
					setTableData((prev) => [...prev, {
						...publication,
						score,
						reason,
					}]);
				} else {
					console.log('Fetching Stories for', publication);
					setProgressMessage(`Qualifying ${publication.name}...`);
					const searchParams = new URLSearchParams({
						publication: publication.$id,
						limit: '5',
						sort: 'desc',
					});
					const stories = await api.fetchWithAuth<Results<Story>>(`resources/Story?${searchParams}`);

					if (abortController.current.signal.aborted) {
						return;
					}
					console.log('Stories', stories);
					setProgressMessage(`Qualifying ${publication.name}...`);
					const score = await ai.qualifyPublication({
						publication,
						criteria: knowledgeBaseArticle.answer || '',
						stories: stories.items,
					});

					if (abortController.current.signal.aborted) {
						return;
					}

					console.log('Qualification score', score);
					const maxScore = Math.max(...Object.values(score).map((s) => s?.score || 0));
					const maxReason = Object.values(score).find((s) => s?.score === maxScore)?.reason || '';
					setProgressMessage(`${publication.name} - ${maxScore}`);

					setTableData((prev) => [...prev, {
						...publication,
						score: maxScore,
						reason: maxReason,
					}]);
					localStorage.setItem(cacheKey, JSON.stringify(score));
				}
			} catch (e: any) {
				console.error({
					publication,
					error: e,
				});
				toast.error(`Error qualifying ${publication.name}: ${e instanceof Error ? e.message : 'Unknown error'}`);
			}
		}

		if (abortController.current.signal.aborted) {
			setProgressMessage('');
			return;
		}

		if (results[0].hits && results[0].hits.length === 100) {
			console.log('Qualifying next page...', results);
			setProgressMessage(`Qualifying page: ${page + 1}`);
			return runQualification(page + 1);
		}

		setProgressMessage('');
		setRunning(false);
	};

	const calculateProgress = () => {
		return Math.round((tableData.length / newstexIds.length) * 100);
	};

	useEffect(() => {
		abortController.current = new AbortController();
		searchKnowledgeBase('');
		return () => {
			abortController.current.abort();
		};
	}, [searchClient]);

	return (
		<Container fluid>
			<PageHeader
				title="AI Qualify Publications"
				info="This tool allows you to qualify publications based on a set of criteria.
				You can select a knowledge base article and enter a list of Newstex IDs to qualify.
				The tool will use AI to score each publication and provide a reason for the score."
			/>
			{!modalVisible && !running && (
				<Row className="mb-3">
					<Col className="text-center">
						<Button variant="outline-primary" size="sm" onClick={() => {
							setModalVisible(true);
						}}>
							<FontAwesomeIcon icon={faPlus} /> Add More
						</Button>
					</Col>
				</Row>
			)}
			<Modal
				show={modalVisible}
				size="xl"
				restoreFocus={true}
				onHide={() => {
					setModalVisible(false);
				}}
			>
				<Modal.Header closeButton>
					<Modal.Title>AI Qualify Publications for Distributors</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row className="mb-3">
						<Col>
							<Form.Group>
								<Form.Label>Search for AI Qualification Criteria</Form.Label>
								<Select
									options={knowledgeBaseOptions.map((kb) => ({
										label: kb.title || '',
										value: kb.$id || (kb as any).id || '',
									}))}
									value={knowledgeBaseArticle ? {
										label: knowledgeBaseArticle.title || '',
										value: knowledgeBaseArticle.$id || (knowledgeBaseArticle as any).id || '',
									} : null}
									onChange={(option) => {
										const kb = knowledgeBaseOptions.find((a) => a.$id === option?.value);
										setKnowledgeBaseArticle(kb || null);
									}}
									isDisabled={running}
									placeholder="Search for criteria..."
									onInputChange={(inputValue) => {
									// Implement debounced search here if needed
									// For now, we'll assume searchKnowledgeBase updates knowledgeBaseOptions
										searchKnowledgeBase(inputValue);
									}}
								/>
							</Form.Group>
						</Col>
					</Row>
					{knowledgeBaseArticle && (
						<>
							<Row className="mb-3">
								<Col>
									<Form.Group>
										<Form.Label>Enter Newstex IDs (one per line)</Form.Label>
										<Form.Control
											as="textarea"
											rows={newstexIds.length + 5}
											value={newstexIds.join('\n')}
											disabled={running}
											onChange={(e) => setNewstexIds(e.target.value.split('\n').filter(Boolean))}
										/>
									</Form.Group>
								</Col>
							</Row>
							<Row className="mb-3">
								<Col className="text-center">
									<Button
										variant="primary"
										onClick={() => {
											setModalVisible(false);
											runQualification(1);
										}}
										disabled={running || !knowledgeBaseArticle || newstexIds.length === 0}
									>
										{running ? (
											<>
												<FontAwesomeIcon icon={faSpinner} spin className="me-2" />
												Qualifying...
											</>
										) : (
											'Run AI Qualification'
										)}
									</Button>
								</Col>
							</Row>
						</>
					)}
				</Modal.Body>
			</Modal>

			{knowledgeBaseArticle && (
				<Row className="mb-3">
					<Col>
						<Alert variant="info">
							Selected criteria:&nbsp;
							<Link to={`/docs/kb/${knowledgeBaseArticle.$id}`} target="_blank">
								{knowledgeBaseArticle.title}
							</Link>
						</Alert>
					</Col>
				</Row>
			)}
			{running && (<>
				{progressMessage && (
					<Row className="mb-1">
						<Col className="text-center text-muted text-small">
							{progressMessage}
						</Col>
					</Row>
				)}
				<Row className="mb-3">
					<Col className="d-flex justify-content-center">
						<div style={{ width: '100%', maxWidth: '300px' }}>
							<ProgressBar
								min={0}
								max={newstexIds?.length || 0}
								now={tableData?.length || 0}
								label={`${tableData?.length || 0} / ${newstexIds?.length || 0} (${calculateProgress()}%)`}
								className="mb-3"
								animated
								striped
								style={{ height: '24px' }}
							/>
						</div>
					</Col>
				</Row>
			</>)}

			{(tableData.length > 0 || running) && (
				<Row className="mb-3">
					<Col>
						<DataTable
							ref={dataTableRef}
							columns={columns}
							items={tableData}
							defaultPageSize={1000}
							defaultSort="score"
							striped
						/>
						{running && (
							<Row className="mb-3">
								<Col className="text-center">
									<FontAwesomeIcon icon={faSpinner} spin className="me-2" />
									Qualifying... ({tableData.length}/{newstexIds.length})
								</Col>
							</Row>
						)}
						<div className="d-flex justify-content-center mt-3">
							<Button variant="outline-secondary" size="sm" onClick={() => {
								dataTableRef.current?.exportToCSV(`AI Qualified Publications - ${knowledgeBaseArticle?.title}`, {
									'Publisher Newstex ID': (original) => original.Publisher?.newstex_id || '',
									'Publisher Name': (original) => original.Publisher?.name || '',
									'Publication Newstex ID': (original) => original.newstex_id || '',
									'Publication Name': 'name',
									'Publication URL': 'url',
									'Publication Status': 'status',
									'Feed URL': 'feed_url',
									'Integration Completion Date': (original) => formatDateString(original.integration_completion_date),
									'Last Post Date': (original) => formatDateString(original.last_post_date),
									'AI Score': (original) => String(original.score || 'N/A'),
									'AI Reason': (original) => original.reason || '',
								});
							}}>
								<FontAwesomeIcon icon={faDownload} /> CSV
							</Button>
						</div>
					</Col>
				</Row>
			)}
		</Container>
	);
}
