import { UserError } from '@newstex/core/errors';
import { sleep } from '@newstex/core/sleep';
import { type TypedObject, getTypeName } from '@newstex/types/base';
import type { DetectFeedRequest, DetectFeedResponse } from '@newstex/types/detect-feed';
import { convertReferenceToString } from '@newstex/types/reference';
import type { GetHubSpotRecordsRequest } from '@newstex/types/requests/get-hubspot-records';
import type { GetMetricStatsRequest } from '@newstex/types/requests/get-metric-stats';
import type { GetHubSpotRecordsResponse } from '@newstex/types/responses/get-hubspot-records';
import type { GetMetricStatsResponse } from '@newstex/types/responses/get-metric-stats';
import { createContext, useContext } from 'react';
import { toast } from 'react-toastify';

import { Logout, UserType, useAuth } from './auth';

const DEFAULT_MAX_ATTEMPTS = 5;
const MAX_CONCURRENT_REQUESTS = 5;
let activeRequests = 0;
const requestQueue: (() => void)[] = [];

export interface RequestInitWithMaxAttempts extends RequestInit {
	maxAttempts?: number;
}

export interface APIContextType {
	fetchWithAuthRaw: (path: string, options?: RequestInitWithMaxAttempts) => Promise<Response>;
	fetchWithAuth: <T = any>(path: string, options?: RequestInitWithMaxAttempts) => Promise<T>;
	getMetricStats: (
		args: GetMetricStatsRequest,
		options?: RequestInitWithMaxAttempts,
	) => Promise<GetMetricStatsResponse>;
	getHubSpotRecords: (
		args: GetHubSpotRecordsRequest,
		options?: RequestInitWithMaxAttempts,
	) => Promise<GetHubSpotRecordsResponse>;
	updateItem: <T extends TypedObject>(
		item: T,
		updates: Partial<T>,
		options?: RequestInitWithMaxAttempts,
	) => Promise<T>;
	createItem: <T extends TypedObject>(item: T, options?: RequestInitWithMaxAttempts) => Promise<T>;
	getItem: <T extends TypedObject>(item: T, options?: RequestInitWithMaxAttempts) => Promise<T>;
	detect: (opts: DetectFeedRequest, options?: RequestInitWithMaxAttempts) => Promise<DetectFeedResponse>;
}

const CACHED_REQUESTS: Record<string, Promise<any>> = {};
const CACHE_TIMEOUT = 1000 * 60 * 5; // 5 minutes

// Create a Context for the API functions
const APIContext = createContext<APIContextType | undefined>(undefined);

export class AccessDeniedError extends Error {
	public statusCode = 403;

	constructor(message?: string) {
		super(message || 'Access Denied');
		this.name = 'AccessDeniedError';
	}
}

export class AbortError extends Error {
	public statusCode = 600;

	constructor(message?: string) {
		super(message || 'Aborted');
		this.name = 'AbortError';
	}
}

// Create a Provider Component
export function APIProvider({
	apiBaseUrl,
	children,
	getUserFunction,
}: React.PropsWithChildren<{ apiBaseUrl?: string, getUserFunction?: () => {
	user: UserType;
	runAs?: string;
}}>) {
	const { user, runAs } = getUserFunction ? getUserFunction() : useAuth();

	// Create a fetch function that includes the Authorization header
	const fetchWithAuthRaw = async (
		path: string,
		options?: RequestInitWithMaxAttempts,
		attempt = 0,
		toastId?: string | number,
	): Promise<Response> => {
		// Add queue handling
		if (activeRequests >= MAX_CONCURRENT_REQUESTS) {
			console.log('Queueing request', path);
			await new Promise<void>((resolve) => {
				requestQueue.push(resolve);
			});
			console.log('Request processed', path);
		}

		activeRequests++;

		try {
			// Allow using "Google" type for google auth via the extension
			const authHeader = [
				user?.type === 'google' ? 'Google' : 'Bearer',
				user?.sessionToken,
				runAs || '',
			].join(' ').trim();

			const url = `${apiBaseUrl || import.meta.env.VITE_API_URL}/${path}`;
			const response = await fetch(url, {
				...options,
				headers: {
					...options?.headers,
					Authorization: authHeader,
				},
			}).catch((e) => {
				if (e.name === 'AbortError') {
					console.log('AbortError', e);
					return {
						ok: false,
						status: 600,
						statusText: 'Aborted',
					} as Response;
				}
				console.log('API Error', e);
				return {
					ok: false,
					status: 500,
					statusText: e.message || e.msg || String(e),
				} as Response;
			});

			if (!response.ok) {
				console.warn(response);

				// Forbidden (Access Denied but we know who the user is)
				if (response.status === 403) {
					throw new AccessDeniedError();
				}

				// TODO: Make this show a warning rather than just forcably logging them out or throwing an
				// error that's not seen anywhere by the user.
				if (response.status === 403 || response.status === 401) {
					Logout();
				}

				if (response.status === 600) {
					throw new AbortError();
				}

				// Allow retrying the request
				if (
					response.status >= 500
					&& response.status < 600
					&& attempt < (options?.maxAttempts || DEFAULT_MAX_ATTEMPTS)
				) {
					const errMessage = `Temporary Error: ${response.statusText || response.status}`;
					if (toastId) {
						toast.update(toastId, {
							render: errMessage,
							isLoading: true,
						});
					} else {
						toastId = toast.error(errMessage);
					}

					await sleep(500 * attempt);
					return await fetchWithAuthRaw(path, options, attempt + 1);
				}
				let errResponse: any;
				let errMessage = response.statusText || response.status;
				try {
					errResponse = await response.json();
					if (errResponse.message) {
						errMessage = errResponse.message;
					}
				} catch (e) {
					console.error('Could not parse error message', e);
				}

				if (errResponse?.statusCode && errResponse?.__type__ === 'Error') {
					throw new UserError(errResponse);
				}

				throw new Error(`API request failed: ${errMessage}`);
			}

			if (toastId) {
				toast.dismiss(toastId);
			}

			return response;
		} finally {
			activeRequests--;
			if (requestQueue.length > 0) {
				const next = requestQueue.shift();
				next?.();
			}
		}
	};

	const fetchWithAuth = async <T = any>(path: string, options?: RequestInitWithMaxAttempts) => {
		if (!options) {
			if (CACHED_REQUESTS[path] == null) {
				CACHED_REQUESTS[path] = fetchWithAuthRaw(path, options).then((response) => {
					setTimeout(() => {
						delete CACHED_REQUESTS[path];
					}, CACHE_TIMEOUT);
					return response.json();
				});
			}
			return CACHED_REQUESTS[path];
		}
		const response = await fetchWithAuthRaw(path, options);
		return response.json() as Promise<T>;
	};

	const getMetricStats = async (
		args: GetMetricStatsRequest,
		options?: RequestInitWithMaxAttempts,
	) => {
		const params: Record<string, string> = {
			namespace: args.namespace,
			metricName: args.metricName,
		};
		if (args.startTime) {
			params.startTime = args.startTime;
		}

		if (args.endTime) {
			params.endTime = args.endTime;
		}

		if (args.dimensions) {
			params.dimensions = JSON.stringify(args.dimensions);
		}

		if (args.period) {
			params.period = args.period;
		}

		const url = `metrics/stats?${new URLSearchParams(params).toString()}`;
		return fetchWithAuth<GetMetricStatsResponse>(url, options);
	};

	const updateItem = async <T extends TypedObject>(
		item: T,
		updates: Partial<T>,
		options?: RequestInitWithMaxAttempts,
	) => {
		const url = `resources/${getTypeName(item)}/${convertReferenceToString(item)}`;
		return fetchWithAuth<T>(url, {
			...(options || {}),
			method: 'PATCH',
			body: JSON.stringify(updates),
		});
	};

	const createItem = async <T extends TypedObject>(
		item: T,
		options?: RequestInitWithMaxAttempts,
	) => {
		const url = `resources/${getTypeName(item)}`;
		return fetchWithAuth<T>(url, {
			...(options || {}),
			method: 'POST',
			body: JSON.stringify(item),
		});
	};

	const getItem = async <T extends TypedObject>(item: T, options?: RequestInitWithMaxAttempts) => {
		const url = `resources/${getTypeName(item)}/${convertReferenceToString(item)}`;
		const resp = await fetchWithAuth<T>(url, options);
		if (resp.items) {
			return resp.items[0];
		}
		return null;
	};

	const detect = async (opts: DetectFeedRequest, options?: RequestInitWithMaxAttempts) => {
		return fetchWithAuth<DetectFeedResponse>('detect', {
			...(options || {}),
			method: 'POST',
			body: JSON.stringify(opts),
		});
	};

	const getHubSpotRecords = async (
		args: GetHubSpotRecordsRequest,
		options?: RequestInitWithMaxAttempts,
	) => {
		const url = `hubspot/records/${args.type}/${args.id}`;
		const output: GetHubSpotRecordsResponse = {
			$type: 'Results',
			items: [],
		};
		let resp: GetHubSpotRecordsResponse = await fetchWithAuth<GetHubSpotRecordsResponse>(url, options);
		if (resp.items) {
			console.log('Initial', resp.items.length);
			output.items.push(...resp.items);
		}
		while (resp.nextToken) {
			await sleep(1000);
			resp = await fetchWithAuth<GetHubSpotRecordsResponse>(`${url}?nextToken=${resp.nextToken}`, options);
			if (resp.items) {
				console.log('Paged', resp.items.length);
				output.items.push(...resp.items);
			}
		}
		return output;
	};

	// Provide the fetch function
	return (
		<APIContext.Provider value={{
			fetchWithAuthRaw,
			fetchWithAuth,
			getMetricStats,
			getHubSpotRecords,
			updateItem,
			createItem,
			getItem,
			detect,
		}}>
			{children}
		</APIContext.Provider>
	);
}

// Create a custom hook to use the API context
export function useAPI() {
	const context = useContext(APIContext);
	if (context === undefined) {
		throw new Error('useAPI must be used within a APIProvider');
	}
	return context;
}
