import { QualificationScores } from '@newstex/ai/qualify';
import { DetectFeedResponse } from '@newstex/types/detect-feed';
import { HubSpotCompany } from '@newstex/types/hubspot';
import { createCompanyFromDetect } from '@newstex/types/hubspot/create-company-from-detect';
import { createContext, useContext } from 'react';
import { toast } from 'react-toastify';

import { useAnalytics } from './analytics-provider';
import { useAPI } from './api-provider';

interface HubSpotContextType {
	createCompany: (params: CreateCompanyParams) => Promise<HubSpotCompany>;
	updateCompany: (params: UpdateCompanyParams) => Promise<HubSpotCompany>;
}

interface CreateCompanyParams {
	lead: DetectFeedResponse;
	provenance?: HubSpotCompany['provenance'];
	tag?: string;
	note?: string;
	showScores?: boolean;
	aiRankings?: QualificationScores | null;
}

interface UpdateCompanyParams extends CreateCompanyParams {
	hsObjectID: string;
}

const HubSpotClientFieldNames: Record<string, keyof HubSpotCompany> = {
	ccc: 'ai_qualification_score__ccc',
	cla: 'ai_qualification_score__cla',
	industrydive: 'ai_qualification_score__industry_dive',
	lexisnexis: 'ai_qualification_score__lexisnexis',
	moodys: 'ai_qualification_score__moody_s',
	proquest: 'ai_qualification_score__proquest',
	refinitiv: 'ai_qualification_score__refinitiv',
	syndigate: 'ai_qualification_score__syndigate',
	thomsonwestlaw: 'ai_qualification_score__thomson_westlaw',
	thomson: 'ai_qualification_score__thomson_westlaw',
};

function getHubSpotFieldName(client: string): keyof HubSpotCompany {
	const cleanedClient = client.replace(/[^a-z]/gi, '').toLowerCase();
	if (HubSpotClientFieldNames[cleanedClient]) {
		return HubSpotClientFieldNames[cleanedClient];
	}
	return `ai_qualification_score__${cleanedClient}` as keyof HubSpotCompany;
}

const HubSpotContext = createContext<HubSpotContextType | undefined>(undefined);

export function HubSpotProvider({ children }: React.PropsWithChildren) {
	const api = useAPI();
	const analytics = useAnalytics();

	const createCompany = async ({
		lead,
		provenance,
		tag,
		note,
		showScores,
		aiRankings,
	}: CreateCompanyParams): Promise<HubSpotCompany> => {
		const toastNotification = toast.loading(`Creating new HubSpot Company: ${lead.url}`, {
			closeButton: true,
			isLoading: true,
		});

		try {
			const hsCompany = createCompanyFromDetect(lead);

			if (showScores && aiRankings) {
				for (const [client, score] of Object.entries(aiRankings)) {
					hsCompany[getHubSpotFieldName(client)] = score.score;
				}
			}

			const hsResp: any = await api.createItem<Partial<HubSpotCompany> & {
				$type: 'HubSpotCompany';
				articles: DetectFeedResponse['articles'];
				$note?: string;
			}>({
				$type: 'HubSpotCompany',
				...hsCompany,
				articles: lead.articles,
				provenance,
				newscore_tag: tag,
				$note: note,
			});

			analytics?.trackEvent('Create HubSpot Company', {
				id: hsResp?.hs_object_id,
				url: lead.url,
				tag,
				provenance,
			});

			const hsObjectID = hsResp?.hs_object_id || hsResp?.id;
			if (hsObjectID) {
				toast.update(toastNotification, {
					render: `Created new HubSpot Company: ${lead.url}`,
					type: 'success',
					isLoading: false,
					autoClose: 15000,
					onClick: () => {
						window.open(`https://app.hubspot.com/contacts/20613241/record/0-2/${hsObjectID}`, '_blank');
					},
				});
				return {
					$id: hsObjectID,
					hs_object_id: hsObjectID,
					...hsResp,
				};
			}

			throw new Error(hsResp?.error || hsResp?.message || 'Unknown error creating company');
		} catch (err) {
			console.error('Failed to create HubSpot Company', lead.url, err);
			toast.update(toastNotification, {
				render: `Failed to create HubSpot Company: ${lead.url} (${err instanceof Error ? err.message : String(err)})`,
				type: 'error',
				isLoading: false,
				onClick: () => {
					const errorObj = {
						url: lead.url,
						error: err,
					};
					navigator.clipboard.writeText(JSON.stringify(errorObj, null, 2));
				},
			});
			throw err;
		}
	};

	const updateCompany = async ({
		lead,
		hsObjectID,
		provenance,
		tag,
		note,
		showScores,
		aiRankings,
	}: UpdateCompanyParams): Promise<HubSpotCompany> => {
		const toastNotification = toast.loading(`Updating record ID ${hsObjectID}...`, {
			closeButton: true,
			isLoading: true,
			onClick: () => {
				window.open(`https://app.hubspot.com/contacts/20613241/record/0-2/${hsObjectID}`, '_blank');
			},
		});

		try {
			analytics?.trackEvent('Update HubSpot Company', {
				id: hsObjectID,
				url: lead.url,
				tag,
				provenance,
			});

			const metadataSources = ['NewsCore'];
			const updates: Partial<HubSpotCompany & { $note: string }> = {
				ai_check_date: new Date().toISOString().split('T')[0],
				ai_qualified: lead.qualified ? 'true' : 'false',
				ai_headline_score: lead.headline_score,
				ai_story_categories: lead.newstex_categories
					? Object.entries(lead.newstex_categories)
						.filter(([, value]) => value > 10)
						.map(([key]) => key)
						.join(';') as any : '' as any,
				ai_rejection_reason: lead.rejection_reasons?.join(';') || '',
				ai_qualification_score: Math.max(...(Object.values(aiRankings ?? {}).map((r) => r.score) || [0])),
				metadata_sources: metadataSources as any,
				newscore_tag: tag,
				$note: note,
			};

			if (showScores && aiRankings) {
				for (const [client, score] of Object.entries(aiRankings)) {
					updates[getHubSpotFieldName(client)] = score.score;
				}
			}

			const hsResp: any = await api.updateItem<Partial<HubSpotCompany> & {
				$type: 'HubSpotCompany';
				$id: string;
				$note?: string;
			}>({
				$type: 'HubSpotCompany',
				$id: hsObjectID,
			}, updates);

			toast.update(toastNotification, {
				render: `Updated record ID ${hsObjectID}`,
				type: 'success',
				isLoading: false,
				autoClose: 5000,
			});

			return hsResp;
		} catch (err) {
			toast.update(toastNotification, {
				render: `Failed to update HubSpot Company: ${lead.url} (${err instanceof Error ? err.message : String(err)})`,
				type: 'error',
				isLoading: false,
				onClick: () => {
					const errorObj = {
						url: lead.url,
						error: err,
					};
					navigator.clipboard.writeText(JSON.stringify(errorObj, null, 2));
				},
			});
			throw err;
		}
	};

	return (
		<HubSpotContext.Provider value={{
			createCompany,
			updateCompany,
		}}>
			{children}
		</HubSpotContext.Provider>
	);
}

export function useHubSpot() {
	const context = useContext(HubSpotContext);
	if (context === undefined) {
		throw new Error('useHubSpot must be used within a HubSpotProvider');
	}
	return context;
}
