import { faBug, faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Feed } from '@newstex/types/feed';
import { createColumnHelper } from '@tanstack/react-table';
import { useMemo, useRef } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import DataTable, { DataTableMethods } from '~/components/DataTable';
import { headerWithTooltip } from '~/components/header-with-tooltip';
import { PropertyDisplayValue } from '~/components/property-display-value';

import { LoadMoreButton } from '../load-more-button';

export function FeedsTable(
	{
		items,
		searchText,
		hasMore,
		onLoadMore,
		onShowModal,
	}: {
		items: Feed[],
		searchText?: string,
		hasMore?: boolean,
		onLoadMore?: () => void,
		onShowModal?: (url: string) => void,
	},
) {
	const dataTableRef = useRef<DataTableMethods>(null);
	const columnHelper = createColumnHelper<Feed>();
	const columns = useMemo(() => [
		columnHelper.accessor('name', {
			header: 'Name/URL',
			enableSorting: true,
			cell: ({ getValue, row: { original }}) => {
				return <>
					<Link to={`/feeds/${original.$id}`}>
						<PropertyDisplayValue propName="name" propValue={getValue()} />
					</Link>
					<br />
					<small>
						<PropertyDisplayValue
							propName="url"
							propValue={original.feed_uri}
							maxLength={100}
							className="text-muted"
						/>
					</small>
				</>;
			},
		}),
		columnHelper.accessor('priority', {
			header: 'Priority',
			enableSorting: true,
			cell: ({ getValue, row: { original }}) => {
				return <>
					<PropertyDisplayValue propName="priority" propValue={getValue() || 'standard'} />
					{original.segment === -3 && <div className="text-danger fst-italic fw-light"> (Chrome Extension)</div>}
					{original.segment === -2 && <div className="text-muted fst-italic fw-light"> (Dev)</div>}
					{original.segment === -1 && <div className="text-muted fst-italic fw-light"></div>}
				</>;
			},
		}),
		columnHelper.accessor('status', {
			header: 'Status',
			enableSorting: true,
			cell: ({ getValue }) => {
				return <PropertyDisplayValue propName="status" propValue={getValue()} />;
			},
		}),
		columnHelper.accessor('last_read', {
			header: headerWithTooltip({
				title: 'Last Read',
				tooltip: 'Last time the feed was checked for new stories.',
			}),
			enableSorting: true,
			cell: ({ getValue }) => {
				return <PropertyDisplayValue propName="last_read" propValue={getValue()} />;
			},
		}),
		columnHelper.accessor('last_post_date', {
			header: headerWithTooltip({
				title: 'Last Published',
				tooltip: 'Most recent publication date for all stories in this feed.',
			}),
			enableSorting: true,
			cell: ({ getValue }) => {
				return <PropertyDisplayValue propName="last_post_date" propValue={getValue()} />;
			},
		}),
		columnHelper.accessor('last_received_date', {
			header: headerWithTooltip({
				title: 'Last Story Received',
				tooltip: 'When we last received a story from this feed.',
			}),
			enableSorting: true,
			cell: ({ getValue }) => {
				return <PropertyDisplayValue propName="last_received_date" propValue={getValue()} />;
			},
		}),
		columnHelper.accessor('last_error', {
			id: 'last_error.time',
			header: 'Last Error',
			enableSorting: true,
			maxSize: 240,
			cell: ({ getValue, row: { original }, cell }) => {
				return <div style={{ maxWidth: '240px' }}>
					{original.error_task_url && <span className="float-end">
						{onShowModal ? <Button
							title="View Bug Report"
							variant="outline-secondary"
							size="sm"
							onClick={() => onShowModal(original.error_task_url!)}
						>
							<FontAwesomeIcon icon={faBug} />
						</Button> : <PropertyDisplayValue
							propName="error_task_url"
							propValue={original.error_task_url}
						/>}
					</span>}
					<div style={{ maxWidth: '200px' }}>
						<PropertyDisplayValue propName="last_error" propValue={getValue()} />
					</div>
				</div>;
			},
		}),
	], [items]);

	return (<>
		<DataTable
			columns={columns}
			items={items || []}
			ref={dataTableRef}
			searchText={searchText}
			tableRowClassNameHelper={({ original }: { original: Feed }) => {
				if (original.status === 'Active') {
					return 'table-success';
				}

				if (original.status === 'Inactive') {
					return 'table-danger';
				}

				return '';
			}}
			nameAsc
			striped
		/>
		<LoadMoreButton show={hasMore} onClick={onLoadMore} />
		<div className="float-end pb-2">
			<Button variant="outline-secondary" size="sm" onClick={() => {
				dataTableRef.current?.exportToCSV('Feeds', {
					Name: 'name',
					URL: 'feed_uri',
					Priority: 'priority',
					Status: 'status',
					'Last Read': (row) => (row.last_read ? new Date(row.last_read * 1000).toLocaleString() : ''),
					'Last Published': (row) => (row.last_post_date ? new Date(row.last_post_date * 1000).toLocaleString() : ''),
					'Last Story Received': (row) => (row.last_received_date ? new Date(row.last_received_date * 1000).toLocaleString() : ''),
					'Last Error': 'last_error',
				});
			}}>
				<FontAwesomeIcon icon={faDownload} /> CSV
			</Button>
		</div>
	</>);
}
