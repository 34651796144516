import type { User } from '@newstex/types';
import type { StatusCode } from 'hono/utils/http-status';

import { version } from './version';

export class UserError extends Error {
	/**
	 * Additional debugging details
	 */
	details?: string;

	/**
	 * HTTP Status Code
	 */
	statusCode: StatusCode;

	/**
	 * Non-numeric code
	 */
	code?: string;

	/**
	 * If this error is retryable
	 */
	retryable?: boolean;

	/**
	 * Optional AWS Request ID
	 *
	 * LOG THIS to trace backend events
	 */
	aws_request_id?: string;

	/**
	 * Optional user that triggered this error
	 */
	user?: User;

	constructor(options: Partial<UserError>) {
		super(options.message);
		for (const [k, v] of Object.entries(options)) {
			this[k] = v;
		}

		if (options.retryable === undefined) {
			this.retryable = this.statusCode >= 500;
		}
	}

	toResponse() {
		return {
			__type__: 'Error',
			message: this.message,
			details: this.details,
			code: this.code,
			statusCode: this.statusCode,
			retryable: this.retryable,
			aws_request_id: this.aws_request_id,
			api_version: version,
		};
	}

	toJSON() {
		return JSON.stringify(this.toResponse());
	}
}

export class NotFoundError extends UserError {
	constructor(options: Omit<Partial<UserError>, 'statusCode' | 'retryable'>) {
		super({
			message: options.message || 'Not Found',
			...options,
			statusCode: 404,
			retryable: false,
		});
	}
}
