/**
 * Sync to HubSpot Button
 */
import { faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Content, isPublication } from '@newstex/types/content';
import { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useAPI } from '~/providers/api-provider';
import { useUserInfo } from '~/providers/user-info';

export interface SyncToHubSpotButtonProps {
	item?: Content,
	disabled?: boolean,
	size?: 'sm' | 'lg';
	onSync?: () => void;
}

export function requireForceSync(item: Content) {
	if (item.hubspot_id) {
		return false;
	}

	if (item.status === 'Lead') {
		return true;
	}

	if (isPublication(item)) {
		// Non-active non-blog
		if (item.status === 'Inactive' && item.content_type !== 'Weblog') {
			return true;
		}

		// Publication missing content_status or content_type
		if (!item.content_status || !item.content_type) {
			return true;
		}
	}

	return false;
}

export function SyncToHubSpotButton({
	item,
	disabled,
	size,
	onSync,
}: SyncToHubSpotButtonProps) {
	const userInfo = useUserInfo();
	if (!userInfo?.auth_groups?.includes('admin')) {
		return null;
	}
	const [saving, setSaving] = useState(false);
	const [showForceSync, setShowForceSync] = useState(false);
	const [parentNotSynced, setParentNotSynced] = useState(false);
	const api = useAPI();

	const syncToHubSpot = async (force = false) => {
		if (!item) {
			return;
		}

		if (requireForceSync(item) && !force) {
			setShowForceSync(true);
			return;
		}

		// Make sure parent is sync'd
		if (item.parent) {
			const parentResp = await api.fetchWithAuth(`resources/Publisher/${item.parent}`, { cache: 'reload' });
			if (!parentResp.items?.[0].hubspot_id) {
				setParentNotSynced(true);
				return;
			}
		}

		setSaving(true);
		setShowForceSync(false);

		console.log('Sync to HubSpot', item);
		try {
			const resp = await api.fetchWithAuthRaw(`resources/${item.$type}/${item.$id}/syncToHubspot`, {
				method: 'POST',
				...(force ? { body: JSON.stringify({ force: true }) } : {}),
			});
			if (resp.ok) {
				console.log('Saved to HubSpot', resp);
				toast.success('Synced to HubSpot');
			}
		} catch (err) {
			console.error(err);
			toast.error('Error syncing to HubSpot', {
				autoClose: false,
				closeOnClick: false,
			});
		} finally {
			setSaving(false);
			onSync?.();
		}
	};

	useEffect(() => {
		setShowForceSync(false);
		setSaving(false);
		setParentNotSynced(false);
	}, [item]);

	return (
		<>
			{showForceSync && item && <Modal show={showForceSync} onHide={() => setShowForceSync(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Force Sync to HubSpot?</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div>Normally this record would not be sync'd because</div>
					<ul>
						{item.status === 'Lead' && <li>It is marked as a Lead</li>}
						{isPublication(item) && item.status === 'Inactive' && item.content_type !== 'Weblog' && <li>It is marked as Inactive and is not a blog.</li>}
						{isPublication(item) && !item.content_status && <li>It is a Publication missing content_status.</li>}
						{isPublication(item) && !item.content_type && <li>It is a Publication missing content_type.</li>}
						<li>It has not been sync'd to HubSpot before</li>
					</ul>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => setShowForceSync(false)}>Cancel</Button>
					<Button variant="primary" onClick={() => syncToHubSpot(true)}>Force Sync</Button>
				</Modal.Footer>
			</Modal>}
			{parentNotSynced && item && <Modal show={parentNotSynced} onHide={() => setParentNotSynced(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Parent Content Not Sync'd to HubSpot</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div>This record can not be sync'd to HubSpot because the parent record is not sync'd</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => setParentNotSynced(false)}>Close</Button>
				</Modal.Footer>
			</Modal>}
			<Button
				size={size || 'sm'}
				variant="outline-secondary"
				disabled={disabled || saving}
				title="Sync to HubSpot"
				onClick={() => syncToHubSpot(false)}>
				<FontAwesomeIcon icon={faCloudArrowUp} />
				&nbsp;
				{saving ? 'Syncing...' : 'Sync to HubSpot'}
			</Button>
		</>
	);
}
