import { BaseObject } from './base';

export interface KnowledgeBase extends BaseObject {
	$type: 'RAG';
	title?: string;
	questions?: string[];
	answer?: string;
	category?: string;
}

export function isKnowledgeBase(obj: any): obj is KnowledgeBase {
	return obj && obj.$type === 'RAG';
}

export interface RAGMemory extends BaseObject {
	$type: 'RAGMemory';
	content?: string;
	link?: string;
	category?: string;
}

export function isRAGMemory(obj: any): obj is RAGMemory {
	return obj && obj.$type === 'RAGMemory';
}
