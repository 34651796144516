import { IconDefinition, faTable } from '@fortawesome/free-solid-svg-icons';
import { convertToDate, now } from '@newstex/core/date';
import type {
	SearchClient,
	SearchHit,
	SearchIndexName,
	SearchResults,
} from '@newstex/search';
import { Publication } from '@newstex/types/index';
import { title as titleCase } from 'case';
import omit from 'object.omit';
import {
	type JSXElementConstructor,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import {
	Button,
	ButtonGroup,
	Col,
	Container,
	Row,
	Table,
} from 'react-bootstrap';
import { Loader } from 'react-bootstrap-typeahead';
import { Link, useSearchParams } from 'react-router-dom';
import { useSearch } from '~/providers/search';

import { ActiveFilterBadge } from './active-filter-badge';
import { DisplayModeButton } from './display-mode-button';
import LoadingSpinner from './LoadingSpinner';
import { PageTitle } from './page-title';
import { PropertyDisplayValue } from './property-display-value';
import { SearchBox } from './search-box';
import { SearchCountTimeline } from './search-count-timeline';
import { SimpleFacetMenu, SimpleFacetMenuOption } from './simple-facet-menu';

interface SearchFacetConfigBase {
	attribute: string;
	title: string;
	options?: SimpleFacetMenuOption[];
	type?: string;
	showMore?: boolean;
	sortBy?: string[] | ((a: any, b: any) => number);
	searchable?: boolean;
}

export interface SearchFacetConfigList extends SearchFacetConfigBase {
	type?: 'List';
}

export interface SearchFacetConfigMenu extends SearchFacetConfigBase {
	type: 'Menu';
}

export interface SearchFacetConfigDate extends SearchFacetConfigBase {
	type: 'Date';
}

export type SearchFacetConfig = SearchFacetConfigList | SearchFacetConfigMenu | SearchFacetConfigDate;

const today = now();

function getFacetFilterString(key: string, values: string[], negated: boolean = false) {
	// Split comma-separated values
	const valueArray = values.flatMap((v) => v.split(','));

	// Boolean Values
	if (valueArray[0] === 'true' || valueArray[0] === 'false') {
		return `${key}:${negated ? '!=' : '='}${valueArray[0]}`;
	}

	// Date/Received At handling
	if (key === 'date' || key === 'received_at') {
		if (valueArray.length === 2) {
			return `${key}:${negated ? '!' : ''}=[${valueArray[0]}...${valueArray[1]}]`;
		}

		if (valueArray.length === 1) {
			if (valueArray[0].startsWith('[')) {
				return `${key}:${negated ? '!' : ''}${valueArray[0]}`;
			}
			return `${key}:${negated ? '<' : '>='}${valueArray[0]}`;
		}
	}

	// Segment handling
	if (key === 'segment') {
		if (valueArray.length === 2) {
			return `${key}:${negated ? '!' : ''}=[${valueArray[0]}...${valueArray[1]}]`;
		}

		if (valueArray.length === 1) {
			return `${key}:${negated ? '!=' : '='}${valueArray[0]}`;
		}
	}

	return `${key}:${negated ? '!' : ''}=[${valueArray.map((value) => `\`${value}\``).join(',')}]`;
}

function getFilterString(filters?: Record<string, string[]>) {
	if (!filters) {
		return;
	}
	return Object.entries(filters)
		.filter(([key]) => key !== 'sort_by' && key !== 'page' && key !== 'mode')
		.map(([key, values]) => {
			const isNegated = key.startsWith('!');
			const actualKey = isNegated ? key.slice(1) : key;
			return getFacetFilterString(actualKey, values, isNegated);
		})
		.join(' && ');
}

interface SearchFacetProps {
	facet: SearchFacetConfig;
	results?: SearchResults<any>;
}

function SearchFacet({ facet, results }: SearchFacetProps) {
	if (facet.type === 'Date') {
		return <SimpleFacetMenu
			key={facet.attribute}
			title={facet.title}
			prop={facet.attribute}
			options={[
				{ value: '', label: 'All time' },
				{ value: `${today.add(-1, 'day').unix()}`, label: 'Last 24 hours' },
				{ value: `${today.add(-7, 'day').unix()}`, label: 'Last 7 days' },
				{ value: `${today.add(-30, 'day').unix()}`, label: 'Last 30 days' },
			]}
			supportNegation={true}
		/>;
	}
	// Get any negated values from search params to ensure they show up in options
	const [searchParams] = useSearchParams();
	const negatedValue = searchParams.get(`!${facet.attribute}`);
	const searchResult = useMemo(() => {
		const result = results?.facets?.[facet.attribute]?.values;
		if (negatedValue && result) {
			// If we have a negated value but it's not in the facet results,
			// add it to the options with count of 0 to keep it visible
			if (!result[negatedValue]) {
				result[negatedValue] = 0;
			}
		}
		return result;
	}, [results?.facets?.[facet.attribute]?.values, negatedValue]);

	return <SimpleFacetMenu
		key={facet.attribute}
		title={facet.title}
		prop={facet.attribute}
		searchResult={searchResult}
		options={facet.options}
		searchable={facet.searchable}
		supportNegation={true}
	/>;
}

export interface GroupByOption {
	id: string;
	icon: IconDefinition;
	label?: string;
	sort?: string;
}

export interface SearchWithFacetsArguments<T = any> {
	indexName: SearchIndexName;
	title: string;
	actions?: React.ReactNode;
	queryBy?: string[];
	info?: string;
	defaultSort?: string;
	resultsTable: JSXElementConstructor<{
		items: SearchHit<T>[],
		searchText?: string,
		hasMore?: boolean,
		onLoadMore?: () => void,
	}>;
	groupTable?: JSXElementConstructor<{
		groupedHits: SearchResults<T>['grouped_hits'],
	}>;
	facets: SearchFacetConfig[];
	filters?: Record<string, string[]>;
	sortBy?: string[];
	/**
	 * Facet Key => Facet Name
	 */
	facetStats?: Record<string, string>;
	/**
	 * Facet to use for a sparkline timeline facet
	 */
	timelineFacet?: string;

	/**
	 * Optional include_fields to pass to the search client
	 */
	includeFields?: string;
	/**
	 * Fallback search function if there are no results
	 */
	fallbackSearch?: (q: string) => Promise<T[]>;

	/**
	 * Grouping options
	 */
	groupByOptions?: GroupByOption[];
}

function FacetStats(props: Pick<SearchWithFacetsArguments<any>, 'facetStats'> & { results?: SearchResults<any> }) {
	return (
		<>
			{Object.entries(props.facetStats || {}).map(([key, name]) => (
				<span className="facet-stat ms-2" key={`facet-stat${key}`}>
					<b>{name}</b>:&nbsp;
					{convertToDate(props.results?.facets?.[key]?.stats?.min).format('YYYY-MM-DD')}
					<i> to </i>
					{convertToDate(props.results?.facets?.[key]?.stats?.max).format('YYYY-MM-DD')}
				</span>
			))}
		</>
	);
}

function NoResults({
	fallbackSearch,
	title,
	loading,
}: {
	fallbackSearch?: (q: string) => Promise<any[]>,
	title?: string,
	loading?: boolean,
}) {
	const [searchParams] = useSearchParams();
	const [fallbackSearchPerformed, setFallbackSearchPerformed] = useState(false);

	return (
		<div className="text-muted p-4">
			{loading ? <LoadingSpinner loading={loading} /> : (
				<center>
					<div>
						No results for <i>{searchParams.get('q') || title || ''}</i>
					</div>
					{!fallbackSearchPerformed && fallbackSearch && searchParams.get('q') && (
						<Button variant="outline-secondary" className="mt-2" onClick={() => {
							setFallbackSearchPerformed(true);
							fallbackSearch(searchParams.get('q')!);
						}}>Perform Deep Search</Button>
					)}
				</center>
			)}
		</div>
	);
}

function SearchHitsTable<T = any>({
	resultsTable: ResultsTableComponent,
	groupTable: GroupTableComponent,
	results,
	hasMore,
	onLoadMore,
	loading,
}: {
	resultsTable: JSXElementConstructor<{
		items: SearchHit<T>[],
		searchText?: string,
		hasMore?: boolean,
		onLoadMore?: () => void,
		loading?: boolean,
	}>,
	groupTable?: JSXElementConstructor<{
		groupedHits: SearchResults<T>['grouped_hits'],
	}>,
	results: SearchResults<T>,
	hasMore?: boolean,
	onLoadMore?: () => void,
	loading?: boolean,
}) {
	const [searchParams] = useSearchParams();
	if (!results?.hits) {
		if (loading) {
			return <LoadingSpinner loading={loading} />;
		}

		if (results?.grouped_hits) {
			if (GroupTableComponent) {
				return <GroupTableComponent
					groupedHits={results.grouped_hits}
				/>;
			}

			return (
				<Table>
					<thead>
						<tr>
							<th>{titleCase(searchParams.get('group_by') || 'group')}</th>
							<th>Count</th>
						</tr>
					</thead>
					<tbody>
						{results.grouped_hits.map((group) => (
							<tr key={group.group_key.join('-')}>
								<td>{<PropertyDisplayValue propName={searchParams.get('group_by') || 'group'} propValue={group.group_key[0]} />}</td>
								<td><Link to={`?${new URLSearchParams({
									...omit(Object.fromEntries(searchParams.entries()), ['group_by', 'sort_by', 'page']),
									[searchParams.get('group_by') || 'group']: group.group_key[0],
								})}`}>{group.found}</Link></td>
							</tr>
						))}
					</tbody>
				</Table>
			);
		}

		return <></>;
	}
	return <ResultsTableComponent
		items={results.hits}
		loading={loading}
		hasMore={hasMore}
		onLoadMore={onLoadMore}
	/>;
}
function Stats({ results }: { results?: SearchResults<any> }) {
	return <small>found {results?.found?.toLocaleString() || '0'} results{results?.search_time_ms && ` in ${results.search_time_ms}ms`}</small>;
}

function ActiveFilters({ facets }: { facets: SearchFacetConfig[] }) {
	const search = useSearch();
	const [searchParams] = useSearchParams();
	const [activeFilters, setActiveFilters] = useState<{ key: string; value: string; label?: string }[]>([]);
	const facetAttributes = new Set(facets.map((f) => f.attribute));

	useEffect(() => {
		const setLabels = async () => {
			const currentFilters: { key: string; value: string; label?: string }[] = [];
			// Collect all active filters that aren't in the facets list
			for (const [key, value] of searchParams.entries()) {
				if (
					key !== 'q'
					&& key !== 'mode'
					&& key !== 'sort_by'
					&& key !== 'page'
					&& key !== 'group_by'
					&& !facetAttributes.has(key.replace('!', ''))
				) {
					if (key.replace('!', '') === 'publication') {
						console.log('Lookup Publication Names', value);
						const resp = await search.searchClient?.multiSearch<[Publication]>([{
							indexName: 'Publication',
							query: '',
							filter_by: `id:[${value}]`,
						}]);

						if (resp?.[0]?.hits?.length) {
							for (const hit of resp[0].hits) {
								currentFilters.push({ key, value: hit.id, label: hit.name });
							}
						}
					} else {
						for (const v of value.split(',')) {
							currentFilters.push({ key, value: v });
						}
					}
				}
			}
			setActiveFilters(currentFilters);
		};
		setLabels();
	}, [searchParams, facets]);

	if (activeFilters.length === 0) {
		return <></>;
	}

	return (
		<div className="mb-3">
			<small className="text-muted me-2">Active Filters:</small>
			{activeFilters.map(({ key, value, label }) => (
				<ActiveFilterBadge
					key={`${key}-${value}`}
					filterKey={key}
					value={value}
					label={label}
				/>
			))}
		</div>
	);
}

export function SearchWithFacets<T = any>(props: SearchWithFacetsArguments<T>) {
	const search = useSearch();
	const [searchParams, setSearchParams] = useSearchParams();
	const [loading, setLoading] = useState(false);
	const [results, setSearchResults] = useState<SearchResults<T>>();
	const [hasMore, setHasMore] = useState(false);
	const [searchRequestParams, setSearchRequestParams] = useState<Parameters<SearchClient['search']>[0]>();
	const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

	const doSearch = async (refresh?: boolean) => {
		const facetNames: string[] = [];
		if (props.facets) {
			for (const facet of props.facets) {
				if (facet?.attribute) {
					facetNames.push(facet.attribute);
				}
			}
		}

		if (props.timelineFacet && !facetNames.includes(props.timelineFacet)) {
			facetNames.push(props.timelineFacet);
		}
		const filters: Record<string, string[]> = {};
		for (const [key, value] of searchParams.entries()) {
			if (key !== 'q' && key !== 'mode' && key !== 'sort_by' && key !== 'page' && key !== 'group_by') {
				if (!Array.isArray(value)) {
					filters[key] = [value];
				} else {
					filters[key] = value;
				}
			}
		}

		if (search?.searchClient) {
			const page = parseInt(searchParams.get('page') || '1', 10);
			setLoading(true);
			const tsSearchRequestParams: Parameters<typeof search.searchClient.search>[0] = {
				indexName: props.indexName,
				query: searchParams.get('q') || '',
				query_by: props.queryBy?.join(','),
				group_by: searchParams.get('group_by') || undefined,
				facet_by: facetNames?.join(','),
				include_fields: props.includeFields,
				filter_by: getFilterString(filters),
				// Odd hack to support sorting by stats fields which for some reason get converted to `stats_`
				sort_by: props.sortBy?.join(',') || (searchParams.get('sort_by') || props.defaultSort || '_text_match:desc').replace(/stats_/g, 'stats.'),
				per_page: 100,
				page,
				use_cache: !refresh,
			};
			setSearchRequestParams(tsSearchRequestParams);

			const searchResponse = await search.searchClient.search(tsSearchRequestParams);

			setHasMore(Boolean(searchResponse.page && searchResponse.page * 100 < searchResponse.found));
			setSearchResults(searchResponse);

			if (searchResponse.page && searchResponse.page !== page) {
				setSearchParams((params) => {
					params.set('page', searchResponse.page.toString());
					return params;
				});
			}

			setLoading(false);
		}
	};

	const onLoadMore = async () => {
		if (results && searchRequestParams && search?.searchClient) {
			console.log('Load More', results?.page);
			const searchResponse = await search.searchClient.search({
				...searchRequestParams,
				page: results.page + 1,
			});
			setSearchResults((r) => {
				if (!r) {
					return searchResponse;
				}
				return {
					...r,
					hits: [...(r.hits || []), ...searchResponse.hits],
					page: searchResponse.page,
				};
			});
			setHasMore(Boolean(searchResponse.page && searchResponse.page * 100 < searchResponse.found));
		}
	};

	useEffect(() => {
		if (debounceTimeout.current) {
			clearTimeout(debounceTimeout.current);
		}

		debounceTimeout.current = setTimeout(() => {
			doSearch();
		}, 300);

		return () => {
			if (debounceTimeout.current) {
				clearTimeout(debounceTimeout.current);
			}
		};
	}, [searchParams, search?.searchClient, props.facets, props.timelineFacet]);

	if (!search?.searchClient) {
		return <Loader />;
	}

	return (
		<Container fluid>
			<PageTitle
				title={props.title}
				info={props.info}
			>
				<Row>
					<Col>
						<Stats results={results}/>
					</Col>
					{props.actions && <Col className="text-end mb-2">
						{props.actions}
					</Col>}
					{props.facetStats && <Col className="text-end">
						<FacetStats facetStats={props.facetStats} results={results} />
					</Col>}
				</Row>
			</PageTitle>
			<Row>
				<Col>
					<SearchBox
						refreshHook={async () => {
							console.log('Refresh Search');
							return doSearch(true);
						}}
					/>
					<ActiveFilters facets={props.facets} />
				</Col>
			</Row>
			{props.timelineFacet && (<Row>
				<Col><SearchCountTimeline facetName={props.timelineFacet} /></Col>
			</Row>)}
			<hr/>
			<Row>
				<Col md={4} xxl={2}>
					<center className="mb-2">
						<Button
							variant="outline-secondary"
							size="sm"
							disabled={!searchParams.toString().replace(/&?q=[^&]*/g, '')}
							onClick={() => {
								const newSearchParams = new URLSearchParams();
								for (const key of ['group_by', 'sort_by']) {
									const value = searchParams.get(key);
									if (value) {
										newSearchParams.set(key, value);
									}
								}
								setSearchParams(newSearchParams);
							}}
						>Clear Filters</Button>
					</center>
					{props.facets.map((facet) => (
						<SearchFacet
							key={`search-facet-${facet.attribute}`}
							facet={facet}
							results={results}
						/>
					))}
				</Col>
				<Col>
					{props.groupByOptions && (
						<div className="text-end mb-2">
							<ButtonGroup>
								<DisplayModeButton
									mode="table"
									icon={faTable}
									onClick={() => {
										setSearchParams((params) => {
											params.delete('mode');
											params.delete('sort_by');
											params.delete('group_by');
											return params;
										});
									}}
									default={!searchParams.get('group_by')}
								/>
								{props.groupByOptions.map((option) => (
									<DisplayModeButton
										mode={option.id}
										searchKey="group_by"
										icon={option.icon}
										onClick={() => {
											setSearchParams((params) => {
												params.set('group_by', option.id);
												params.set('sort_by', option.sort || '_group_found:desc');
												return params;
											});
										}}
									/>
								))}
							</ButtonGroup>
						</div>
					)}
					{results?.found
						? <SearchHitsTable
							resultsTable={props.resultsTable}
							groupTable={props.groupTable}
							hasMore={hasMore}
							results={results}
							loading={loading}
							onLoadMore={onLoadMore}
						/>
						: <NoResults fallbackSearch={props.fallbackSearch} title={props.title} loading={loading}/>
					}
				</Col>
			</Row>
		</Container>
	);
}
