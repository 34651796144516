import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { KnowledgeBase } from '@newstex/types/rag';
import { useState } from 'react';
import {
	Button,
	Col,
	Form,
	Row,
} from 'react-bootstrap';
import { useConfirm } from '~/providers/confirm';

import { SelectKnowledgeBaseArticle } from '../select-knowledge-base-article';

interface LeadSubmissionFormProps {
	onSubmit: (urls: string[], article?: KnowledgeBase | null) => void;
	onSelectKnowledgeBase?: (article: KnowledgeBase | null) => void;
}

const COST_PER_URL = 0.1;

export function LeadSubmissionForm({ onSubmit, onSelectKnowledgeBase }: LeadSubmissionFormProps) {
	const [selectedKnowledgeBase, setSelectedKnowledgeBase] = useState<KnowledgeBase | null>(null);
	const [inputData, setInputData] = useState('');
	const { confirmDialog } = useConfirm();

	const handleSelectKnowledgeBase = (article: KnowledgeBase | null) => {
		setSelectedKnowledgeBase(article);
		onSelectKnowledgeBase?.(article);
	};

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		const urls = inputData.split('\n').filter((url) => url.trim() !== '');
		if (selectedKnowledgeBase) {
			if (await confirmDialog({
				title: 'Use AI Qualification Criteria?',
				message: 'Are you sure you want to use the AI Qualification Criteria?',
				details: <div>
					<p>
						This may have a cost of <b>up to ${(urls.length * COST_PER_URL).toFixed(2)}</b>
					</p>
					<p>
						<b>Note:</b> Only records that match technical qualification will be run through
						the AI Qualification Criteria, anything that doesn't match technical qualification
						will not incur additional costs.
					</p>
					<p>
						Leave the AI Qualification criteria field blank to skip AI Qualification and perform
						only the technical qualifications.
					</p>
				</div>,
			})) {
				onSubmit(urls, selectedKnowledgeBase);
			}
		} else {
			onSubmit(urls);
		}
	};

	return (
		<Form onSubmit={handleSubmit}>
			<Row className="mb-3">
				<Col>
					<SelectKnowledgeBaseArticle
						category="Qualification"
						value={selectedKnowledgeBase}
						onSelect={handleSelectKnowledgeBase}
						label="Search for AI Qualification Criteria"
						showWarning
						costPerItem={COST_PER_URL}
					/>
				</Col>
			</Row>
			<Row className="mt-3 mb-3">
				<Col>
					<Form.Group controlId="urlInput">
						<Form.Label>Enter URLs (one per line)</Form.Label>
						<Form.Control
							as="textarea"
							rows={25}
							value={inputData}
							onChange={(e) => setInputData(e.target.value)}
							placeholder={[
								'# URL to Qualify',
								'https://example.com',
								'',
								'# Hubspot Company List ID',
								'https://app.hubspot.com/contacts/912345/objectLists/1234/filters',
								'',
								'# URL and Hubspot Company ID',
								'https://example.com;912345',
							].join('\r\n')}
						/>
					</Form.Group>
				</Col>
			</Row>
			<Row>
				<Col>
					<Button
						type="submit"
						disabled={inputData.trim() === ''}
						variant="success"
						className="ms-2 float-end"
					>
						<FontAwesomeIcon icon={faPlay} /> Submit {inputData.trim() === '' ? 'URLs' : `${inputData?.trim().split('\n').length} URLs`}
					</Button>
				</Col>
			</Row>
		</Form>
	);
}
