import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { NewsletterSubscription } from '@newstex/types/newsletter-subscription';
import { createColumnHelper } from '@tanstack/react-table';
import { useMemo, useRef } from 'react';
import { Button } from 'react-bootstrap';

import DataTable, { DataTableMethods } from '../DataTable';
import { LoadMoreButton } from '../load-more-button';
import { PropertyDisplayValue } from '../property-display-value';

export function NewsletterSubscriptionsTable(
	{
		subscriptions,
		searchText,
		hasMore,
		onLoadMore,
	}: {
		subscriptions: NewsletterSubscription[],
		searchText?: string,
		hasMore?: boolean,
		onLoadMore?: () => void,
	},
) {
	const dataTableRef = useRef<DataTableMethods>(null);
	const columnHelper = createColumnHelper<NewsletterSubscription>();
	const columns = useMemo(() => [
		columnHelper.accessor('email', {
			header: 'Email',
			enableSorting: true,
		}),
		columnHelper.accessor('name', {
			header: 'Name',
			enableSorting: !hasMore,
			cell: ({ getValue, row: { original }}) => {
				return <>
					<PropertyDisplayValue propName="name" propValue={getValue() || original.email?.split('@')[0]} />
				</>;
			},
		}),
		columnHelper.accessor('status', {
			header: 'Status',
			enableSorting: true,
		}),
		columnHelper.accessor('topic', {
			header: 'Topic',
			enableSorting: true,
		}),
		columnHelper.accessor('created_at', {
			header: 'Created',
			enableSorting: true,
			cell: ({ getValue }) => <PropertyDisplayValue propName="created_at" propValue={getValue()} />,
		}),
	], [subscriptions]);

	return (<>
		<DataTable
			columns={columns}
			items={subscriptions || []}
			ref={dataTableRef}
			searchText={searchText}
			tableRowClassNameHelper={({ original }: { original: NewsletterSubscription }) => {
				if (original.status === 'unsubscribed') {
					return 'table-danger';
				}

				if (original.status === 'confirmed') {
					return 'table-success';
				}
				return '';
			}}
			nameAsc
			striped
		/>
		<LoadMoreButton show={hasMore} onClick={onLoadMore} />
		<div className="float-end pb-2">
			<Button variant="outline-secondary" size="sm" onClick={() => {
				dataTableRef.current?.exportToCSV('Newsletter Subscriptions');
			}}>
				<FontAwesomeIcon icon={faDownload} /> CSV
			</Button>
		</div>
	</>);
}
