/* eslint-disable @typescript-eslint/no-loop-func */
import {
	faDownload,
	faPlay,
	faStop,
	faSync,
	faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HotTable, HotTableClass } from '@handsontable/react';
import { QualificationScores } from '@newstex/ai/qualify';
import { trimEmptyProps } from '@newstex/core/trim-empty-properties';
import type { DetectFeedResponse } from '@newstex/types/detect-feed';
import type { HubSpotCompany } from '@newstex/types/hubspot';
import { createCompanyFromDetect } from '@newstex/types/hubspot/create-company-from-detect';
import Handsontable from 'handsontable';
import { CellProperties } from 'handsontable/settings';
import HyperFormula from 'hyperformula';
import {
	forwardRef,
	useMemo,
	useRef,
	useState,
} from 'react';
import {
	Button,
	Col,
	Row,
	Spinner,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { ProvenanceEditor } from '~/components/editors/provenance';
import EditableTextField from '~/components/editors/text';
import { useAnalytics } from '~/providers/analytics-provider';
import { useAPI } from '~/providers/api-provider';
import { useConfirm } from '~/providers/confirm';
import { useUserInfo } from '~/providers/user-info';

export interface LeadData extends DetectFeedResponse {
	running?: boolean;
	ai_rankings?: QualificationScores;
	email?: string;
}

interface LeadQualificationTableProps {
	items: { url: string, hubspotID?: string }[];
	data: LeadData[];
	running: boolean;
	abortController: AbortController;
	runQualification: () => void;
	clearData: () => void;
	className?: string;
	showScores: boolean;
}

const HubSpotClientFieldNames: Record<string, keyof HubSpotCompany> = {
	ccc: 'ai_qualification_score__ccc',
	cla: 'ai_qualification_score__cla',
	industrydive: 'ai_qualification_score__industry_dive',
	lexisnexis: 'ai_qualification_score__lexisnexis',
	moodys: 'ai_qualification_score__moody_s',
	proquest: 'ai_qualification_score__proquest',
	refinitiv: 'ai_qualification_score__refinitiv',
	syndigate: 'ai_qualification_score__syndigate',
	thomsonwestlaw: 'ai_qualification_score__thomson_westlaw',
	thomson: 'ai_qualification_score__thomson_westlaw',
};

function getHubSpotFieldName(client: string): keyof HubSpotCompany {
	const cleanedClient = client.replace(/[^a-z]/gi, '').toLowerCase();
	if (HubSpotClientFieldNames[cleanedClient]) {
		return HubSpotClientFieldNames[cleanedClient];
	}
	return `ai_qualification_score__${cleanedClient}` as keyof HubSpotCompany;
}

export const LeadQualificationTable = forwardRef<HotTableClass, LeadQualificationTableProps>(({
	className,
	items,
	data,
	running,
	abortController,
	runQualification,
	clearData,
	showScores,
}, ref) => {
	const hotRef = useRef<HotTableClass | null>(null);
	const [syncing, setSyncing] = useState(false);
	const api = useAPI();
	const analytics = useAnalytics();
	const { confirmDialog } = useConfirm();
	const userInfo = useUserInfo();
	const [updates, setUpdates] = useState<Partial<HubSpotCompany>>({
		provenance: 'NewsCore',
		newscore_tag: `${userInfo?.initials}-${new Date().toISOString().split('T')[0]}`,
	});

	const exportToCSV = () => {
		analytics.trackEvent('Export Qualified Leads', {}, data.filter((item) => item.qualified).length);
		const hot = hotRef.current?.hotInstance;
		if (hot) {
			const exportPlugin = hot.getPlugin('exportFile');
			exportPlugin.downloadFile('csv', {
				bom: false,
				columnDelimiter: ',',
				columnHeaders: true,
				fileExtension: 'csv',
				filename: `Qualified-Leads-${new Date().toISOString().split('T')[0].split('-').join('')}`,
				mimeType: 'text/csv',
				rowDelimiter: '\r\n',
			});
		}
	};

	const tableData = useMemo(() => data.map((item) => ({
		sync: false,
		url: item.url,
		feed_url: item.feed_url,
		name: item.name,
		qualified: item.qualified,
		// Calculate the highest scoring client
		ai_ranking_score: Math.max(...(Object.values(item.ai_rankings ?? { all: { score: 0 }}).map((r) => r.score) || [0])),
		// List ALL reasons next to their scores
		ai_ranking_reason: item.qualified ? Object.entries(item.ai_rankings ?? {})
			.map(([client, score]) => `${client}: ${score.score} - ${score.reason}`)
			.join('\r\n') : item.rejection_reasons?.join('\r\n'),
		// But also keep the full rankings
		ai_rankings: item.ai_rankings,
		average_word_count: item.stats?.average_word_count?.toFixed(0) || 0,
		possible_categories: item.newstex_categories ? Object.entries(item.newstex_categories)
			.filter(([, value]) => value > 10)
			.map(([key]) => key)
			.join(', ') : '',
		last_post_date: item.last_post_date,
		hubspot_records: item.hubspot_records?.map((record) => `${record.name} (${record.hs_object_id || record.id})`).join('\r\n'),
		twitter: item.twitter,
		facebook: item.facebook,
		linkedin: item.linkedin,
		email: item.email,
		running: item.running,
	})), [data]);

	const columns = useMemo<Handsontable.ColumnSettings[]>(() => [
		{
			data: 'sync',
			type: 'checkbox',
			className: 'htCenter',
			readOnly: running,
			width: 60,
			renderer(
				instance: Handsontable.Core,
				td: HTMLTableCellElement,
				row: number,
				col: number,
				prop: string | number,
				value: any,
				cellProperties: CellProperties,
			) {
				Handsontable.renderers.CheckboxRenderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
				td.style.textAlign = 'center';
				return td;
			},
		},
		{ data: 'url', readOnly: true, width: 200 },
		{ data: 'feed_url', readOnly: true, width: 200 },
		{ data: 'name', readOnly: true, width: 200 },
		{
			data: 'qualified',
			readOnly: true,
			type: 'checkbox',
			renderer(
				instance: Handsontable.Core,
				td: HTMLTableCellElement,
				row: number,
				col: number,
				prop: string | number,
				value: any,
				cellProperties: CellProperties,
			) {
				if (value == null) {
					return td;
				}
				const text = value ? 'Yes' : 'No';
				td.innerHTML = text;
				td.className = value ? 'bg-success-subtle' : 'bg-danger-subtle';
				td.style.textAlign = 'center';
				td.style.color = '#000'; // Black text for better contrast
				td.style.fontWeight = 'bold';
				return td;
			},
		},
		{
			data: 'ai_ranking_score',
			readOnly: true,
			type: 'numeric',
			width: 90,
			numericFormat: { pattern: '0' },
			renderer(
				instance: Handsontable.Core,
				td: HTMLTableCellElement,
				row: number,
				col: number,
				prop: string | number,
				value: any,
				cellProperties: CellProperties,
			) {
				if (value == null) {
					return td;
				}
				Handsontable.renderers.NumericRenderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
				if (value < 0) {
					td.className = 'bg-danger-subtle';
				} else if (value > 2) {
					td.className = 'bg-success-subtle';
				} else if (!td.className.includes('running')) {
					td.className = 'bg-warning-subtle';
				}
				return td;
			},
		},
		{ data: 'ai_ranking_reason', readOnly: true, width: 200 },
		{ data: 'average_word_count', readOnly: true },
		{ data: 'possible_categories', readOnly: true },
		{
			type: 'date',
			data: 'last_post_date',
			readOnly: true,
		},
		{
			data: 'hubspot_records',
			readOnly: true,
			renderer(
				instance: Handsontable.Core,
				td: HTMLTableCellElement,
				row: number,
				col: number,
				prop: string | number,
				value: any,
			) {
				if (value == null || value.length === 0) {
					return td;
				}
				const records: string[] = [];
				for (const record of value.split('\r\n')) {
					const [name, id] = record.match(/^(.+)\s\((.+)\)$/)?.slice(1) ?? [];
					records.push(`<div><a href="https://app.hubspot.com/contacts/20613241/record/0-2/${id}" target="_blank">${name}</a></div>`);
				}
				td.innerHTML = records.join('\r\n');
				return td;
			},
		},
		{ data: 'twitter', readOnly: true },
		{ data: 'facebook', readOnly: true },
		{ data: 'linkedin', readOnly: true },
		{ data: 'email', readOnly: true },
	], [running]);

	const syncSelectedLeads = async (leadsToSync: LeadData[], formData: Partial<Record<keyof HubSpotCompany, any>>) => {
		analytics.trackEvent('Sync Leads', {
			tag: formData.newscore_tag,
			provenance: formData.provenance,
		}, leadsToSync.length);
		setSyncing(true);
		for (const lead of leadsToSync) {
			const toastNotification = toast.loading(`Syncing ${lead.url}...`, {
				closeButton: true,
				isLoading: true,
			});
			try {
				const matchedCategories: string[] = [];
				for (const [category, score] of Object.entries(lead.newstex_categories ?? {})) {
					if (score >= 10) {
						matchedCategories.push(category);
					}
				}
				// Include a $note (which gets added as a Note object in HubSpot)
				const note = lead.qualified ? Object.entries(lead.ai_rankings ?? {})
					.map(([client, score]) => `<b>${client}</b>: <i>${score.score}</i> - ${score.reason}`)
					.join('<br/>') : lead.rejection_reasons?.join('<br/>');

				// Update existing records
				if (lead.hubspot_records?.length) {
					const hsRecord = lead.hubspot_records[0];
					const hsObjectID = hsRecord.hs_object_id;
					toast.update(toastNotification, {
						render: `Updating record ID ${hsObjectID}...`,
						type: 'info',
						isLoading: true,
						onClick: () => {
							window.open(`https://app.hubspot.com/contacts/20613241/record/0-2/${hsObjectID}`, '_blank');
						},
					});
					analytics.trackEvent('Update HubSpot Company', {
						id: hsObjectID,
						url: lead.url,
						tag: formData.newscore_tag,
						provenance: formData.provenance,
					});
					const metadataSources: any[] = (hsRecord.metadata_sources as any) || ['NewsCore'];
					if (!metadataSources.includes('NewsCore')) {
						metadataSources.push('NewsCore');
					}
					const hsCompany: Partial<HubSpotCompany & { $note: string }> = {
						ai_check_date: new Date().toISOString().split('T')[0],
						ai_qualified: lead.qualified ? 'true' : 'false',
						ai_headline_score: lead.headline_score,
						ai_story_categories: matchedCategories?.join(';') || '' as any,
						ai_rejection_reason: lead.rejection_reasons?.join(';') || '',
						ai_qualification_score: Math.max(...(Object.values(lead.ai_rankings ?? {}).map((r) => r.score) || [0])),
						// Don't update existing provenances
						// provenance: formData.provenance,
						// But DO update the tag
						newscore_tag: formData.newscore_tag,
						// And update the metadata_sources
						metadata_sources: metadataSources,
						// Include a $note (which gets added as a Note object in HubSpot)
						$note: note,
					};
					if (showScores && lead.ai_rankings) {
						for (const [client, score] of Object.entries(lead.ai_rankings)) {
							hsCompany[getHubSpotFieldName(client)] = score.score;
						}
					}
					await api.updateItem<any>({
						$type: 'HubSpotCompany',
						$id: hsObjectID,
					}, hsCompany);
					toast.update(toastNotification, {
						render: `Updated record ID ${hsObjectID}`,
						type: 'success',
						isLoading: false,
						autoClose: 5000,
						onClick: () => {
							window.open(`https://app.hubspot.com/contacts/20613241/record/0-2/${hsObjectID}`, '_blank');
						},
					});
				} else {
					// Or create a new one
					toast.update(toastNotification, {
						render: `Creating new HubSpot Company: ${lead.url}`,
						type: 'info',
					});
					const hsCompany = createCompanyFromDetect(lead);
					hsCompany.ai_check_date = new Date().toISOString().split('T')[0];
					hsCompany.ai_qualified = lead.qualified ? 'true' : 'false';
					hsCompany.ai_headline_score = lead.headline_score;
					hsCompany.ai_story_categories = matchedCategories?.join(';') || '' as any;
					hsCompany.ai_rejection_reason = lead.rejection_reasons?.join(';') || '';
					if (showScores && lead.ai_rankings) {
						for (const [client, score] of Object.entries(lead.ai_rankings)) {
							hsCompany[getHubSpotFieldName(client)] = score.score;
						}
						hsCompany.ai_qualification_score = Math.max(
							...(Object.values(lead.ai_rankings ?? {})
								.map((r) => r.score) || [0]),
						);
					}
					const hsResp = await api.createItem<any>({
						$type: 'HubSpotCompany',
						...hsCompany,
						articles: lead.articles,
						provenance: formData.provenance,
						newscore_tag: formData.newscore_tag,
						// Include a $note (which gets added as a Note object in HubSpot)
						$note: note,
					});
					analytics.trackEvent('Create HubSpot Company', {
						id: hsResp?.hs_object_id,
						url: lead.url,
						tag: formData.newscore_tag,
						provenance: formData.provenance,
					});
					const hsObjectID = hsResp?.hs_object_id || hsResp?.id;
					if (hsObjectID) {
						lead.hubspot_records = [hsResp];
						toast.update(toastNotification, {
							render: `Created new HubSpot Company: ${lead.url}`,
							type: 'success',
							isLoading: false,
							autoClose: 15000,
							onClick: () => {
								window.open(`https://app.hubspot.com/contacts/20613241/record/0-2/${hsObjectID}`, '_blank');
							},
						});
					} else {
						console.error('Failed to create HubSpot Company', lead.url, hsResp);
						toast.update(toastNotification, {
							render: `Failed to create HubSpot Company: ${lead.url} (${hsResp?.error || hsResp?.message || String(hsResp)})`,
							type: 'error',
							isLoading: false,
							onClick: () => {
								const errorObj = {
									url: lead.url,
									error: hsResp,
								};
								navigator.clipboard.writeText(JSON.stringify(errorObj, null, 2));
							},
						});
					}
				}
			} catch (e: any) {
				console.error(e);
				toast.update(toastNotification, {
					render: `Error syncing ${lead.url}: ${e instanceof Error ? e.message : 'Unknown error'}`,
					type: 'error',
					autoClose: false,
					isLoading: false,
				});
			}
		}
		setSyncing(false);
	};

	const getSelectedLeads = () => {
		const selectedLeads: LeadData[] = [];
		const hot = hotRef.current?.hotInstance;
		if (hot) {
			const hotData = hot.getData();
			for (const row of hotData) {
				if (row[0] === true && row[1]?.length > 0) {
					const lead = data.find((l) => l.url === row[1]);
					if (lead) {
						selectedLeads.push(lead);
					}
				}
			}
		}
		return selectedLeads;
	};

	return (
		<>
			<Row className="mt-3 mb-3">
				<Col>
					<Button
						onClick={exportToCSV}
						disabled={running}
						variant="outline-secondary"
					>
						<FontAwesomeIcon icon={faDownload} /> Export to CSV
					</Button>
				</Col>
				<Col>
					{running && (
						<Button
							onClick={(e) => {
								e.preventDefault();
								e.currentTarget.disabled = true;
								if (running) {
									abortController.abort();
								}
							}}
							disabled={abortController.signal.aborted}
							variant="danger"
							className="ms-2 float-end"
						>
							<FontAwesomeIcon icon={faStop} /> Stop
						</Button>
					)}

					{!running && (
						<Button
							onClick={async (e) => {
								e.preventDefault();
								if (await confirmDialog('Are you sure you want to clear all data?')) {
									clearData();
								}
							}}
							variant="danger"
							className="ms-2 float-end"
						>
							<FontAwesomeIcon icon={faTrash} /> Clear
						</Button>
					)}

					<Button
						onClick={runQualification}
						disabled={
							running
							|| items.length === 0
							|| data.filter((lead) => lead.qualified == null).length === 0
						}
						variant="success"
						className="ms-2 float-end"
					>
						{running ? <Spinner animation="border" size="sm" /> : <FontAwesomeIcon icon={faPlay} />} Run
						({data.filter((lead) => lead.qualified == null).length})
					</Button>
				</Col>
			</Row>
			<Row className={`mb-3 ${className}`}>
				<Col>
					<HotTable
						ref={hotRef}
						data={tableData}
						columns={columns}
						colHeaders={[
							'Sync', 'URL', 'Feed URL', 'Name', 'Tech Qualified', 'AI Score',
							'Score Reason', 'Average Word Count', 'Possible Categories',
							'Last Post Date', 'Existing HubSpot Company', 'Twitter', 'Facebook', 'LinkedIn', 'Email',
						]}
						height="auto"
						licenseKey="non-commercial-and-evaluation"
						stretchH="all"
						autoWrapRow
						autoWrapCol
						rowHeaders={true}
						filters={!running}
						dropdownMenu={!running}
						contextMenu={{
							items: {
								select_all: {
									name: 'Select All',
									callback: () => {
										const hot = hotRef.current?.hotInstance;
										if (hot) {
											const hotData = hot.getData();
											for (const [index] of hotData.entries()) {
												hot.setDataAtCell(index, 0, true);
											}
										}
									},
									disabled: () => running,
								},
								deselect_all: {
									name: 'Deselect All',
									callback: () => {
										const hot = hotRef.current?.hotInstance;
										if (hot) {
											const hotData = hot.getData();
											for (const [index] of hotData.entries()) {
												hot.setDataAtCell(index, 0, false);
											}
										}
									},
									disabled: () => running,
								},
							},
						}}
						multiColumnSorting={!running}
						manualColumnResize={!running}
						manualRowResize={!running}
						formulas={{
							engine: HyperFormula,
						}}
						columnSorting={{
							sortEmptyCells: false,
							indicator: !running,
							headerAction: !running,
							compareFunctionFactory(sortOrder, columnMeta) {
								return (value: any, nextValue: any) => {
									if (columnMeta.type === 'checkbox') {
										if (value === nextValue) {
											return 0;
										}

										if (sortOrder === 'asc') {
											return value ? 1 : -1;
										}
										return value ? -1 : 1;
									}

									// For other column types, use a simple comparison
									if (value === nextValue) {
										return 0;
									}

									if (sortOrder === 'asc') {
										return value < nextValue ? -1 : 1;
									}
									return value < nextValue ? 1 : -1;
								};
							},
						}}
						cells={(row) => {
							if (tableData[row]?.running) {
								return { className: 'running bg-info-subtle' };
							}
							return { className: '' };
						}}
						hiddenColumns={{
							columns: showScores ? [] : [5, 6], // Hide AI Score and Score Reason columns when showScores is false
							indicators: true,
						}}
					/>
				</Col>
			</Row>
			<Row className="mb-3">
				<Col className="text-center">
					<Button
						disabled={running || syncing}
						variant="secondary"
						onClick={async (e) => {
							e.preventDefault();
							const leadsToSync = getSelectedLeads();
							if (leadsToSync.length === 0) {
								return;
							}
							const updateCount = leadsToSync.filter((lead) => lead.hubspot_records?.length).length;
							const createCount = leadsToSync.filter((lead) => !lead.hubspot_records?.length).length;

							const formData = await confirmDialog({
								title: `Sync ${leadsToSync.length} leads to HubSpot?`,
								message: 'Are you sure you want to sync these leads to HubSpot?',
								details: <>
										This will:
									<ul className="mt-2">
										{updateCount > 0 && <li>
												Update {updateCount} companies that are already in HubSpot.
										</li>}
										{createCount > 0 && <li>
												Create {createCount} companies that are not in HubSpot.
										</li>}
									</ul>
								</>,
								form: <>
									<EditableTextField
										typeName="HubSpotCompany"
										propKey="newscore_tag"
										placeholder={updates?.newscore_tag}
									/>
									{createCount > 0 && <ProvenanceEditor />}
								</>,
							});

							if (formData) {
								// Update the parent state with the new values
								setUpdates((prev) => {
									const newUpdates = { ...prev, ...trimEmptyProps(formData[1], true) };
									console.log('newUpdates', newUpdates);
									syncSelectedLeads(leadsToSync, newUpdates);
									return newUpdates;
								});
							}
						}}
					>
						<FontAwesomeIcon
							icon={faSync}
							className="me-2"
							spin={syncing}
						/>
							Sync selected leads to HubSpot
					</Button>
				</Col>
			</Row>
		</>
	);
});
