import { faDownload } from '@fortawesome/pro-duotone-svg-icons';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getObjectID, getObjectType } from '@newstex/core/reference';
import type { Publication } from '@newstex/types/content';
import type { HistoryRecord } from '@newstex/types/history';
import type { Results } from '@newstex/types/results';
import { createColumnHelper } from '@tanstack/react-table';
import {
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { Button, Card } from 'react-bootstrap';
import { formatDateYYYYMMDD } from '~/lib/utils';
import { useAPI } from '~/providers/api-provider';

import { CreateEditorialNoteModal } from './create-editorial-note-modal';
import DataTable, { DataTableMethods } from './DataTable';
import LoadingSpinner from './LoadingSpinner';
import { PropertyDisplayValue } from './property-display-value';

interface EditorialNotesTableProps {
	item: Publication;
}

interface EditorialNote {
	transaction_id: string;
	ts: number;
	review_date?: string;
	reviewer?: string;
	comment?: string;
	user?: string;
}

type EditorialHistoryRecord = HistoryRecord<Publication, keyof Publication>;

const editorialProperties = ['last_editorial_review', 'reviewed_by', 'last_review_comment'] as const;

export function EditorialNotesTable({ item }: EditorialNotesTableProps) {
	const [notes, setNotes] = useState<EditorialNote[]>([]);
	const [loading, setLoading] = useState(false);
	const [showAddCommentModal, setShowAddCommentModal] = useState(false);
	const dataTableRef = useRef<DataTableMethods>(null);
	const api = useAPI();

	const columnHelper = createColumnHelper<EditorialNote>();
	const columns = useMemo(() => [
		columnHelper.accessor('review_date', {
			header: 'Review Date',
			enableSorting: true,
			cell: ({ getValue }) => (
				<PropertyDisplayValue
					propName="last_editorial_review"
					propValue={getValue()}
				/>
			),
		}),
		columnHelper.accessor('reviewer', {
			header: 'Reviewed By',
			enableSorting: true,
			cell: ({ getValue }) => (
				<PropertyDisplayValue
					propName="reviewed_by"
					propValue={getValue()}
				/>
			),
		}),
		columnHelper.accessor('comment', {
			header: 'Comments',
			enableSorting: true,
			cell: ({ getValue }) => (
				<PropertyDisplayValue
					propName="last_review_comment"
					propValue={getValue()}
				/>
			),
		}),
	], []);

	const refreshData = async () => {
		if (item) {
			setLoading(true);
			const itemType = getObjectType(item)?.toLowerCase();
			const itemId = getObjectID(item);

			const promises = editorialProperties.map((prop) => (
				api.fetchWithAuth<Results<EditorialHistoryRecord>>(
					`resources/${itemType}/${itemId}/history?property=${prop}`,
				)
			));

			const results = await Promise.all(promises);

			// Group the history records by transaction_id
			const groupedRecords: Record<string, EditorialHistoryRecord[]> = {};
			for (const result of results) {
				if (result?.items?.length) {
					for (const record of result.items) {
						if (record.transaction_id) {
							if (!groupedRecords[record.transaction_id]) {
								groupedRecords[record.transaction_id] = [];
							}
							groupedRecords[record.transaction_id].push(record);
						}
					}
				}
			}

			// Convert grouped records to EditorialNote array
			const notesArray: EditorialNote[] = Object.entries(groupedRecords).map(([transactionId, records]) => ({
				transaction_id: transactionId,
				ts: records[0]?.ts || 0,
				review_date: records.find((r) => r.property === 'last_editorial_review')?.new_value as string,
				reviewer: records.find((r) => r.property === 'reviewed_by')?.new_value as string || records[0]?.user as string,
				comment: records.find((r) => r.property === 'last_review_comment')?.new_value as string,
			}));

			// Sort by timestamp descending
			notesArray.sort((a, b) => b.ts - a.ts);
			setNotes(notesArray);
			setLoading(false);
		}
	};

	// Initial data fetch
	useEffect(() => {
		// Wait 5 seconds before fetching data to allow time for the publication to be updated
		setTimeout(() => {
			refreshData();
		}, 5000);
	}, [item]);

	return (<>
		<CreateEditorialNoteModal
			publication={item}
			show={showAddCommentModal}
			onHide={() => setShowAddCommentModal(false)}
			onSave={() => {
				refreshData();
			}}
		/>
		<Card className="card-table">
			<Card.Body>
				<DataTable
					loading={loading}
					columns={columns}
					items={notes}
					ref={dataTableRef}
					defaultSort="ts"
					striped
				/>
				<LoadingSpinner loading={loading} />
			</Card.Body>
			<Card.Footer className="d-flex justify-content-end">
				<Button className="me-1" variant="outline-secondary" size="sm" onClick={() => {
					dataTableRef.current?.exportToCSV(`Editorial Notes - ${formatDateYYYYMMDD(new Date())}`, {
						'Review Date': (original) => original.review_date || '',
						'Reviewed By': (original) => original.reviewer || '',
						Comments: (original) => original.comment || '',
					});
				}}>
					<FontAwesomeIcon icon={faDownload} /> CSV
				</Button>
				<Button size="sm" variant="outline-success"
					title="Add Note"
					onClick={() => {
						setShowAddCommentModal(true);
					}}>
					<FontAwesomeIcon icon={faPlus} /> Add Note
				</Button>
			</Card.Footer>
		</Card>
	</>);
}
