import { version } from '@newstex/core/version';
import { Col, Container, Row } from 'react-bootstrap';

export default function Footer() {
	return (
		<footer className="footer bg-white shadow align-self-end py-3 px-xl-5 w-100">
			<Container fluid>
				<Row>
					<Col md={6} className="text-center text-md-start fw-bold">
						<div className="mb-2 mb-md-0">
							© 2023-{new Date().getFullYear()}
							&nbsp;<a
								className="ml-3 mr-3"
								target="_blank"
								href="https://newstex.com"
								aria-current="page">Newstex</a>
							&nbsp;
							All Rights Reserved
						</div>
					</Col>
					<Col md={6} className="text-center text-md-end text-gray-400">
						<div className="mb-0">Version {version}</div>
					</Col>
					<div className="terms d-flex justify-content-center justify-content-md-end text-sm">
						<a
							className="ml-6"
							target="_blank"
							href="https://newstex.com/privacy-policy"
						>Privacy</a>&nbsp;&mdash;&nbsp;
						<a
							className="ml-6"
							target="_blank"
							href="https://newstex.com/cookie-policy"
						>Cookie Policy</a>&nbsp;&mdash;&nbsp;
						<a
							className="ml-6"
							target="_blank"
							href="https://newstex.com/terms-of-use"
						>Terms of Use</a>
					</div>
				</Row>
			</Container>
		</footer>
	);
}
