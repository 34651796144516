import { faDownload, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { Publisher } from '@newstex/types/content';
import { createColumnHelper } from '@tanstack/react-table';
import { useMemo, useRef } from 'react';
import {
	Button,
	Container,
	OverlayTrigger,
	Tooltip,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { ContentCard } from '../content-card';
import DataTable, { DataTableMethods } from '../DataTable';
import { headerWithTooltip } from '../header-with-tooltip';
import { LoadMoreButton } from '../load-more-button';
import { PropertyDisplayValue } from '../property-display-value';
import { StatusBadge } from '../status-badge';

const CSV_EXPORT_CONFIG: Record<string, string | ((item: any) => string)> = {
	'NewsCore ID': '__id__',
	'Newstex ID': 'newstex_id',
	Name: 'name',
	'Sortable Name': 'sortable_name',
	'Parent Content Newstex ID': 'parent_content_newstex_id',
	Status: 'status',
	'Closed Sites Only': 'closed_sites_only',
	'Last Post Date': 'last_post_date',
	'Content Status': 'content_status',
	'Archive Date': 'archive_date',
	'Deletion Date': 'deletion_date',
	'HubSpot ID': 'hubspot_id',
	'Website URL': 'website_url',
	'Integrated Date': (item) => item.integration_completion_date,
};

export function PublisherTable(
	{
		items,
		searchText,
		hasMore,
		onLoadMore,
	}: {
		items: Publisher[],
		searchText?: string,
		hasMore?: boolean,
		onLoadMore?: () => void,
	},
) {
	const dataTableRef = useRef<DataTableMethods>(null);
	const columnHelper = createColumnHelper<Publisher>();
	const columns = useMemo(() => [
		columnHelper.display({
			id: 'info',
			header: '',
			enableSorting: false,
			maxSize: 30,
			cell: ({ row: { original }}) => {
				return (
					<div className="text-center">
						<OverlayTrigger
							placement="right"
							overlay={
								<Tooltip className="card-tooltip">
									<Container>
										<ContentCard item={original} />
									</Container>
								</Tooltip>
							}
						>
							<FontAwesomeIcon icon={faInfoCircle} />
						</OverlayTrigger>
					</div>
				);
			},
		}),
		columnHelper.accessor('newstex_id', {
			header: 'Newstex ID',
			enableSorting: true,
			cell: ({ getValue, row: { original }}) => {
				return original.$id ? <>
					<Link to={`/publishers/${encodeURIComponent(original.$id)}`}>
						<PropertyDisplayValue propName="newstex_id" propValue={getValue()} />
					</Link>
				</> : <PropertyDisplayValue propName="newstex_id" propValue={getValue()} />;
			},
		}),
		columnHelper.accessor('name', {
			header: 'Name',
			enableSorting: true,
			cell: ({ getValue, row: { original }}) => {
				return original.$id ? <>
					<Link to={`/publishers/${encodeURIComponent(original.$id)}`}>
						<PropertyDisplayValue propName="title" propValue={getValue()} />
					</Link>
				</> : <PropertyDisplayValue propName="title" propValue={getValue()} />;
			},
		}),
		columnHelper.accessor('url', {
			header: 'URL',
			enableSorting: true,
			cell: ({ getValue }) => {
				return <PropertyDisplayValue propName="url" propValue={getValue()} />;
			},
		}),
		columnHelper.accessor('status', {
			header: 'Status',
			enableSorting: true,
			cell: ({ getValue }) => {
				return <StatusBadge status={getValue()} />;
			},
		}),
		columnHelper.accessor('closed_sites_only', {
			header: 'Closed Sites Only',
			enableSorting: true,
			cell: ({ getValue }) => {
				return <PropertyDisplayValue propName="closed_sites_only" propValue={getValue()} />;
			},
		}),
		columnHelper.accessor('last_post_date', {
			header: headerWithTooltip({
				title: 'Last Post Date',
				tooltip: 'The date provided on the most recently received story for this content.',
			}),
			enableSorting: true,
			cell: ({ getValue }) => {
				return <PropertyDisplayValue propName="last_post_date" propValue={getValue()} />;
			},
		}),
		columnHelper.accessor('last_received_date', {
			header: headerWithTooltip({
				title: 'Last Received',
				tooltip: 'The most recent date we received a story for this content.',
			}),
			enableSorting: true,
			cell: ({ getValue }) => {
				return <PropertyDisplayValue propName="last_received_date" propValue={getValue()} />;
			},
		}),
		columnHelper.accessor('integration_completion_date', {
			header: headerWithTooltip({
				title: 'Integrated Date',
				tooltip: 'The date we first activated a feed for this content.',
			}),
			enableSorting: true,
			cell: ({ getValue }) => {
				return <PropertyDisplayValue propName="integration_completion_date" propValue={getValue()} />;
			},
		}),
	], [items]);

	return (<>
		<DataTable
			columns={columns}
			items={items || []}
			ref={dataTableRef}
			searchText={searchText}
			striped
		/>
		<LoadMoreButton show={hasMore} onClick={onLoadMore} />
		<div className="float-end pb-2">
			<Button variant="outline-secondary" size="sm" onClick={() => {
				dataTableRef.current?.exportToCSV('Publishers', CSV_EXPORT_CONFIG);
			}}>
				<FontAwesomeIcon icon={faDownload} /> CSV ({items.length})
			</Button>
		</div>
	</>);
}
