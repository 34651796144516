import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CLIENTS } from '@newstex/core/clients';
import { AccountInfoRelatedContent } from '@newstex/types/responses/info';
import { createColumnHelper } from '@tanstack/react-table';
import { useMemo, useRef, useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { formatDateString } from '~/lib/utils';

import DataTable, { DataTableMethods } from './DataTable';
import { PropertyDisplayValue } from './property-display-value';
import { StatusBadge } from './status-badge';

export interface MyPubsProps {
	pubs: AccountInfoRelatedContent[];
	title: string;
}

export default function MyPubs({ pubs, title }: MyPubsProps) {
	const dataTableRef = useRef<DataTableMethods>(null);
	const [searchText, setSearchText] = useState<string>('');
	const columnHelper = createColumnHelper<AccountInfoRelatedContent>();

	const columns = useMemo(() => [
		columnHelper.accessor('newstex_id', {
			header: 'Newstex ID',
			enableSorting: false,
		}),
		columnHelper.accessor('publication_name', {
			header: 'Publication Name',
			enableSorting: false,
			cell: ({ getValue, row: { original }}) => {
				return (
					<div className="d-flex align-items-center">
						<div className="pt-1">
							<Link to={`/publications/${original.newscore_id}`}>
								<strong>{getValue()}</strong>
							</Link>
							<br />
							<span className="text-muted text-sm">
								<PropertyDisplayValue
									propName="url"
									propValue={original.publication_url}
									className="text-secondary"
								/>
							</span>
						</div>
					</div>
				);
			},
		}),
		columnHelper.accessor('status', {
			header: 'Status',
			enableSorting: false,
			cell: ({ getValue }) => {
				return <StatusBadge status={getValue()} indicator />;
			},
		}),
		columnHelper.accessor('publisher_name', {
			header: 'Publisher',
			enableSorting: false,
			cell: ({ getValue, row: { original }}) => {
				return (
					<div className="d-flex align-items-center">
						<span className="me-2">
							<Link to={`/publishers/${original.publisher_newscore_id}`}>
								<strong>{getValue()}</strong>
							</Link>
						</span>
					</div>
				);
			},
		}),
		...Object.entries(CLIENTS).map(([clientId, clientName]) => (columnHelper.accessor(`${clientId}_status`, {
			header: clientName,
			enableSorting: false,
		}))),
		columnHelper.accessor('last_post_date', {
			header: 'Last Post Date',
			enableSorting: false,
			cell: ({ getValue }) => {
				const value = getValue();
				return value ? formatDateString(value, {
					month: 'short',
					day: 'numeric',
					year: 'numeric',
				}) : '';
			},
		}),
		/*
		columnHelper.accessor('avg_monthly_posts', {
			header: 'Average Monthly Story Count',
		}),
		*/
	], []);

	return (
		<Card className="card-table mb-4">
			<Card.Header>
				<div className="card-heading">{title}
					<Form
						className="d-inline-block float-end"
						onSubmit={(e) => e.preventDefault()}
					>
						<Form.Control
							type="text"
							placeholder={'Search'}
							onChange={(e) => setSearchText(e.target.value)}
							onSubmit={(e) => e.preventDefault()}
						/>
					</Form>
				</div>
			</Card.Header>
			<Card.Body>
				<DataTable
					columns={columns}
					items={pubs}
					defaultPageSize={20}
					searchText={searchText}
					ref={dataTableRef}
					striped
				/>
			</Card.Body>
			<Card.Footer>
				<div className="float-end">
					<Button variant="outline-secondary" size="sm" onClick={() => {
						dataTableRef.current?.exportToCSV('My Publications', {
							'Newstex ID': 'newstex_id',
							'Publication Name': 'publication_name',
							'Publisher Name': 'publisher_name',
							Status: 'status',
							'Archived Date': 'archived_date',
							// Status for each client
							...Object.entries(CLIENTS).reduce((acc, [clientId, clientName]) => ({
								...acc,
								[clientName]: `${clientId}_status`,
							}), {}),
							'Last Post Date': 'last_post_date',
							// 'Average Monthly Story Count': 'avg_monthly_posts',
						});
					}}>
						<FontAwesomeIcon icon={faDownload} /> CSV
					</Button>
				</div>
			</Card.Footer>
		</Card>
	);
}
