import { createContext, useContext } from 'react';

interface CacheOptions {
	/**
	 * Time in milliseconds before the cache expires
	 * Default: 8 hours
	 */
	ttl?: number;
}

interface CacheEntry<T> {
	value: T;
	expires?: number;
}

interface CacheContextType {
	get: <T>(key: string) => T | null;
	set: <T>(key: string, value: T, options?: CacheOptions) => void;
	remove: (key: string) => void;
	clear: () => void;
}

const DEFAULT_TTL = 1000 * 60 * 60 * 8; // 8 hours

const CacheContext = createContext<CacheContextType | undefined>(undefined);

export function CacheProvider({ children }: React.PropsWithChildren) {
	const get: CacheContextType['get'] = (key: string) => {
		try {
			const item = localStorage.getItem(key);
			if (!item) return null;

			const entry: CacheEntry<any> = JSON.parse(item);

			// Check if entry has expired
			if (entry.expires && entry.expires < Date.now()) {
				localStorage.removeItem(key);
				return null;
			}

			return entry.value;
		} catch (err) {
			console.error('Cache retrieval error:', err);
			return null;
		}
	};

	const set: CacheContextType['set'] = (key, value, options = {}) => {
		try {
			const entry: CacheEntry<any> = {
				value,
				expires: options.ttl ? Date.now() + options.ttl : Date.now() + DEFAULT_TTL,
			};
			localStorage.setItem(key, JSON.stringify(entry));
		} catch (err) {
			console.error('Cache set error:', err);
		}
	};

	const remove: CacheContextType['remove'] = (key) => {
		localStorage.removeItem(key);
	};

	const clear: CacheContextType['clear'] = () => {
		localStorage.clear();
	};

	return (
		<CacheContext.Provider value={{
			get,
			set,
			remove,
			clear,
		}}>
			{children}
		</CacheContext.Provider>
	);
}

export function useCache() {
	const context = useContext(CacheContext);
	if (context === undefined) {
		console.error('useCache must be used within a CacheProvider');
		return null;
	}
	return context;
}
