import Case from 'case';

import { type DetectFeedResponse, getMatchedCategories } from '../detect-feed';

import type { HubSpotCompany } from './company';

export function createCompanyFromDetect(detectResp: DetectFeedResponse, domain?: string): Partial<HubSpotCompany> {
	const now = new Date();
	const company: Partial<HubSpotCompany> = {
		name: detectResp.name,
		domain: domain || new URL(detectResp.url).hostname.replace(/^www\./, ''),
		content_feed_url: detectResp.feed_url,
		website: detectResp.url,
		provenance: 'AI',
		lifecyclestage: 'lead',
		feed_status: 'valid',
		type: 'Publisher',
		ai_check_date: now.toISOString().split('T')[0],
		ai_qualified: 'true',
		ai_headline_score: detectResp.headline_score,
		metadata_sources: ['NewsCore'],
	};
	if (detectResp.description) {
		company.description = detectResp.description;
	}

	if (detectResp.stats) {
		if (detectResp.stats.total_articles > 0 && detectResp.stats.qualified_articles != null) {
			company.ai_qualified_headline_percentage = (
				(
					detectResp.stats.qualified_articles / detectResp.stats.total_articles
				) * 100
			);
		}

		if (detectResp.stats.average_word_count > 0) {
			company.ai_average_story_length = detectResp.stats.average_word_count;
		}
	}

	if (detectResp.suggested_clients) {
		company.ai_suggested_clients = detectResp.suggested_clients.map(Case.title).join('\r\n');
	}

	const matchedCategories = getMatchedCategories(detectResp);

	if (matchedCategories.length > 0) {
		company.ai_story_categories = matchedCategories.join(';') as any;
	}

	if (detectResp.categories?.length > 0) {
		company.feed_categories = detectResp.categories.join('\r\n');
	}

	if (detectResp.twitter) {
		company.twitterhandle = detectResp.twitter;
	}

	if (detectResp.facebook) {
		company.facebook_company_page = detectResp.facebook;
	}

	if (detectResp.linkedin) {
		company.linkedin_company_page = detectResp.linkedin;
	}

	// Only link a newscore ID if this doesn't match an existing HubSpot ID
	if (detectResp.newscore_id && !detectResp.hubspot_id) {
		company.newscore_id = detectResp.newscore_id;
	}
	return company;
}
