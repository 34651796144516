import { KnowledgeBase } from '@newstex/types/rag';
import omit from 'object.omit';
import { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useAPI } from '~/providers/api-provider';

import EditableMultiValueField from '../multi-value';
import EditableSelectField from '../select';
import EditableTextField from '../text';

interface EditRAGModalProps {
	item: KnowledgeBase;
	show: boolean;
	onClose: (saved: boolean) => void;
}

export default function EditRAGModal({ item, show, onClose }: EditRAGModalProps) {
	const [editedItem, setEditedItem] = useState<KnowledgeBase>(item);
	const api = useAPI();

	const handleSave = async () => {
		try {
			const response = await api.fetchWithAuthRaw(item?.$id ? `resources/RAG/${item.$id}` : 'resources/RAG', {
				method: item?.$id ? 'PATCH' : 'POST',
				body: JSON.stringify({
					$type: 'RAG',
					...omit(editedItem, ['$id', '$type']),
				}),
			});

			if (response.ok) {
				onClose(true);
			} else {
				console.error('Failed to update RAG entry');
			}
		} catch (error) {
			console.error('Error updating RAG entry:', error);
		}
	};

	return (
		<Modal show={show} onHide={() => onClose(false)} size="xl" fullscreen>
			<Modal.Header className="text-center" closeButton>
				<Modal.Title>
					Edit Knowledge Base Entry
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Form.Group className="mb-3 row">
						<EditableTextField
							typeName="RAG"
							propKey="title"
							item={editedItem}
							updates={editedItem}
							setUpdates={(updates) => setEditedItem({ ...editedItem, ...updates })}
						/>
					</Form.Group>
					<Form.Group className="mb-3 row">
						<EditableMultiValueField
							typeName="RAG"
							propKey="questions"
							item={editedItem}
							updates={editedItem}
							setUpdates={(updates) => setEditedItem({ ...editedItem, ...updates })}
							trimValues={false}
						/>
					</Form.Group>
					<Form.Group className="mb-3 row">
						<EditableSelectField
							typeName="RAG"
							propKey="category"
							item={editedItem}
							updates={editedItem}
							setUpdates={(updates) => setEditedItem({ ...editedItem, ...updates })}
							options={[
								'RAG',
								'Qualification',
								'Accounting',
								'Prompts',
								'General',
							]}
						/>
					</Form.Group>
					<Form.Group className="mb-3 row">
						<EditableTextField
							typeName="RAG"
							propKey="answer"
							item={editedItem}
							updates={editedItem}
							multiLine
							setUpdates={(updates) => setEditedItem({ ...editedItem, ...updates })}
						/>
						<Form.Text>Markdown format supported.</Form.Text>
					</Form.Group>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={() => onClose(false)}>
					Cancel
				</Button>
				<Button variant="primary" onClick={handleSave}>
					Save Changes
				</Button>
			</Modal.Footer>
		</Modal>
	);
}
