/* eslint-disable no-console */
import {
	Dimension,
	TimestreamWriteClient,
	TimestreamWriteClientConfig,
	WriteRecordsCommand,
	WriteRecordsCommandInput,
	_Record,
} from '@aws-sdk/client-timestream-write';
import { version } from '@newstex/core/version';
import { EventData, isDimensionName } from '@newstex/types/analytics';
import type { AccountInfoResponse } from '@newstex/types/responses/info';
import { EventEmitter } from 'events';

export const MAX_VALUE_LENGTH = 2048;

// Helper to clean strings for Timestream
export const cleanString = (value: unknown): string => {
	return String(value)
		.replace(/[^\x20-\x7E]/g, '')
		.trim();
};

const objectEntries = <T extends object>(obj: T) => {
	return Object.entries(obj) as [keyof T, T[keyof T]][];
};

/**
 * Analytics Tracking Client for browser-side event collection
 */
export class AnalyticsClient extends EventEmitter {
	public commonDimensions: Record<string, string>;
	public tsClient: TimestreamWriteClient;
	protected flushTimeout: NodeJS.Timeout | null = null;
	private records: (Omit<_Record, 'Dimensions'> & { Dimensions: Record<string, string> })[] = [];

	constructor(
		public config: AccountInfoResponse,
		public updateCredentials?: () => Promise<AccountInfoResponse | null>,
	) {
		super();
		this.commonDimensions = {
			cnb_analytics_version: version,
			...this.getBrowserInfo(),
		};
		this.updateConfig(config);
	}

	private getBrowserInfo() {
		if (typeof window === 'undefined') return {};

		const ua = window.navigator.userAgent;
		const screenRes = `${window.screen.width}x${window.screen.height}`;
		const viewport = `${window.innerWidth}x${window.innerHeight}`;

		return {
			user_agent: ua,
			screen_resolution: screenRes,
			viewport,
			language: window.navigator.language,
			timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
			referrer: document.referrer || 'direct',
		};
	}

	async trackEvent(name: string, data?: EventData, count = 1) {
		this.addTimestreamRecord({
			MeasureName: name,
			MeasureValue: String(count),
			MeasureValueType: 'BIGINT',
			Time: Date.now().toString(),
		}, data);
	}

	async trackPageView(path: string) {
		this.addTimestreamRecord({
			MeasureName: 'View Page',
			MeasureValue: '1',
			MeasureValueType: 'BIGINT',
			Time: Date.now().toString(),
		}, { path });
	}

	updateConfig(config: Partial<AccountInfoResponse>) {
		this.emit('updateConfig', config);
		// Initialize the AWS Clients
		if (config.aws) {
			const tsConfig: TimestreamWriteClientConfig = {
				region: 'us-east-1',
				credentials: {
					accessKeyId: config.aws.AccessKeyId,
					secretAccessKey: config.aws.SecretAccessKey,
					sessionToken: config.aws.SessionToken,
				},
			};
			this.tsClient = new TimestreamWriteClient(tsConfig);
		}

		if (!this.config) {
			this.config = config as AccountInfoResponse;
		} else {
			this.config = {
				...this.config,
				...config,
			};
		}
	}

	identify(data: EventData) {
		if (data) {
			for (const [key, value] of objectEntries(data)) {
				const normalizedKey = String(key).toLowerCase();
				if (
					value != null
					&& String(value).length > 0
					&& isDimensionName(normalizedKey)
				) {
					this.commonDimensions[normalizedKey] = cleanString(value);
				}
			}
		}
	}

	/**
	 * Queue up an additional Timestream record to be sent to analytics
	 *
	 * @param record Timestream Record to add to the queue
	 * @param data Optional additional "data" to be added as Dimensions (normalized before sending)
	 */
	addTimestreamRecord(record: Omit<_Record, 'Dimensions'>, data?: EventData) {
		const dimensions: Record<string, string> = {};
		if (data) {
			for (const [key, value] of objectEntries(data)) {
				const normalizedKey = String(key).toLowerCase();
				if (
					isDimensionName(normalizedKey)
					&& !this.commonDimensions?.[normalizedKey]
					&& value != null
					&& String(value).length > 0
				) {
					dimensions[normalizedKey] = cleanString(value).slice(0, MAX_VALUE_LENGTH);
				}
			}
		}

		// Make sure we're not going to go over the 2KB limit
		if (
			JSON.stringify({
				...record,
				Dimensions: dimensions,
			}).length > 1800
		) {
			return this.emit('error', new Error('Event too large'));
		}
		this.records.push({
			...record,
			Dimensions: dimensions,
		});
		// Must flush every 50 records
		if (this.records.length === 50) {
			this.flush();
		} else if (!this.flushTimeout) {
			// Otherwise make sure we send at least once every 500ms
			this.flushTimeout = setTimeout(() => {
				this.flush();
			}, 500);
		}
		return this;
	}

	// Ping this periodically to flush all cached analytics events
	async flush() {
		if (!this.config.stage) {
			console.error('No stage configured for analytics client');
			// Pull the stage from the hostname if it's not already set
			if (window?.location?.hostname?.startsWith('admin-')) {
				this.config.stage = window.location.hostname.split('.')[0].split('-')[1];
			} else {
				this.config.stage = 'prod';
			}
		}
		clearTimeout(this.flushTimeout);
		console.log('Flushing Records', this.records);
		this.flushTimeout = null;
		if (this.config.aws && this.tsClient && this.records?.length > 0) {
			const cmdInput: WriteRecordsCommandInput = {
				DatabaseName: `${this.config.stage || 'prod'}-newstex`,
				TableName: 'Analytics',
				Records: [],
			};
			if (this.commonDimensions) {
				cmdInput.CommonAttributes = {
					Dimensions: [],
				};
				for (const [key, value] of objectEntries(this.commonDimensions)) {
					if (key != null && value != null) {
						cmdInput.CommonAttributes.Dimensions.push({
							Name: String(key),
							Value: value,
						});
					}
				}
				// Remove any common dimensions
				for (const record of this.records) {
					const dimensions: Dimension[] = [];
					if (record.Dimensions) {
						for (const [dimension, value] of objectEntries(record.Dimensions)) {
							if (value != null && this.commonDimensions[dimension] == null) {
								dimensions.push({
									Name: String(dimension),
									Value: value,
								});
							}
						}
					}
					cmdInput.Records.push({
						...record,
						Dimensions: dimensions,
					});
				}
			}
			// Create the record write command
			const cmd = new WriteRecordsCommand(cmdInput);

			// Then reset the event cache
			this.records = [];

			// And finally, issue the command
			await this.tsClient.send(cmd).catch((err) => this.handleTSException(err, cmd));
		}
	}

	async handleTSException(err, cmd: WriteRecordsCommand) {
		if (err.retryable || err.$retryable) {
			return this.tsClient.send(cmd).catch((e) => this.handleTSException(e, cmd));
		}

		if (this.updateCredentials
			&& (
				err.reason?.$metadata?.httpStatusCode === 403
				|| err.$metadata?.httpStatusCode === 403
				|| err.statusCode === 403
				|| err.code === 403
			)
			// This error is the same as the "your credentials are wrong", but it can't be fixed by
			// getting new credentials
			&& !err.message?.includes?.('The request signature we calculated does not match the signature you provided')
		) {
			this.emit('updateCredentials');
			this.updateConfig(await this.updateCredentials());
			return this.tsClient.send(cmd).catch((e) => this.handleTSException(e, cmd));
		}

		// Only emit an error if there is a listener for it, otherwise this will end up tossing an
		// uncaught exception, which we really want to avoid
		if (this.listenerCount('error') > 0) {
			this.emit('error', err);
		}
		console.error('ERROR Writing record to TimeStream', err);
		return err;
	}
}
