import { faArrowUpRightFromSquare } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Dropdown, NavItem, NavLink } from 'react-bootstrap';
import { useAuth } from '~/providers/auth';
import { useUserInfo } from '~/providers/user-info';

import Avatar from '../Avatar';

import AssumeUserRoleModal from './AssumeUserRoleModal';
import SettingsModal from './SettingsModal';

export default function UserMenu() {
	const auth = useAuth();
	const userInfo = useUserInfo();
	const [showSettings, setShowSettings] = useState(false);
	const [showAssumeUser, setShowAssumeUser] = useState(false);

	return (
		<>
			<Dropdown as={NavItem} className="ms-auto" align="end">
				<Dropdown.Toggle as={NavLink} className="pe-0" id="userInfo">
					<Avatar image={userInfo?.picture} alt={userInfo?.name} border priority />
				</Dropdown.Toggle>
				<Dropdown.Menu
					className="dropdown-menu-animated"
					aria-labelledby="userInfo"
					data-bs-popper="none"
				>
					<Dropdown.Header className="text-gray-700">
						<h6 className="text-uppercase font-weight-bold">{userInfo?.name}</h6>
						<small>{userInfo?.email}</small>
					</Dropdown.Header>
					<Dropdown.Divider />
					<Dropdown.Item
						onClick={() => setShowSettings(true)}
					>Settings</Dropdown.Item>
					{auth?.runAs && (
						<Dropdown.Item onClick={() => {
							auth?.assumeUser?.(null);
						}}
						>Return to User</Dropdown.Item>
					)}
					{userInfo?.auth_groups?.includes('admin') && (
						<Dropdown.Item onClick={() => setShowAssumeUser(true)}>Assume User</Dropdown.Item>
					)}
					<Dropdown.Divider />
					<Dropdown.Item href="/logout">Logout</Dropdown.Item>
					{window.location.hostname !== 'admin.newstex.com' && (
						<Dropdown.Item onClick={() => {
							window.location.href = 'https://admin.newstex.com';
						}}>
							Switch to Production
							<FontAwesomeIcon icon={faArrowUpRightFromSquare} className="ms-2" />
						</Dropdown.Item>
					)}
				</Dropdown.Menu>
			</Dropdown>
			<SettingsModal show={showSettings} onHide={() => setShowSettings(false)} />
			<AssumeUserRoleModal show={showAssumeUser} onHide={() => setShowAssumeUser(false)} />
		</>
	);
}
